import React, { useState } from "react";
import closeButton from "../../images/exitButton.png";
import uncheckedType from "../../images/unchecked.png";
import checkedType from "../../images/checked.png";
import songIcon from "../../images/songIcon.png";
import successPng from "../../images/exportSuccess.png";
import API from "../../api";

const reportTypes = ["Not Music", "Stolen Beat", "Not Playing", "Other"];

const ReportSong = ({ setIsOpen, currentSong }) => {
  //need to create a copy of the current song because current song might change while report window is still opened
  const song = { ...currentSong };
  const [formData, setFormData] = useState({
    type: "",
    song,
    description: "",
  });
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleTextAreaChange = (e) => {
    setFormData((prev) => ({ ...formData, description: e.target.value }));
  };

  const reportSong = () => {
    setLoading(true);
    API.reportSong(formData)
      .then(() => setSuccess(true))
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  return (
    <div className="loud-window report-song">
      <div className="top">
        <div className="title">
          <h1>Report</h1>
          <img
            onClick={() => setIsOpen(false)}
            src={closeButton}
            alt="close Button"
            className="close"
          />
        </div>
      </div>
      {success ? (
        <React.Fragment>
          <img className="success-image" src={successPng} alt="success icon" />
          <div className="success-text">
            Your report has been submitted successfully <br /> Thank you for
            making LOUD better
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <div className="report-song__details">
            <img src={songIcon} alt="song Icon" />
            <div className="song-title">{song.title}</div>
            <div className="song-producer">
              {song.producer.nickname.length > 4
                ? song.producer.nickname.substring(0, 4) + "..."
                : song.producer.nickname}
            </div>
          </div>
          <div className="report-song__form">
            {reportTypes.map((type) => (
              <div
                key={type}
                className="type"
                onClick={() =>
                  setFormData((prev) => ({ ...prev, description: "", type }))
                }
              >
                <img
                  src={formData.type === type ? checkedType : uncheckedType}
                  alt="options"
                />
                <span>{type}</span>
              </div>
            ))}

            <textarea
              onChange={handleTextAreaChange}
              placeholder="Additional info..."
              value={formData.description}
              rows="5"
              maxLength="150"
            ></textarea>
          </div>

          <button
            disabled={
              loading ||
              !formData.type ||
              (formData.type === "Other" && !formData.description)
            }
            onClick={reportSong}
            className="window-btn"
          >
            {loading ? "Please Wait..." : "Submit"}
          </button>
        </React.Fragment>
      )}
    </div>
  );
};

export default ReportSong;
