import React from "react";
import exportSuccess from "../../images/exportSuccess.png";
import closeButton from "../../images/exitButton.png";

const ExportSuccess = ({ setOpen, user, loading, exportSongs }) => {
  return (
    <div className="exportWidget">
      <div className="top">
        <div className="title">
          <h1>EXPORT</h1>
          <img
            onClick={() => setOpen(false)}
            src={closeButton}
            alt="close Button"
            className="close"
          />
        </div>
        <div className="subtitle">COMPLETE</div>
      </div>
      <div className="success-message">
        {loading ? (
          <div>Please Wait..</div>
        ) : (
          <React.Fragment>
            <img src={exportSuccess} alt="export completed" />
            <div className="message">
              We emailed the beats you selected to <span>{user.email}</span>
              <br />
              <br />
              <br />
              If you have not received an email after 20 minutes, Click Resend
              below or Export the beats again
            </div>
          </React.Fragment>
        )}
      </div>
      <div className="bottom">
        <button
          disabled={loading}
          className="export-button"
          onClick={() => exportSongs()}
        >
          RESEND
        </button>
      </div>
    </div>
  );
};

export default ExportSuccess;
