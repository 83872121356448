import React, { useEffect, useState } from "react";
import ProducerProfile from "../components/Producer/ProducerProfile";
import { useSelector } from "react-redux";
import API from "../api";
import setAxiosAuthToken from "../utils/setAxiosAuthToken";

const ProducerProfilePage = ({ history }) => {
  const [downloads, setDownloads] = useState();
  const [referralIncome, setReferralIncome] = useState(0);
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    setAxiosAuthToken(localStorage.getItem("auth-token"));
    API.getProducerSongsDownloaded()
      .then((res) => {
        // console.log(res);
        setDownloads([...res]);
      })
      .catch((err) => console.log(err));
    API.getProducerRevenue()
      .then((res) => {
        if (res.revenue.length) setReferralIncome(res.revenue[0].total);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <ProducerProfile
      producer={user}
      downloads={downloads}
      referralIncome={referralIncome}
    />
  );
};

export default ProducerProfilePage;
