const PrivacyPolicy = () => (
  <div className="main2 policy">
    <div className="main--text">
      <div className="Header">
        <h2> LOUD Music Technology Co.</h2>
      </div>
    </div>

    <div className="main--text">
      ________________________________________
      <br />
    </div>
    <div className="main--text">
      <h3>Privacy Policy</h3>
      <br />
    </div>

    <div className="policy">
      <h3>Last updated: (December 1, 2018)</h3> <br />
      LOUD ("us", "we", or "our") operates Loud.army. This page informs you of
      our policies regarding the collection, use and disclosure of Personal
      Information we receive from users of the Site.
      <br />
      We use your Personal Information only for providing and improving the
      Site. By using the Site, you agree to the collection and use of
      information in accordance with this policy.
      <br />
      <br />
      <h4>Information Collection And Use</h4>
      While using our Site, we may ask you to provide us with certain personally
      identifiable information that can be used to contact or identify you.
      Personally identifiable information may include, but is not limited to
      your name, contact information and social media.
      <br />
      <br />
      <h4>Log Data</h4>
      Like many site operators, we collect information that your browser sends
      whenever you visit our site. The information we collect is location data
      which is aggregated and anonymous. We use third party services such as
      Google Analytics that collect, monitor and analyzes this data. We do NOT
      collect any information on users visiting SOUND links. SOUND links are
      links that our site generates to give full previews of beats that
      producers have uploaded on the site. <br />
      <br />
      <br />
      <h4>Cookies</h4>
      Cookies are files with small amount of data, which may include an
      anonymous unique identifier. Cookies are sent to your browser from a web
      site and stored on your computer's hard drive.
      <br />
      Like many sites, we use "cookies" to collect information. You can instruct
      your browser to refuse all cookies or to indicate when a cookie is being
      sent. However, if you do not accept cookies, you may not be able to use
      some portions of our Site.
      <br />
      <br />
      <h4>Security</h4>
      The security of your Personal Information is important to us but remember
      that no method of transmission over the Internet, or method of electronic
      storage, is 100% secure. While we strive to use commercially acceptable
      means to protect your Personal Information, we cannot guarantee its
      absolute security.
      <br />
      <br />
      <h4>Changes To This Privacy Policy</h4>
      This Privacy Policy is effective as of December 1, 2018 and will remain in
      effect except with respect to any changes in its provisions in the future,
      which will be in effect immediately after being posted on this page.
      <br />
      We reserve the right to update or change our Privacy Policy at any time
      and you should check this Privacy Policy periodically. Your continued use
      of the Service after we post any modifications to the Privacy Policy on
      this page will constitute your acknowledgment of the modifications and
      your consent to abide and be bound by the modified Privacy Policy.
      <br />
      If we make any material changes to this Privacy Policy, we will notify you
      either through the email address you have provided us, or by placing a
      prominent notice on our website.
      <br />
      <br />
      <h4>Contact Us</h4>
      If you have any questions about this Privacy Policy, please contact us.
    </div>
  </div>
);

export default PrivacyPolicy;
