import React, { useEffect, forwardRef } from "react";
import { motion } from "framer-motion";
import { Player } from "@lottiefiles/react-lottie-player";
import {  shareSongCopyLink } from "./utils";
// Assets
import PlayBtnDark from "../../images/play-button-dark.svg";
import PlayBtnLight from "../../images/play-button-light.svg";
import PlayingIconBlack from "../../LottieAnimations/playing-icon-black.json";
import PlayingIconWhite from "../../LottieAnimations/playing-icon-white.json";
import ShareIconDark from "../../images/share-light.svg";
import ShareIconLight from "../../images/share-dark.svg";
const Beat = ({
  ref,
  song,
  currentSong,
  setCurrentSong,
  isPlaying,
  setIsPlaying,
  darkMode,
}) => {
  useEffect(() => {
    if (currentSong) {
      if (currentSong.id !== song.id) {
        setIsPlaying(false);
      } else {
        setIsPlaying(true);
      }
    }
  }, [currentSong]);

  const handleSelectSong = () => {
    setCurrentSong(song);
    setIsPlaying(true);
  };

  return (
    <motion.div
      ref={ref}
      whileHover={{
        backgroundColor: darkMode ? "#1a1a1a" : "#ecf0f1",
        transition: { duration: 0.2 },
      }}
      onTap={handleSelectSong}
      animate={{
        backgroundColor:
          currentSong?.id === song?.id
            ? darkMode
              ? "#1a1a1a"
              : "#fff"
            : "rgba(0,0,0,0)",
        boxShadow:
          currentSong?.id === song?.id && !darkMode
            ? "0px 0px 10px 2px rgba(0, 0, 0, 0.1)"
            : "0px 0px 0px 0px rgba(0, 0, 0, 0.1)",
        scale: 1,
      }}
      className="sound-beat-item">
      <div className="beat-content">
        <div className="beat-main">
          {currentSong?.id === song?.id && isPlaying ? (
            <>
              <motion.button
                whileHover={{
                  scale: 0.95,
                  transition: { duration: 0.2 },
                }}
                whileTap={{ scale: 1 }}
                onTap={() => {
                  setIsPlaying(false);
                }}
                className="playing-btn">
                <Player
                  className="playing-btn"
                  autoplay
                  loop
                  src={darkMode ? PlayingIconBlack : PlayingIconWhite}></Player>
              </motion.button>
            </>
          ) : (
            <motion.img
              whileHover={{
                scale: 0.95,
                transition: { duration: 0.2 },
              }}
              whileTap={{ scale: 1 }}
              className="play-btn"
              src={darkMode ? PlayBtnDark : PlayBtnLight}
              onTap={handleSelectSong}></motion.img>
          )}
          <div className="beat-name">
            <h1>{song?.title}</h1>
            <h3>{song?.producer.nickname}</h3>
          </div>
        </div>
        <div className="beat-actions">
          <motion.button
            initial={{ opacity: 0.2 }}
            whileHover={{
              scale: 1,
              opacity: 1,
              transition: { duration: 0.2 },
            }}
            whileTap={{ scale: 1 }}
            onTap={() => shareSongCopyLink(currentSong.id)}
            className="beat-action">
            <img src={darkMode ? ShareIconDark : ShareIconLight}></img>
          </motion.button>
        </div>
      </div>
    </motion.div>
  );
};

const forwardedBeat = forwardRef(Beat);

export default forwardedBeat;
