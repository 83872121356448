import React, { useEffect } from "react";
import Home from "../components/Home";
import TagManager from "react-gtm-module";


const world = () =>{
    TagManager.dataLayer({
        dataLayer: {
            'event': "Lead",
            'referral': "/world"
        },
    });
    return (
        <div>
            <Home />
        </div>
    );
};

export default world;

