import React from "react";
import { Link } from "react-router-dom";
import successImg from "../../images/tick.svg";
import Fade from "react-reveal/Fade";
import TagManager from 'react-gtm-module'

const SignupSuccess = (formData) => {
  TagManager.dataLayer({
    dataLayer: {
      'event': 'Conversion',
      'conversion': 'conversionSignup'
    }
  });
  return (
    <div className="signup__success">
      <Fade bottom>
        <div className="signup__card">
          <h1>All Done</h1>
          <img src={successImg} alt="signup successful" />
          <p>
            We are glad to have you on LOUD. <br />
            You can cancel at anytime by emailing <br />
            <span>memberships@loud.army</span>
          </p>
          {formData.formData.plan === 'stream' ? (
              <Link className="white-btn red" to="/stream/signin">
                View Account
              </Link>
          ) : (
              <Link className="white-btn red" to="/manage-subscription">
                View Account
              </Link>
          )}
        </div>
      </Fade>
    </div>
  );
};

export default SignupSuccess;
