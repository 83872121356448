import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Signin from "../components/Signin";
import { useLocation } from "react-router-dom";
import { authSuccess } from "../actions";

const StreamerSigninPage = ({ history }) => {
  const location = useLocation();
  const { isSignedIn } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  let { from } = location.state || { from: { pathname: "/" } };

  const navigate = (user) => {
    const sharedSongId = localStorage.getItem("sharedSongId");
    if (!user) {
      user = localStorage.getItem("user");
    }
    if (sharedSongId || user.userType === 'listener') {
      history.push("/stream");
    }  else {
      history.replace(from);
    }
  };

  const onSigninSuccess = (user) => {
    dispatch(authSuccess(user));
    navigate(user);
  };

  useEffect(() => {
    if (isSignedIn) {
      const user = localStorage.getItem("user");
      navigate(user);
    }
  }, [isSignedIn, from, history]);

  let userType = 'listener';
  return <Signin onSigninSuccess={onSigninSuccess} userType={userType}/>;
};

export default StreamerSigninPage;
