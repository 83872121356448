import { takeEvery, call, put } from "redux-saga/effects";
import { AUTH_ERROR, LOAD_USER, AUTH_SUCCESS } from "../actions/types";
import API from "../api";

function* handleLoadUser() {
  try {
    const user = yield call(API.loadUser);
    yield put({ type: AUTH_SUCCESS, payload: user });
  } catch (error) {
    yield put({ type: AUTH_ERROR });
  }
}

function* authWatcher() {
  yield takeEvery(LOAD_USER, handleLoadUser);
}

export default authWatcher;
