import React, { useState } from "react";
import API from "../../api";
import setAxiosAuthToken from "../../utils/setAxiosAuthToken";

const ProducerSignupSuccess = () => {
  const [loading, setLoading] = useState(false);
  const sendEmailConfirmation = () => {
    setLoading(true);
    setAxiosAuthToken(localStorage.getItem("auth-token"));
    API.sendProducerEmailConfirmation()
      .then((data) => {
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  return (
    <div className="producerSignup__success">
      <div className="title">Congratulations</div>
      <p>You are now a LOUD producer</p>
      <p>Please check your email to confirm your email address</p>
      <p>
        If you did not receive an email after 15 minutes click the resend button
        below to send email
      </p>
      <button
        disabled={loading}
        className="white-btn"
        onClick={sendEmailConfirmation}
      >
        {loading ? "Please wait..." : "resend"}
      </button>
    </div>
  );
};

export default ProducerSignupSuccess;
