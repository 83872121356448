import React, { useState, useEffect, forwardRef } from "react";
import menuIcon from "../../images/menu.svg";
import audioOn from "../../images/speaker-play.svg";
import audioOff from "../../images/speaker-mute.svg";
import incomplete from "../../images/incomplete.png";
import { Link, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { authError } from "../../actions";
import Fade from "react-reveal/Fade";

const homeSongs = [
  "https://storage.googleapis.com/loudbeta/songs/dawn/dawn-1615742021265-TB382.mp3",
  "https://storage.googleapis.com/loudbeta/Home%20Audio/JT%20-%20pink.mp3",
  "https://storage.googleapis.com/loudbeta/Home%20Audio/All%20my%20love-drenathan-1614528257709.mp3",
];
function randomIntFromInterval(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

const HomeHeader = forwardRef(
  (
    {
      withAudio,
      incompleteRegistration,
      setHomeAudioPlaying,
      handleAudioPlay,
      sharedSong,
      isSharedPage,
      isWhiteBackground,
    },
    ref
  ) => {
    const [audioPlaying, setAudioPlaying] = useState(false);
    const [isNavActive, setIsNavActive] = useState(false);
    const [currentAudio, setCurrentAudio] = useState();
    const [autoPlay, setAutoPlay] = useState(true)
    const dispatch = useDispatch();
    const { isSignedIn, user, loading } = useSelector((state) => state.auth);
    const history = useHistory();
    const [songType, setSongType] = useState('');

    const handleSignOut = () => {
      let userType = user.userType;
      dispatch(authError());
      if (userType === 'listener') {
        history.push("/stream/signin");
      } else {
        history.push("/");
      }
    };

    const playNextSong = () => {
      if (sharedSong) return;
      const currentSongIndex = homeSongs.findIndex(
        (song) => song === currentAudio
      );
      currentSongIndex === homeSongs.length - 1
        ? setCurrentAudio(homeSongs[0])
        : setCurrentAudio(homeSongs[currentSongIndex + 1]);
      handleAudioPlay();
    };

    useEffect(() => {
      if (withAudio) {
        if (audioPlaying) {
          setHomeAudioPlaying(true);
        } else {
          setHomeAudioPlaying(false);
        }
      }
    }, [audioPlaying, setHomeAudioPlaying, withAudio]);

    useEffect(() => {
      if (isSharedPage && sharedSong) {
        setCurrentAudio(sharedSong.audio);
        setSongType(sharedSong.type);
      } else if (!isSharedPage) {
        setCurrentAudio(homeSongs[randomIntFromInterval(0, 2)]);
        setSongType('');
      }
    }, [isSharedPage, sharedSong]);

    useEffect(() => {
      if(isSharedPage){
        setAutoPlay(false);
      }
    }, [])


    return (
      <>
        <div
          className={isWhiteBackground ? "home__header white" : "home__header"}>
          <h1>LOUD</h1>
          <div className="menu-bar-wrapper">
            {withAudio && (
              <div className="audio-wrapper">
                <div className="audio" onClick={handleAudioPlay}>
                  <img src={audioPlaying ? audioOn : audioOff} alt={menuIcon} />
                </div>
                <audio
                  hidden
                  src={currentAudio}
                  onPlay={() => setAudioPlaying(true)}
                  onPause={() => setAudioPlaying(false)}
                  ref={ref}
                  onEnded={playNextSong}
                  autoPlay={autoPlay}></audio>
              </div>
            )}

            <header className="menu-bar">
              <div
                className={isNavActive ? "menu-icon active" : "menu-icon"}
                onClick={() => setIsNavActive(!isNavActive)}>
                <input className="menu-icon__cheeckbox" type="checkbox" />
                <div>
                  <span></span>
                  <span></span>
                </div>
              </div>
              {incompleteRegistration && (
                <img className="incomplete" src={incomplete} alt="loud.army" />
              )}
              {isNavActive && (
                <Fade>
                  <nav className="nav">
                    <ul>
                      {!isSignedIn && (
                          <React.Fragment>
                            <li>
                              {songType === 'song' ? (
                                  <Link to="/stream/signup">Sign up</Link>
                              ) : (
                                  <Link to="/signup">Sign up</Link>
                              )}{" "}
                              or{" "}
                              {songType === 'song' ? (
                                  <Link to="/stream/signin">Sign In</Link>
                              ) : (
                                  <Link to="/signin">Sign In</Link>
                              )}
                            </li>
                          </React.Fragment>
                      )}

                      {isSignedIn && user.userType === "artiste" && (
                        <React.Fragment>
                          <li>
                            <Link to="/sound">Sound App</Link>
                          </li>

                          <li>
                            <Link to="/manage-subscription">
                              Membership info
                            </Link>
                          </li>
                          <li>
                            <a
                              href="https://storage.googleapis.com/loudbeta/utils/SOUND%20terms%20of%20service.pdf"
                              target="_blank"
                              className="download"
                              rel="noreferrer">
                              Terms of service
                            </a>
                          </li>
                        </React.Fragment>
                      )}
                      {isSignedIn && user.userType === "listener" && (
                        <React.Fragment>
                          <li>
                            <Link to="/stream">Stream App</Link>
                          </li>

                          <li>
                            <Link to="/manage-subscription">
                              Membership info
                            </Link>
                          </li>
                          <li>
                            <a
                              href=""
                              target="_blank"
                              className="download"
                              rel="noreferrer">
                              Terms of service
                            </a>
                          </li>
                        </React.Fragment>
                      )}
                      {isSignedIn && user.userType === "producer" && (
                        <React.Fragment>
                          <li>
                            <Link to="/producer">My Dashboard</Link>
                          </li>
                          <li>
                            <Link to="/new-song">Upload Beat</Link>
                          </li>
                          <li>
                            <Link to="/add-song">Upload Song</Link>
                          </li>
                          <li>
                            <Link to="/producer/beats">My Beats</Link>
                          </li>
                          <li>
                            <Link to="/producer/songs">My Songs</Link>
                          </li>
                          <li>
                            <a
                              href="https://storage.googleapis.com/loudbeta/utils/SOUND%20terms%20of%20service.pdf"
                              target="_blank"
                              className="download"
                              rel="noreferrer">
                              Terms of service
                            </a>
                          </li>
                        </React.Fragment>
                      )}
                      {isSignedIn && <li onClick={handleSignOut}>Sign out</li>}
                      {/*                      <li>
                        <a
                          href="https://storage.googleapis.com/loudbeta/utils/SOUND%20terms%20of%20service.pdf"
                          target="_blank"
                          className="download"
                        >
                          Terms of service
                        </a>
                      </li>*/}
                      {/* <li>
                <Link to="/">Home</Link>
              </li> */}
                    </ul>
                  </nav>
                </Fade>
              )}
            </header>
          </div>
        </div>
      </>
    );
  }
);

export default HomeHeader;
