import React, { useEffect, useState } from 'react';
import { copyTextToClipBoard } from '../../utils/helpers';
import ProducerLayout from './ProducerLayout';

const ProducerProfile = ({ producer, downloads, referralIncome }) => {
  const [referralLink, setReferralLink] = useState();
  const [streamLink, setStreamLink] = useState();
  const [copyButtonText, setCopyButtonText] = useState('Copy referral Link');
  const [copyStreamButtonText, setCopyStreamButtonText] = useState('Copy Stream Link');
  useEffect(() => {
    if (producer) {
      setReferralLink(`https://app.loud.army/signup/${producer.nickname}`);
      setStreamLink(`https://app.loud.army/stream/signup/${producer.nickname}`);
    }
  }, [producer]);

  const copyReferalLinkToClipBoard = () => {
    const copy = copyTextToClipBoard(referralLink);
    if (copy) {
      setCopyButtonText('Link Copied');
      setTimeout(() => setCopyButtonText('Copy referral Link'), 2500);
    }
  };

  const copyStreamLinkToClipBoard = () => {
    const copy = copyTextToClipBoard(streamLink);
    if (copy) {
      setCopyStreamButtonText('Link Copied');
      setTimeout(() => setCopyStreamButtonText('Copy STREAM Link'), 2500);
    }
  };

  return (
    <ProducerLayout>
      <div className="producer">
        <div className="producer__name">{producer && producer.nickname}</div>
        {/*<div className="producer__title">Beat Dashboard</div>*/}
        <div className="producer__link mt-2"><strong>SOUND LINK: </strong> {referralLink}</div>
        <div className="producer__link mb-3"><strong>STREAM LINK: </strong>{streamLink}</div>
        {downloads && downloads.length ? (
          <div className="producer__downloads">
            <div className="download-wrapper">
              <div className="download-headings">
                <div>Client</div>
                <div>No of Beats</div>
                <div>Splits</div>
              </div>
              {downloads.map((item, i) => (
                <React.Fragment key={i}>
                  {item.artiste[0] && (
                    <div className="download-details">
                      <div>{item.artiste[0].email}</div>
                      <div>{item.total}</div>
                      <div>{item.split ? item.split : 0}</div>
                    </div>
                  )}
                </React.Fragment>
              ))}
            </div>
          </div>
        ) : (
          <div className="download-empty">You have no downloads yet</div>
        )}

        <div className="producer__income">
          <div className="income-heading">Referral Income</div>
          <div className="income">
            {referralIncome ? referralIncome : 0} CAD
          </div>
        </div>
        {/*<button onClick={copyReferalLinkToClipBoard} className="white-btn red">*/}
        {/*  {copyButtonText}*/}
        {/*</button>*/}
      </div>
    </ProducerLayout>
  );
};

export default ProducerProfile;
