import React, { useState, useEffect } from 'react';
import API from '../../api';
import { useStripe } from '@stripe/react-stripe-js';
import { validateSignupForm } from '../../utils/helpers';
import { toast } from 'react-toastify';
import Fade from 'react-reveal/Fade';
import { useHistory } from 'react-router-dom';

const SignupStep2 = ({
  formData,
  setFormData,
  setPage,
  setSubmissionError,
  signupUser,
  subscriptionPrice,
}) => {
  const [loading, setLoading] = useState();
  const stripe = useStripe();
  const history = useHistory();
  const handleChange = (e) => {
    const { name } = e.target;
    const value =
      name === 'email' ? e.target.value.trim().toLowerCase() : e.target.value;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const goToPageOne = () => {
    setPage(1);
    history.push(window.location.pathname);
  };

  const handlePaymentThatRequiresCustomerAction = ({
    subscription,
    invoice,
    priceId,
    paymentMethodId,
    isRetry,
  }) => {
    if (
      subscription &&
      (subscription.status === 'active' || subscription.status === 'trialing')
    ) {
      // Subscription is active, no customer actions required.
      return { subscription, priceId, paymentMethodId };
    }

    // If it's a first payment attempt, the payment intent is on the subscription latest invoice.
    // If it's a retry, the payment intent will be on the invoice itself.
    let paymentIntent = invoice
      ? invoice.payment_intent
      : subscription.latest_invoice.payment_intent;

    if (
      paymentIntent.status === 'requires_action' ||
      (isRetry === true && paymentIntent.status === 'requires_payment_method')
    ) {
      return stripe
        .confirmCardPayment(paymentIntent.client_secret, {
          payment_method: paymentMethodId,
        })
        .then((result) => {
          if (result.error) {
            // Start code flow to handle updating the payment details.
            // Display error message in your UI.
            // The card was declined (i.e. insufficient funds, card has expired, etc).
            setSubmissionError(result.error.message);
            toast.error(result.error.message, {
              position: 'top-left',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            goToPageOne();
            //thinking of deleting the customer when there is an error making payment because a new stripe customer is created at the start of the payment process
            throw result;
          } else {
            if (result.paymentIntent.status === 'succeeded') {
              // Show a success message to your customer.
              console.log('payment confirmed');
              return {
                priceId: priceId,
                subscription: subscription,
                invoice: invoice,
                paymentMethodId: paymentMethodId,
              };
            }
          }
        })
        .catch((error) => {
          // console.log(error, 'This is an errorororororo');
        });
    } else {
      // No customer action needed.
      return { subscription, priceId, paymentMethodId };
    }
  };

  function handleRequiresPaymentMethod({
    subscription,
    priceId,
    paymentMethodId,
  }) {
    if (
      subscription.status === 'active' ||
      subscription.status === 'trialing'
    ) {
      // subscription is active, no customer actions required.
      return { subscription, priceId, paymentMethodId };
    } else if (
      subscription.latest_invoice.payment_intent.status ===
      'requires_payment_method'
    ) {
      // Using localStorage to manage the state of the retry here,
      // feel free to replace with what you prefer.
      // Store the latest invoice ID and status.
      localStorage.setItem('latestInvoiceId', subscription.latest_invoice.id);
      localStorage.setItem(
        'latestInvoicePaymentIntentStatus',
        subscription.latest_invoice.payment_intent.status
      );
      setSubmissionError('Your card was declined');
      toast.error('Your card was declined', {
        position: 'top-left',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      goToPageOne();
      //thinking of deleting the customer when there is an error making payment because a new stripe customer is created at the start of the payment process
      throw new Error('Declined');
    } else {
      return { subscription, priceId, paymentMethodId };
    }
  }

  const onSubscriptionComplete = (result) => {
    //Create User Account
    // console.log('will create user account');
    signupUser(result.subscription.customer, result.subscription.id);

    // console.log('this subscription suceeded', result);
  };

  const goToNextPage = () => {
    const [isFormValid, errMsg] = validateSignupForm(
      formData,
      setSubmissionError
    );

    if (!isFormValid) {
      toast.error(errMsg, {
        position: 'top-left',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    if (isFormValid) {
      setLoading(true);
      const { email, paymentMethod, plan, secretCode, partnerName } = formData;

      API.processSignupPayment({
        email,
        paymentMethod,
        plan,
        secretCode,
        price: subscriptionPrice,
        partnerName,
      })
        .then((result) => {
          // console.log(result);
          return {
            paymentMethodId: formData.paymentMethod,
            priceId: subscriptionPrice,
            subscription: result.subscription,
          };
        })
        // Some payment methods require a customer to be on session
        // to complete the payment process. Check the status of the
        // payment intent to handle these actions.
        .then(handlePaymentThatRequiresCustomerAction)
        // If attaching this card to a Customer object succeeds,
        // but attempts to charge the customer fail, you
        // get a requires_payment_method error.
        .then(handleRequiresPaymentMethod)
        // No more actions required. Provision your service for the user.
        .then(onSubscriptionComplete)
        .catch((error) => {
          // console.log(error);
          if (error.data && error.data.message) {
            setSubmissionError(error.data.message);
            toast.error(error.data.message, {
              position: 'top-left',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            goToPageOne();
          }
        });
    }
  };

  return (
    <Fade bottom>
      <div className="signup__card">
        <h1 className="heading">ACCOUNT SETUP</h1>
        <div className="signup__step2">
          <div className="double-input">
            <input
              className="input-text"
              type="text"
              value={formData.firstname}
              onChange={handleChange}
              name="firstname"
              placeholder="First Name"
            />
            <input
              className="input-text"
              type="text"
              value={formData.lastname}
              onChange={handleChange}
              name="lastname"
              placeholder="Last Name"
            />
          </div>

          <input
            type="text"
            className="input-text"
            value={formData.email}
            onChange={handleChange}
            name="email"
            placeholder="Email Address"
            disabled={true}
          />

          <input
            type="password"
            className="input-text"
            value={formData.password}
            onChange={handleChange}
            name="password"
            placeholder="Password"
            autoComplete="off"
          />

          <input
            type="number"
            className="input-text"
            value={formData.phone}
            onChange={handleChange}
            name="phone"
            placeholder="Phone"
          />
        </div>
        <div className="signup__actions">
          <button
            disabled={loading}
            onClick={() => goToPageOne()}
            className="white-btn ns"
          >
            Back
          </button>
          <button
            disabled={loading}
            onClick={goToNextPage}
            className="white-btn"
          >
            {loading ? 'please wait...' : 'Finish'}
          </button>
        </div>
        <div className="signup__info">
          Are you having trouble signing up? contact us at info@loud.army
        </div>
      </div>
    </Fade>
  );
};

export default SignupStep2;
