import React, { useEffect, useRef, useState } from "react";
import songIcon from "../../images/songIcon.png";
import songSelectedIcon from "../../images/songSelectedIcon.png";

function Song({ song, handleSelectSong, currentSongId, headingRef }) {
  const [fadeOut, setFadeOut] = useState(false);
  const songRef = useRef(null);
  return (
    <a
      href="##"
      ref={songRef}
      className={fadeOut ? "song-wrapper fadeout" : "song-wrapper"}
      onClick={() => handleSelectSong(song)}
      aria-label={song.song}
    >
      <img
        src={currentSongId === song._id ? songSelectedIcon : songIcon}
        alt="song"
        className="icon"
      />
      <div className="producer">{song.producer.nickname}</div>
      <div className="beat">{song.title}</div>
    </a>
  );
}

export default Song;
