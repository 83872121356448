import { useState } from "react";
import { toast } from "react-toastify";
import API from "../../api";
import AdminLayout from "./AdminLayout";

function AdminArtistes() {
  const [email, setEmail] = useState("");
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [loading, setLoading] = useState(false);
  const [submissionError, setSubmissionError] = useState("");

  const createArtiste = () => {
    setSubmissionError("");
    if (!email || !firstname || !lastname) {
      setSubmissionError("All fields required");
      return;
    }
    setLoading(true);
    API.createOrganizationArtiste({
      email: email.trim().toLowerCase(),
      firstname,
      lastname,
    })
      .then(() => {
        toast.success("Artiste Created");
        setEmail("");
        setFirstname("");
        setLastname("");
        setLoading(false);
      })
      .catch((err) => {
        setSubmissionError(err.data.message);
        setLoading(false);
      });
  };
  return (
    <AdminLayout activeNav="artiste">
      <h3>Create organization artiste</h3>
      <div className="error-message"> {submissionError}</div>
      <div>
        <input
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
          type="text"
          className="input-text mr-2 w-50"
        />
        <input
          value={firstname}
          onChange={(e) => setFirstname(e.target.value)}
          placeholder="Firstname"
          type="text"
          className="input-text mr-2 w-50"
        />
        <input
          value={lastname}
          onChange={(e) => setLastname(e.target.value)}
          placeholder="Lastname"
          type="text"
          className="input-text mr-2 w-50"
        />
      </div>
      <button
        disabled={loading}
        onClick={createArtiste}
        className="white-btn"
        type="button"
      >
        {loading ? "Loading..." : "Create Artiste"}
      </button>
    </AdminLayout>
  );
}

export default AdminArtistes;
