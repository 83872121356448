import React from "react";
import { Link, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import menuBar from "../../images/menu-bar.png";
import incomplete from "../../images/incomplete.png";
import { authError } from "../../actions";

const SoundAppHeader = ({
  setShowNav,
  showNav,
  currentSong,
  showSideBeatDetails,
  user,
  setShowExportModal,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const logUserOut = () => {
    dispatch(authError());
    history.push("/");
  };

  return (
    <header className="soundApp__header">
      <div className="logo-menu">
        <a
          href="##"
          className="logo-container"
          onClick={() => setShowNav((prev) => !prev)}
        >
          <img
            id="soundapp_logo"
            className="soundapp_logo"
            src={menuBar}
            alt="loud.army"
          />
          {user && !user.completedRegistration && (
            <img
              id="soundapp_logo"
              className="soundapp_incomplete"
              src={incomplete}
              alt="loud.army"
            />
          )}
        </a>
      </div>
      <div className="now-playing">
        <div className="playing">NowPlaying</div>
        <div className={showSideBeatDetails ? "beat-info active" : "beat-info"}>
          <div className="producer">
            {currentSong && currentSong.producer.nickname}
          </div>
          <div className="beat">
            {currentSong && `${currentSong.title.substring(0, 6)}...`}
          </div>
        </div>
      </div>
      <div className={showNav ? "soundapp_nav visible" : "soundapp_nav"}>
        <ul>
          {/* <li>
              <a href="##">RNB SOUND</a>
            </li>
            <li>
              <a href="##">SOCA SOUND</a>
            </li>
            <li>
              <a href="##"> DANCEHALL SOUND</a>
            </li>
            <li>
              <a href="##">IMPULSE RIDDIMS</a>
            </li> */}
          {user && !user.completedRegistration && (
            <li className="incomplete-registration">
              <Link to="/manage-subscription">
                <span>COMPLETE YOUR REGISTRATION</span>
                <span>90%</span>
              </Link>
            </li>
          )}

          <li>
            <Link to="/manage-subscription" className="nav_links">
              MEMBERSHIP INFO
            </Link>
          </li>
          <li>
            <Link to="/sound" className="nav_links">
              NEW SOUND APP
            </Link>
          </li>
          <li>
            <Link
              to="##"
              className="nav_links"
              onClick={() => {
                if (currentSong) {
                  setShowNav(false);
                  setShowExportModal(true);
                }
              }}
            >
              REPORT
            </Link>
          </li>
          <li>
            <Link to="/" className="nav_links">
              HOME
            </Link>
          </li>
          <li>
            <p onClick={logUserOut} className="nav_links">
              LOG OUT
            </p>
          </li>
        </ul>
      </div>
    </header>
  );
};

export default SoundAppHeader;
