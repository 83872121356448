import React, { useState } from "react";
import API from "../api";

const ForgotPasswordPage = ({ match }) => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [email, setEmail] = useState("");
  const [submissionError, setSubmissionError] = useState("");
  const { user } = match.params;

  const handleResetPassword = () => {
    setSubmissionError("");
    if (!email) {
      setSubmissionError("Please enter your email");
      return;
    }
    setLoading(true);
    API.handleForgotPassword({ email: email.toLowerCase().trim() }, user)
      .then((res) => {
        console.log(res);
        setSuccess(true);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setSubmissionError(err.data.message);
      });
  };

  return (
    <div className="container">
      <div className="forgotpass__card">
        <div className="forgotPassword">
          <div className="forgotPassword__title">
            Reset your {user} password
          </div>
          <div className="error-message">{submissionError}</div>
          {success ? (
            <React.Fragment>
              <p>
                We just sent you an email with futher instructions on how to
                reset your password
              </p>
              <p>
                If you have not received an email after 15 minutes, check your
                spams and other email folders or click on the resend button
                bellow
              </p>
              <button
                disabled={loading}
                onClick={handleResetPassword}
                className="white-btn"
              >
                {loading ? "Please wait..." : "Resend"}
              </button>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <input
                type="text"
                placeholder="Please enter your email"
                className="input-text"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <button
                onClick={handleResetPassword}
                disabled={loading}
                className="white-btn"
              >
                {loading ? "Please wait..." : "Reset Password"}
              </button>
            </React.Fragment>
          )}
        </div>
      </div>
    </div>
  );
};

export default ForgotPasswordPage;
