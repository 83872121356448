import { useState } from "react";
import API from "../../api";
import AdminLayout from "./AdminLayout";

const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
const currentYear = new Date().getFullYear();
const YEARS = Array.from({ length: currentYear - 2021 + 1 }, (_, index) => (currentYear - index).toString());

function AdminProducers() {
  const [year, setYear] = useState(new Date().getFullYear());
  const [month, setMonth] = useState(new Date().getMonth() - 1);
  const [loading, setLoading] = useState(false);
  const [earnings, setEarnings] = useState("");
  const [producer, setProducer] = useState("");
  const [filteredEarnings, setFilteredEarnings] = useState("");

  const filterProducers = (e) => {
    const { value } = e.target;
    setProducer(value);
    if (!value) setFilteredEarnings(earnings);
    else
      setFilteredEarnings(
        filteredEarnings.filter((earning) =>
          earning.nickname.toLowerCase().includes(value.toLowerCase())
        )
      );
  };

  const getEarnings = () => {
    setLoading(true);
    API.getProducerEarnings({ month, year }).then((res) => {
      setEarnings(res);
      setFilteredEarnings(res);
      setLoading(false);
    });
  };
    const exportProducersList = () => {
        setLoading(true);
        API.producerExport().then((res) => {
            // Create a URL for the Blob
            const url = window.URL.createObjectURL(new Blob([res]));

            // Set the file name for the download to "producers.csv"
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'producers.csv');

            // Append the link to the body and trigger click to download
            document.body.appendChild(link);
            link.click();

            // Clean up and revoke the URL
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);

            // Reset loading state
            setLoading(false);
        }).catch(error => {
            console.error('Error:', error);
            setLoading(false);
        });
    };



    const handlePaidEarning = async (paid, id) => {
    API.updateProducerEarnings(id, paid).then(() => {
      const newEarnings = filteredEarnings.map((earning) => {
        if (earning._id === id) {
          return { ...earning, paid };
        }
        return earning;
      });
      setFilteredEarnings(newEarnings);
    });
  };

  return (
    <AdminLayout activeNav="producer">
      <label className="mr-1">Year</label>
      <select
        className="mr-2"
        onChange={({ target }) => setYear(target.value)}
        value={year}
      >
        {YEARS.map((item) => (
          <option value={item} key={item}>
            {item}
          </option>
        ))}
      </select>
      <label className="mr-1">Month</label>
      <select
        className="mr-2"
        onChange={({ target }) => setMonth(target.value)}
        value={month}
      >
        {MONTHS.map((item, i) => (
          <option value={i} key={item}>
            {item}
          </option>
        ))}
      </select>
      <button
        disabled={loading}
        onClick={getEarnings}
        className="white-btn"
        type="button"
      >
        Get report
      </button>

      <button
        disabled={loading}
        onClick={exportProducersList}
        className="white-btn ml-2"
        type="button"
      >
          All Producers Info
      </button>
      {earnings && earnings.length > 0 && (
        <div>
          <div>
            <input
              value={producer}
              onChange={filterProducers}
              placeholder="Filter by producer name"
              type="text"
              className="input-text mr-2 w-50"
            />
          </div>
          <div>
            <div className="d-flex mt-2 mb-1">
              <div className="w-10">Rank</div>
              <div className="w-15">Producer</div>
              <div className="w-15">Total Referrals</div>
              <div className="w-15">Earnings</div>
              <div className="w-10">Paid</div>
            </div>
            {filteredEarnings &&
              filteredEarnings.map((item, i) => (
                <div key={item._id} className="d-flex mb-1">
                  <div className="w-10">{i + 1}</div>
                  <div className="w-15">{item.nickname}</div>
                  <div className="w-15">{item.totalReferrals}</div>
                  <div className="w-15">{item.totalRevenue}</div>
                  <div className="w-10">
                    <input
                      type="checkbox"
                      className="checkbox"
                      checked={item.paid}
                      onChange={(e) =>
                        handlePaidEarning(e.target.checked, item._id)
                      }
                    />
                  </div>
                </div>
              ))}
          </div>
        </div>
      )}
    </AdminLayout>
  );
}

export default AdminProducers;
