import React, { useState, useEffect } from "react";
import HomeHeader from "../Home/HomeHeader";
import { useSelector } from "react-redux";
import incomplete from "../../images/incomplete.png";
import Modal from "../Modal";
import CompleteRegistration from "./CompleteRegistration";
import API from "../../api";
import ReactivateSound from "./ReactivateSound";
import ReactivateStream from "./ReactivateStream";
import ArtisteReferral from "./ArtisteReferral";
import UserReferral from "./UserReferral";
import { useHistory } from "react-router";

const MembershipInfo = () => {
  const [incompleteRegistration, setIncompleteRegistration] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [cancelButtonLoading, setCancelButtonLoading] = useState(false);
  const [referralModalOpen, setReferralModalOpen] = useState(false);
  const { user } = useSelector((state) => state.auth);
  const [reactivateModal, setReactivateModal] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (user && (user.userType === "artiste" || user.userType === "listener") && !user.completedRegistration) {
      setIncompleteRegistration(true);
    } else {
      setIncompleteRegistration(false);
    }
  }, [user]);

  const cancelSubscription = () => {
    setCancelButtonLoading(true);
    API.cancelSubscription().then(() => {
      window.location.reload();
    });
  };

  return (
    <React.Fragment>
      {user && (
          <div className={user.userType === "listener" ? "membership membership-listener-height" : "membership membership-height"}>
          {modalOpen && (
            <Modal setIsOpen={setModalOpen}>
              <CompleteRegistration setIsOpen={setModalOpen} />
            </Modal>
          )}
          {referralModalOpen && user.userType === "artiste" && (
            <Modal setIsOpen={setReferralModalOpen}>
              <ArtisteReferral user={user} setIsOpen={setReferralModalOpen} />
            </Modal>
          )}
          {reactivateModal && (user.userType === "artiste" || user.userType === "listener") && (
        <Modal setIsOpen={setReactivateModal}>
          <ReactivateSound setIsOpen={setReactivateModal} user={user}/>
        </Modal>
          )}
        {/*  {reactivateModal && user.userType === "listener" && (*/}
        {/*<Modal setIsOpen={setReactivateModal}>*/}
        {/*  <ReactivateStream setIsOpen={setReactivateModal} />*/}
        {/*</Modal>*/}
        {/*  )}*/}
          <HomeHeader
            incompleteRegistration={incompleteRegistration}
            isWhiteBackground
          />
          <div className="membership__info">
            <div className="title">Membership Info</div>
            <div className="details">
              {user.subscriptionStatus === "active" && (
                <React.Fragment>
                  {user.subscriptionType === "sound" && (
                    <p>Active $9/Month Sound</p>
                  )}
                  {user.subscriptionType === "stream" && (
                    <p>Active $9/Month Stream</p>
                  )}
                  {user.subscriptionType === "trial" && <p>Active: Trial</p>}
                  {user.subscriptionType === "secret" && (
                    <p>Active Secret Service</p>
                  )}
                  {/* {user.subscriptionType === "sound" && (
                    <button
                      onClick={() => history.push("/payment-update")}
                      className="white-btn"
                    >
                      Billing Info
                    </button>
                  )} */}

                </React.Fragment>
              )}
              {user.subscriptionStatus !== "active" && (
                <React.Fragment>
                  <p>No active subcription</p>
                  <button onClick={() => setReactivateModal(true)} className="white-btn">Reactivate</button>
                </React.Fragment>
              )}
            </div>
          </div>
          {incompleteRegistration && (
            <div className="membership__incomplete">
              <div className="title">incomplete account</div>
              <div className="progress-bar">
                <span></span>
              </div>
              <button
                onClick={() => setModalOpen(true)}
                className="white-btn red font2"
              >
                Finish paperwork
                <img src={incomplete} alt="incomplete" />
              </button>
            </div>
          )}
          {/* <p>Referral Link: {`http://localhost:3000/signup/artiste/${user._id}`}</p>
          {user.subscriptionStatus === "active" && (
            <button className="membership__cancel">Cancel Membership</button>
          )} */}
          {user && user.userType !== "listener" && (
          <div className="membership__referral">
            <div className="heading">
              Get your membership for free just by refering people
            </div>
            <button
              onClick={() => setReferralModalOpen(true)}
              className="white-btn"
            >
              invite friends
            </button>
            <p>
              We give you $1.00 off for every person that you refer and you keep
              that discount for as long as they stay a member. The great news is
              we also give them a $1.00 off too
            </p>
          </div>
          )}
      {user && user.subscriptionStatus === "active" && (
        <button
          disabled={cancelButtonLoading}
          onClick={cancelSubscription}
          className="membership__cancel"
        >
          {cancelButtonLoading ? "Processing..." : "Cancel Membership"}
        </button>
      )}
    </div>
    )}
    </React.Fragment>
  );
};

export default MembershipInfo;
