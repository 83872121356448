import { copyTextToClipBoard } from '../../utils/helpers';
import { toast } from 'react-toastify';

export const shareSong = (id) => {
  copyTextToClipBoard(`https://app.loud.army/share/${id}`);
  toast('Link Copied');
};

export const shareSongCopyLink = (id) => {
  localStorage.setItem('sharedSongId', id);
  copyTextToClipBoard(`https://app.loud.army/share/${id}`);
  toast('Link Copied');
};
