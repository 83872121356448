import { useMemo, useEffect, useState } from "react";

export const monthOptions = [
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
];

export const getYearOptions = () => {
  let currentYear = new Date().getFullYear();
  const yearOptions = [];
  for (let year = currentYear; year <= currentYear + 10; year++) {
    yearOptions.push(year);
  }
  return yearOptions;
};

export function useResponsiveFontSize() {
  const getFontSize = () => (window.innerWidth < 450 ? "16px" : "18px");
  const [fontSize, setFontSize] = useState(getFontSize);

  useEffect(() => {
    const onResize = () => {
      setFontSize(getFontSize());
    };

    window.addEventListener("resize", onResize);

    return () => {
      window.removeEventListener("resize", onResize);
    };
  });

  return fontSize;
}

export const useOptions = () => {
  const fontSize = useResponsiveFontSize();
  const options = useMemo(
    () => ({
      style: {
        base: {
          fontSize,
          color: "white",
          letterSpacing: "0.025em",
          fontFamily: "jost",
          fontWeight: "200",
          "::placeholder": {
            color: "white",
            opacity: "0.4",
            fontWeight: "200",
            fontFamily: "jost",
          },
        },
        invalid: {
          color: "#ec3432",
        },
      },
    }),
    [fontSize]
  );

  return options;
};

export const validateSignupForm = (formData, setSubmissionError) => {
  setSubmissionError("");
  const { email, firstname, lastname, password, phone } = formData;
  if (!firstname || !lastname || !email || !password || !phone) {
    setSubmissionError("All fields are required");
    return [false, "All fields are required"];
  }
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (email && !re.test(email)) {
    setSubmissionError("Please enter a valid email");
    return [false, "Please enter a valid email"];
  }
  if (password && password.length < 6) {
    setSubmissionError("Password must be at least six characters");
    return [false, "Please enter a valid email"];
  }
  return [true, ""];
};

export const copyTextToClipBoard = (text) => {
  const input = document.createElement("textarea");
  input.innerHTML = text;
  document.body.appendChild(input);
  input.select();
  const copy = document.execCommand("copy");
  document.body.removeChild(input);
  return copy;
};

export const getQueryVariable = (variable) => {
  var query = window.location.search.substring(1);
  var vars = query.split("&");
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=");
    if (pair[0] == variable) {
      return pair[1];
    }
  }
  return false;
};
