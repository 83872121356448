import React, { useState } from 'react';
import closeButton from '../../images/exitButton.png';
import API from '../../api';
import successImage from '../../images/exportSuccess.png';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { copyTextToClipBoard } from '../../utils/helpers';

const ArtisteReferral = ({ setIsOpen, user }) => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [emails, setEmails] = useState({
    0: '',
    1: '',
    2: '',
    3: '',
    4: '',
  });
  const [isValidEmails, setIsValidEmails] = useState(false);
  const artisteReferralLink = `app.loud.army/signup/artiste/${user._id}`;
  const handleChange = (e, position) => {
    const { value } = e.target;
    setEmails((prev) => {
      const newEmails = { ...prev, [position]: value };
      validateEmails(newEmails);
      return { ...prev, [position]: value };
    });
  };

  const validateEmails = (emailsTovalidate) => {
    const validEmails = Object.values(emailsTovalidate).filter(
      (item) => !!item
    );
    if (validEmails.length) setIsValidEmails(true);
    else setIsValidEmails(false);
  };

  const createReferrals = () => {
    setLoading(true);
    const refEmails = Object.values(emails).filter((item) => !!item);
    API.createArtisteReferrals({ emails: refEmails })
      .then(() => {
        setSuccess(true);
      })
      .catch();
  };

  const copyReferralLink = () => {
    copyTextToClipBoard(artisteReferralLink);
    toast('Link Copied');
  };
  return (
    <div className="loud-window artisteReferral">
      <div className="top">
        <div className="title">
          <h1>Invite</h1>
          <img
            onClick={() => setIsOpen(false)}
            src={closeButton}
            alt="close Button"
            className="close"
          />
        </div>
      </div>
      <div className="referral-link">
        <div>Refferal Link:</div>
        <p>{artisteReferralLink}</p>
      </div>
      {success ? (
        <React.Fragment>
          <div className="success">
            <img src={successImage} alt="success" />
            <p>Invites Sent</p>
            <div>
              <Link className="white-btn" to="/artiste/referral">
                My Referrals
              </Link>
            </div>
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <div className="referral-emails">
            <input
              onChange={(e) => handleChange(e, 0)}
              value={emails[0]}
              type="text"
              className="input-text"
            />
            <input
              type="text"
              className="input-text"
              onChange={(e) => handleChange(e, 1)}
              value={emails[1]}
            />
            <input
              type="text"
              className="input-text"
              onChange={(e) => handleChange(e, 2)}
              value={emails[2]}
            />
            <input
              type="text"
              className="input-text"
              onChange={(e) => handleChange(e, 3)}
              value={emails[3]}
            />
            <input
              type="text"
              className="input-text"
              onChange={(e) => handleChange(e, 4)}
              value={emails[4]}
            />
          </div>
          <div className="actions">
            <button onClick={copyReferralLink} className="white-btn">
              Copy Link
            </button>
            <button
              onClick={createReferrals}
              className="white-btn"
              disabled={!isValidEmails || loading}
            >
              {loading ? 'Loading' : 'Submit'}
            </button>
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

export default ArtisteReferral;
