import React, { useState } from "react";
import API from "../../api";
import AdminLayout from "./AdminLayout";
import DeleteSong from "./DeleteSong";

function AdminSongs() {
  const [producer, setProducer] = useState("");
  const [submissionError, setSubmissionError] = useState("");
  const [songs, setSongs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedSong, setSelectedSong] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);

  const searchSongs = () => {
    setSubmissionError("");
    if (!producer) {
      setSubmissionError("Please enter producer name");
      return;
    }
    setLoading(true);
    API.getSongsByProducer(producer)
      .then((res) => {
        setSongs(res.songs);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setSubmissionError(err.data.message);
      });
  };

  const openDeleteModal = (song) => {
    setSelectedSong(song);
    setDeleteModal(true);
  };

  const handleSongDeleted = () => {
    setSongs((prevState) =>
      prevState.filter((song) => song._id !== selectedSong._id)
    );
    setSelectedSong(null);
  };

  return (
    <AdminLayout activeNav="song">
      {deleteModal && (
        <DeleteSong
          song={selectedSong}
          setIsOpen={setDeleteModal}
          handleSongDeleted={handleSongDeleted}
        />
      )}
      <h1>Songs</h1>
      <div className="error-message">{submissionError}</div>
      <div>
        <input
          value={producer}
          onChange={(e) => setProducer(e.target.value)}
          placeholder="Producer"
          type="text"
          className="input-text mr-2 w-50"
        />
        <button
          disabled={loading}
          onClick={searchSongs}
          className="white-btn"
          type="button"
        >
          {loading ? "Loading..." : "Search"}
        </button>
      </div>
      <div className="d-flex w-100 mt-2 mb-2">
        <div className="w-10">Title</div>
        <div className="w-10">Producer</div>
        <div className="w-10">Plays</div>
        <div className="w-10">Saves</div>
      </div>

      {songs.length > 0 &&
        songs.map((song) => (
          <div key={song._id} className="d-flex mb-2">
            <div className="w-10">{song.title}</div>
            <div className="w-10">{song.producer.nickname}</div>
            <div className="w-10">{song.plays}</div>
            <div className="w-10">{song.saves}</div>
            <button
              onClick={() => openDeleteModal(song)}
              className="icon-btn w-10"
              type="button"
            >
              <i className="fa fa-trash"></i>
            </button>
          </div>
        ))}
    </AdminLayout>
  );
}

export default AdminSongs;
