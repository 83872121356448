import React from "react";
import successNew from "../../images/successNew.png";

const Success = () => {
  return (
    <div className="success">
      <h1>PHASE ONE DONE</h1>
      <img src={successNew} alt="sucess" />
      <p>
        You completed the first step to entering the $1000 producer challenge.
        You must attend your intake session to be eligible. This is where you
        will set up your LOUD producer account and upload your beats and gain
        access to your producer dashboard where you can view your beats, stats
        and links
      </p>
    </div>
  );
};

export default Success;
