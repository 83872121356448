import React, { useState } from "react";
import closeButton from "../../images/exitButton.png";
import API from "../../api";

const DeleteSong = ({ setIsOpen, handleSongDeleted, song }) => {
  const [loading, setLoading] = useState(false);
  const [submissionError, setSubmissionError] = useState("");
  const deleteSong = () => {
    setLoading(true);
    API.deleteSong(song._id)
      .then(() => {
        handleSongDeleted();
        setIsOpen(false);
      })
      .catch((err) => {
        setLoading(false);
        setSubmissionError(err.data.message);
      });
  };

  return (
    <div className="loud-window fixed">
      <div className="top">
        <div className="title">
          <h1>Delete</h1>
          <img
            onClick={() => {
              setIsOpen(false);
            }}
            src={closeButton}
            alt="close Button"
            className="close"
          />
        </div>
      </div>
      {song && (
        <React.Fragment>
          <div className="error-message">{submissionError}</div>
          <br />
          <div>
            Are you absolutely sure you want to delete {song.title} by{" "}
            {song.producer.nickname}?
          </div>
          <h3 className="error-message">THIS ACTION IS IRREVERSIBLE</h3>
          <br />
          <br />
          <button
            disabled={loading}
            onClick={deleteSong}
            className="window-btn"
          >
            {loading ? "Please Wait..." : "Delete"}
          </button>
        </React.Fragment>
      )}
    </div>
  );
};

export default DeleteSong;
