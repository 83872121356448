import { useEffect, useState } from 'react';
import bgVideo from '../../images/bg2.mp4';
import API from '../../api';
import { useHistory } from 'react-router-dom';

function ConfirmEmailStep({ setFormData, formData, setPage, tempUserId }) {
  const [loading, setLoading] = useState(false);
  const [canContinue, setCanContinue] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (tempUserId) {
      setLoading(true);
      API.getTempUser(tempUserId)
        .then((res) => {
          setFormData((prev) => ({
            ...prev,
            ...res.details,
          }));
          setCanContinue(true);
          setLoading(false);
        })
        .catch((err) => {
          console.error(err.data);
          setLoading(false);
          // Fail quietly
        });
    }
  }, [tempUserId, setFormData]);

  return (
    <>
      <video className="videoTag" autoPlay loop muted playsInline>
        <source src={bgVideo} type="video/mp4" />
      </video>
      <div className="signup__step1">
        <div
          className="signup__step1-wrapper"
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%',
            textAlign: 'center',
            gap: '20px',
          }}
        >
          {canContinue ? (
            <p>Email confirmation successful</p>
          ) : (
            <>
              <p>We have sent an email to {formData.email}</p>
              <p>Please click the link in the email to continue</p>
            </>
          )}
        </div>
      </div>
      <div
        className="signup__actions"
        style={{ zIndex: 2000, padding: '0 20px', width: '350px' }}
      >
        <button
          disabled={loading}
          onClick={() => {
            setPage(1);
            history.push(window.location.pathname);
          }}
          className="white-btn ns"
        >
          Back
        </button>
        {canContinue && (
          <button onClick={() => setPage(3)} className="white-btn">
            {loading ? 'please wait...' : 'Continue'}
          </button>
        )}
      </div>
    </>
  );
}

export default ConfirmEmailStep;
