import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import HomeHeader from './HomeHeader';
import { useSelector } from 'react-redux';
import songIcon from '../../images/play.svg';
import songSelectedIcon from '../../images/pause.svg';
import Fade from 'react-reveal/Fade';
import bgVideo from '../../images/bg2.mp4';

const Home = ({ isSharedPage, sharedSong }) => {
  const { user } = useSelector((state) => state.auth);
  const [homeAudioPlaying, setHomeAudioPlaying] = useState();
  const [sharedSongSignUp, setSharedSongSignUp] = useState();
  const audioRef = useRef();

  const handleAudioPlay = () => {
    if (audioRef.current) {
      if (homeAudioPlaying) {
        audioRef.current.pause();
      } else {
        audioRef.current.play();
      }
    }
  };

  useEffect(() => {
    if (sharedSong) {
      if (sharedSong.type === 'song') {
        // Your code for handling the 'song' type shared song
        setSharedSongSignUp(`/stream/signup/${sharedSong.producer.nickname}`);
      } else {
        // Your code for handling other types of shared songs
        setSharedSongSignUp(`/signup/${sharedSong.producer.nickname}`);
      }
    }
  }, [sharedSong]);
  return (
    <div className="home">
      <video className="videoTag" autoPlay loop muted playsInline>
        <source src={bgVideo} type="video/mp4" />
      </video>
      <div className="home__middle">
        {isSharedPage && sharedSong && (
          <React.Fragment>
            <div className="shared-song" onClick={handleAudioPlay}>
              <div className="icon">
                <img
                  src={homeAudioPlaying ? songSelectedIcon : songIcon}
                  alt="songIcon"
                />
              </div>
              <div className="details">
                <div className="tap">Tap to listen</div>
                <div className="song">
                  <p>{sharedSong ? sharedSong.producer.nickname : ''}</p>
                  <p className="song-title">
                    {sharedSong ? sharedSong.title : ''}
                  </p>
                </div>
              </div>
            </div>
            {sharedSong.type === 'song' ? (
              <Link to={sharedSongSignUp} className="white-btn">
                Support this artist
              </Link>
            ) : (
              <Link to={sharedSongSignUp} className="white-btn">
                Download
              </Link>
            )}
            <Fade bottom cascade>
              {sharedSong.type === 'song' ? (
                <div className="heading-mob">
                  <p>Stream unlimited music from </p>{' '}
                  <p>emerging artists & pay </p>
                  <p>them directly.</p>
                </div>
              ) : (
                <div className="heading-mob">
                  <p>Download an unlimited number of </p>{' '}
                  <p>beats from top producers </p>
                  <p>worldwide.</p>
                </div>
              )}
            </Fade>
          </React.Fragment>
        )}
        {!isSharedPage && (
          <React.Fragment>
            <Fade bottom cascade>
              <div className="heading-wrapper">
                <h1>Welcome! </h1>
              </div>
            </Fade>
            {!user && (
              <Link to="/signup" className="white-btn">
                Join Now
              </Link>
            )}
            {user && user.userType === 'artiste' && (
              <Link to="/sound" className="white-btn">
                Sound App
              </Link>
            )}
            {user && user.userType === 'listener' && (
              <Link to="/stream" className="white-btn">
                Stream App
              </Link>
            )}
            {user && user.userType === 'producer' && (
              <Link to="/producer" className="white-btn">
                My Dashboard
              </Link>
            )}
          </React.Fragment>
        )}
      </div>
      <React.Fragment>
        <HomeHeader
          withAudio={true}
          setHomeAudioPlaying={setHomeAudioPlaying}
          handleAudioPlay={handleAudioPlay}
          sharedSong={sharedSong}
          isSharedPage={isSharedPage}
          ref={audioRef}
          incompleteRegistration={
            user && user.userType === 'artiste' && !user.completedRegistration
          }
        />
      </React.Fragment>
      <div className="home__policy">
        <ul>
          <li>
            <Link to="/support">Support</Link>
          </li>
          <li>
            <Link to="/privacy-policy">Privacy Policy</Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Home;
