import { baseUri } from "./index";
import axios from "axios";

const organization = {
  createOrganizationArtiste: async (data) => {
    try {
      const response = await axios.post(`${baseUri}/organizations/artistes`, {
        ...data,
      });
      return response.data;
    } catch (err) {
      throw err.response;
    }
  },
};

export default organization;
