export const formatSongArray = (songs) => {
  const songsArray = [];
  if (songs.length <= 3) {
    return [songs];
  }
  for (let i = 0; i < songs.length; i += 5) {
    songsArray.push([songs[i], songs[i + 1], songs[i + 2]]);
    songsArray.push([songs[i + 3], songs[i + 4]]);
  }
  return songsArray;
};
