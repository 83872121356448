import { baseUri } from "./index";
import axios from "axios";

const song = {
  getBeatsByProducer: async (producer) => {
    try {
      const response = await axios.get(
        `${baseUri}/songs/producer?producer=${producer}&type=beat`
      );
      return response.data;
    } catch (err) {
      throw err.response;
    }
  },

  getSongsByProducer: async (producer) => {
    try {
      const response = await axios.get(
        `${baseUri}/songs/producer?producer=${producer}&type=song`
      );
      return response.data;
    } catch (err) {
      throw err.response;
    }
  },
  deleteSong: async (songId) => {
    try {
      const response = await axios.delete(`${baseUri}/songs/${songId}`);
      return response.data;
    } catch (err) {
      throw err.response;
    }
  },
};

export default song;
