import React, { useEffect } from "react";
import { motion } from "framer-motion";
import { Player as LottiePlayer } from "@lottiefiles/react-lottie-player";

// Assets
import CloseBtnDark from "../../images/closeDark.svg";
import CloseBtnWhite from "../../images/closeWhite.svg";
import loadingAnim from "../../LottieAnimations/loading-white.json";

const ExportWidget = ({
  songs,
  exportSongs,
  setSelectedBeats,
  selectedBeats,
  setOpen,
  setSavedSongs,
  loading,
  darkMode,
}) => {
  const handleChange = (e, beat) => {
    const { checked } = e.target;
    if (selectedBeats.length === 4 && checked) return;
    if (checked) {
      const beats = selectedBeats.slice();
      beats.push(beat);
      setSelectedBeats(beats);
    } else {
      const beats = selectedBeats.filter((item) => item._id !== beat._id);
      setSelectedBeats(beats);
    }
    setSavedSongs((prevSaved) =>
      prevSaved.map((item) =>
        item._id === beat._id
          ? {
              ...item,
              isSelected: checked,
            }
          : item
      )
    );
  };
  return (
    <div className="export-wrapper">
      <div className="top">
        <div className="title">
          <h1>Download</h1>
          <motion.button
            initial={{ opacity: 0.2 }}
            whileHover={{
              scale: 1.1,
              opacity: 1,
              transition: { duration: 0.2 },
            }}
            whileTap={{ scale: 1 }}
            onTap={setOpen}>
            <img
              src={darkMode ? CloseBtnDark : CloseBtnWhite}
              alt="close Button"
            />
          </motion.button>
        </div>
        <h2>SAVED BEATS</h2>
      </div>
      <div className="saved-songs">
        {loading ? (
          <LottiePlayer autoplay loop src={loadingAnim}></LottiePlayer>
        ) : (
          <React.Fragment>
            {songs &&
              songs.length > 0 &&
              songs.map((song, i) => {
                return (
                  <div key={i} className="beats-container">
                    <input
                      id="checkmark"
                      type="checkbox"
                      onChange={(e) => handleChange(e, song)}
                      checked={song.isSelected ? song.isSelected : false}
                    />

                    <div className="beat-content">
                      <h1>{song.title}</h1>
                      <h2>{song.producer.nickname}</h2>
                    </div>
                  </div>
                );
              })}
          </React.Fragment>
        )}
      </div>
      <div className="bottom">
        <div className="info-count">
          <div className="info">you can only export 4 beats at a time</div>
        </div>
        <button
          disabled={loading || selectedBeats.length < 1}
          className="export-button"
          onClick={() => exportSongs(selectedBeats)}>
          DOWNLOAD {`(${selectedBeats.length})`}
        </button>
      </div>
    </div>
  );
};

export default ExportWidget;
