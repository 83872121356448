import React, { useState } from 'react';
import API from '../../api';

const checkIfNameIsValid = (name, setSubmissionError) => {
  if (!/^[a-z0-9]+$/.test(name) || name.length > 10) {
    setSubmissionError('Invalid name');
    return false;
  }
  return true;
};

const ProducerSIgnupStep2 = ({
  formData,
  setFormData,
  setPage,
  setSubmissionError,
}) => {
  const [loading, setLoading] = useState(false);

  const goToNextPage = () => {
    setSubmissionError('');
    const { nickname } = formData;
    const isNameValid = checkIfNameIsValid(
      nickname,
      setSubmissionError
    );
    if (isNameValid) {
      setLoading(true);
      API.checkIfProducerNicknameExists(nickname)
        .then(() => {
          setPage(2);
        })
        .catch(() => {
          setLoading(false);
          setSubmissionError(
            'Producer name already exists, please try again'
          );
        });
    }
  };

  return (
    <div className="producerSignup__step1">
      <input
        type="text"
        name="nickname"
        className="input-text"
        placeholder="choose your LOUD producer name"
        value={formData.nickname}
        onChange={(e) =>
          setFormData((prev) => ({
            ...prev,
            nickname: e.target.value.toLowerCase().trim(),
          }))
        }
      />
      <p className="info">*Only lowercase alphanumeric characters</p>
      <p className="info">*Maximum 10 characters</p>
      <div className="uploadBeat__actions">
        <button
          disabled={loading}
          className="white-btn"
          onClick={goToNextPage}
        >
          {loading ? 'Please wait...' : 'Next'}
        </button>
      </div>
    </div>
  );
};

export default ProducerSIgnupStep2;
