import React, { useRef, useState } from "react";
import bgVideo from "../images/bg2.mp4";
import visaIcon from "../images/visaIcon.svg";
import chipIcon from "../images/chipicon.png";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import ADDICON from "../images/Group 23.png";
import DeleteCard from "../images/DeleteCard.png";


const PaymentUpdate = () => {
    const history = useHistory();

    const returnSoundApp = () => {
      history.push("/manage-subscription");
    }

    const addCardRoute = () => {
        history.push({
            pathname: '/update-card',
        });
    }

    return (
        <div className="paymentHome">
            <video className="videoTag" autoPlay loop muted playsInline>
                <source src={bgVideo} type="video/mp4" />
            </video>
            <div className="payment-parent">
                <div>
                    <p className="paymentCardInfoText">UPDATE PAYMENT INFO</p>
                </div>

                <div className="display-flex justify-content-space-around cardParent">
                    <div className="signup__step1-card-wrapper">
                    <div className="containesr">
                                <div className="addIconChilds">
                                            <img src={DeleteCard} height="66" width={"66"}/>
                                 </div>
                        <div className="card-group">
                                    <div className="card mydiv">
                                        <div className="logo">
                                            <img src={visaIcon} alt="Visa" />
                                        </div>
                                        <div className="chip">
                                            <img src={chipIcon} alt="chip" />
                                        </div>
                                        <div className="number">**** **** **** 3456</div>
                                        <div className="name">{""}</div>
                                        <div className="from">10/19</div>
                                        <div className="to">06/21</div>
                                        <div className="ring"></div>
                                    </div>                                
                
                        </div>
                    </div>
                    </div>
                    <div  className="signup__step1-card-wrapper">
                    <div className="addCardParent">
                        <div className="card-group">
                            <div className="card">
                                <div className="AddIconParent">
                                    <div onClick={()=> addCardRoute()} className="addIconChild">
                                    <img src={ADDICON} height="66" width={"66"}/>
                                    </div>
                                    <p onClick={()=> addCardRoute()} className="addNewCardText">ADD NEW CARD</p>
                                </div>

                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div onClick={()=> returnSoundApp()} className="returnSoundBtnParent">
                    <p className="returnSoundBtn">RETURN TO MEMBERSHIP INFO</p>
                </div>
            </div>
        </div>
    );
};

export default PaymentUpdate;
