import React, { useState, useRef, useEffect } from "react";
import Song from "./Song";
import Player from "./Player";
import PlayerMobile from "./PlayerMobile";
import { Player as LottiePlayer } from "@lottiefiles/react-lottie-player";
import { InView } from "react-intersection-observer";
import API from "../../api";

// Assets
import loadingAnim from "../../LottieAnimations/loading-white.json";

const StreamPlayer = ({
                         songs,
                         loading,
                         nextPageLoading,
                         loadNextPage,
                         currentSong,
                         setCurrentSong,
                         setOpenReportModal,
                         darkMode,
                         addPlayList
                     }) => {
    let audioPlayedCounter = 1;

    const [isPlaying, setIsPlaying] = useState(false);
    const [audioDuration, setAudioDuration] = useState();
    const [currentAudioTime, setCurrentAudioTime] = useState();
    const audioRef = useRef();

    const playNextSong = () => {
        const currentSongIndex = songs.findIndex(
            (song) => song._id === currentSong._id
        );
        currentSongIndex === songs.length - 1
            ? setCurrentSong(songs[0])
            : setCurrentSong(songs[currentSongIndex + 1]);
        if (isPlaying) {
            setIsPlaying(true);
        } else {
            setIsPlaying(false);
        }
    };
    const playPrevSong = () => {
        const currentSongIndex = songs.findIndex(
            (song) => song._id === currentSong._id
        );
        currentSongIndex === 0
            ? setCurrentSong(songs[0])
            : setCurrentSong(songs[currentSongIndex - 1]);
        if (isPlaying) {
            setIsPlaying(true);
        } else {
            setIsPlaying(false);
        }
    };

    useEffect(() => {
        if (audioRef) {
            if (isPlaying) {
                audioRef.current.play();
            } else {
                audioRef.current.pause();
            }
        }
    }, [isPlaying]);

    useEffect(() => {
        audioPlayedCounter = 1;
    }, [currentSong]);

    const incrementPlayCount = async () => {
        if (audioPlayedCounter === 1) {
            if (Math.floor(currentAudioTime) === Math.floor(audioDuration / 10)) {
                audioPlayedCounter = 2;
                await API.incrementSongPlayCount(currentSong._id);
            }
        }
    };

    return (
        <div className="sound-player-wrapper">
            <audio
                hidden
                autoPlay
                onPlay={() => setIsPlaying(true)}
                onPause={() => setIsPlaying(false)}
                ref={audioRef}
                src={currentSong && currentSong.audio}
                onEnded={playNextSong}
                onDurationChange={(e) => setAudioDuration(e.target.duration)}
                onTimeUpdate={(e) => {
                    setCurrentAudioTime(e.target.currentTime);
                    incrementPlayCount();
                }}></audio>
            <Player
                currentSong={currentSong}
                nextSong={playNextSong}
                prevSong={playPrevSong}
                isPlaying={isPlaying}
                audioRef={audioRef}
                audioDuration={audioDuration}
                setIsPlaying={setIsPlaying}
                currentAudioTime={currentAudioTime}
                setOpenReportModal={setOpenReportModal}
                addPlayList={addPlayList}
                darkMode={darkMode}></Player>
            <PlayerMobile
                currentSong={currentSong}
                nextSong={playNextSong}
                prevSong={playPrevSong}
                isPlaying={isPlaying}
                audioRef={audioRef}
                audioDuration={audioDuration}
                setIsPlaying={setIsPlaying}
                currentAudioTime={currentAudioTime}
                setOpenReportModal={setOpenReportModal}
                addPlayList={addPlayList}
                darkMode={darkMode}></PlayerMobile>
            {loading ? (
                <div className="songs-loading">
                    <LottiePlayer autoplay loop src={loadingAnim}></LottiePlayer>
                </div>
            ) : (
                <div className="sound-beats-list">
                    {songs.map((song, i, arr) => {
                        if (i === arr.length - 1) {
                            return (
                                <InView
                                    as="div"
                                    onChange={(inView) => {
                                        if (inView) {
                                            loadNextPage();
                                        }
                                    }}>
                                    <Song
                                        key={song?.id}
                                        song={song}
                                        setCurrentSong={setCurrentSong}
                                        currentSong={currentSong}
                                        isPlaying={isPlaying}
                                        setIsPlaying={setIsPlaying}
                                        darkMode={darkMode}></Song>
                                </InView>
                            );
                        } else {
                            return (
                                <Song
                                    key={song.id}
                                    song={song}
                                    setCurrentSong={setCurrentSong}
                                    currentSong={currentSong}
                                    isPlaying={isPlaying}
                                    setIsPlaying={setIsPlaying}
                                    darkMode={darkMode}></Song>
                            );
                        }
                    })}
                    {nextPageLoading && (
                        <div className="songs-loading-main">
                            <LottiePlayer autoplay loop src={loadingAnim}></LottiePlayer>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default StreamPlayer;
