import { useEffect, useState } from "react";

const Step2 = ({ setStep }) => {
  const [isConfirmed, setIsConfirmed] = useState(false);

  useEffect(() => {
    const script = document.createElement("script");
    script.innerText = `
      window.addEventListener && window.addEventListener("message", function(event){if (event.origin === "https://loudsignup.youcanbook.me"){document.getElementById("ycbmiframeloudsignup").style.height = event.data + "px";}}, false);
      `;
    document.body.appendChild(script);
    return () => {
      if (script) document.body.removeChild(script);
    };
  }, []);

  const nextPage = () => {
    if (isConfirmed) setStep(3);
  };
  return (
    <div className="step-2">
      <iframe
        src="https://zcal.co/i/caPwCrwD"
        id="ycbmiframeloudsignup"
        style={{
          width: "90%",
          height: "1000px",
          border: "0px",
          backgroundColor: "transparent",
        }}
        frameBorder="0"
        allowtransparency="true"
        title="bookloud"
      ></iframe>
      <div style={{ marginTop: "2rem" }}>
        <input
          value={isConfirmed}
          style={{ marginRight: "1rem" }}
          onChange={(e) => {
            setIsConfirmed(e.target.checked);
          }}
          type="checkbox"
          id="confirmIntake"
        />
        <label htmlFor="confirmIntake">I BOOKED A TIME FOR MY INTAKE</label>
      </div>
      <button
        disabled={!isConfirmed}
        onClick={nextPage}
        className="white-btn black"
      >
        Continue
      </button>
    </div>
  );
};

export default Step2;
