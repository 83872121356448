import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import AdminSignIn from "../components/Signin/AdminSignIn";

import { authSuccess } from "../actions";

const AdminSigninPage = ({ history }) => {
  const { isSignedIn, user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const onSigninSuccess = (user) => {
    dispatch(authSuccess(user));
    history.push("/admin");
  };

  useEffect(() => {
    if (isSignedIn && user && user.userType === "admin") {
      history.push("/admin");
    }
  }, [isSignedIn, history]);

  return <AdminSignIn onSigninSuccess={onSigninSuccess} />;
};

export default AdminSigninPage;
