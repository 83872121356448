import React, { useState } from "react";
import closeButton from "../../images/exitButton.png";
import checkedImg from "../../images/checked.png";
import uncheckedImg from "../../images/unchecked.png";
import API from "../../api";

const CompleteRegistration = ({ setIsOpen }) => {
  const [termsChecked, setTermsChecked] = useState({
    royalties: false,
    distrokid: false,
  });
  const [loading, setLoading] = useState(false);

  const handleCompleteRegistration = () => {
    setLoading(true);
    API.completedRegistration()
      .then(() => window.location.reload())
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  return (
    <div className="complete-registration">
      <div className="top">
        <div className="title">
          <h1>Paper...</h1>
          <img
            onClick={() => setIsOpen(false)}
            src={closeButton}
            alt="close Button"
            className="close"
          />
        </div>
      </div>
      <a
        href="https://storage.googleapis.com/loudbeta/utils/SOUND%20terms%20of%20service.pdf"
        target="_blank"
        className="download"
        rel="noreferrer"
      >
        download terms of service
      </a>
      <p className="info">
        I have read the terms & conditions and I agree to everything including
        the following:
      </p>
      <div className="terms">
        <div
          className="item"
          onClick={() =>
            setTermsChecked((prev) => ({
              ...prev,
              royalties: !termsChecked.royalties,
            }))
          }
        >
          <img
            src={termsChecked.royalties ? checkedImg : uncheckedImg}
            alt="terms"
          />
          <div className="details">
            <h3>I will give the producers 20% Royalty</h3>
            <p>
              I agree to pay the respective LOUD producer 20% of all revenue
              generated from songs I release that use their beat
            </p>
          </div>
        </div>
        <div
          className="item"
          onClick={() =>
            setTermsChecked((prev) => ({
              ...prev,
              distrokid: !termsChecked.distrokid,
            }))
          }
        >
          <img
            src={termsChecked.distrokid ? checkedImg : uncheckedImg}
            alt="terms"
          />
          <div className="details">
            <h3>I will Use distrokid for releases</h3>
            <p>
              I agree to using distokid for releasing songs that use LOUD beats
              and I will use the automatic split feature for paying out
              royalties to LOUD producers
            </p>
          </div>
        </div>
      </div>
      <div className="bottom">
        <div className="progress-bar">
          <span></span>
        </div>
        <button
          disabled={
            !termsChecked.distrokid || !termsChecked.royalties || loading
          }
          className="white-btn"
          onClick={handleCompleteRegistration}
        >
          {loading ? "Please Wait..." : "Complete Registration"}
        </button>
      </div>
    </div>
  );
};

export default CompleteRegistration;
