import React, { useEffect, useState } from "react";
import API from "../api";
import Signup from "../components/signup";

const SignupWithUserReferral = ({ match, history }) => {
  const id = match.params.id;
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    API.validateUserID(id)
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        history.push("/stream/signup");
      });
  }, [id, history]);

  return (
    <React.Fragment>
      {loading ? <div>loading</div> : <Signup refUserId={id} />}
    </React.Fragment>
  );
};

export default SignupWithUserReferral;
