import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import ProducerSignin from "../components/Signin/ProducerSignin";

import { authSuccess } from "../actions";

const ProducerSigninPage = ({ history }) => {
  const { isSignedIn, user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const onSigninSuccess = (user) => {
    dispatch(authSuccess(user));
    history.push("/producer");
  };

  useEffect(() => {
    if (isSignedIn && user && user.userType === "producer") {
      history.push("/producer");
    }
  }, [isSignedIn, history]);

  return <ProducerSignin onSigninSuccess={onSigninSuccess} />;
};

export default ProducerSigninPage;
