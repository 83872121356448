import React, { useEffect } from 'react';
import Signup from '../components/signup';
import { useLocation } from 'react-router-dom';
import { authSuccess } from '../actions';
import { useSelector, useDispatch } from 'react-redux';

const SignupPage = ({ history }) => {
  const location = useLocation();
  const { isSignedIn } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  let { from } = location.state || { from: { pathname: '/' } };

  const onSignupSuccess = (user) => {
    // history.replace(from);
    dispatch(authSuccess(user));
  };
  useEffect(() => {
    if (isSignedIn) {
      history.replace(from);
    }
  }, [isSignedIn, from, history]);

  return (
    <Signup onSignupSuccess={onSignupSuccess} history={history} plan={'sound'}/>
  );
};

export default SignupPage;
