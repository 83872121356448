import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import { authError } from "../../actions";

// Assets
import SunIcon from "../../images/sun.svg";
import MoonIcon from "../../images/moon.svg";
import NotifcationIconLight from "../../images/notification-light.svg";
import NotifcationIconDark from "../../images/notification-dark.svg";

const MobileNav = ({ setOpenExportModal, darkMode, setDarkMode }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

  const logUserOut = () => {
      let userType = user.userType;
      dispatch(authError());
      if (userType === 'listener') {
          history.push("/stream/signin");
      } else {
          history.push("/");
      }
  };
  const [isNavActive, setIsNavActive] = useState(false);
  return (
    <div className="sound-nav">
      <h1>LOUD</h1>
      <div className="menu-wrapper">
        {user && !user.completedRegistration && (
          <motion.button
            initial={{ opacity: 0.8 }}
            whileHover={{
              scale: 1.1,
              opacity: 1,
              transition: { duration: 0.2 },
            }}
            whileTap={{ scale: 1, opacity: 1 }}>
            <Link to="/manage-subscription">
              <img
                src={darkMode ? NotifcationIconLight : NotifcationIconDark}
                alt="icon"></img>
            </Link>
          </motion.button>
        )}

        <motion.button
          initial={{ opacity: 0.2 }}
          whileHover={{
            scale: 1.1,
            opacity: 1,
            transition: { duration: 0.2 },
          }}
          whileTap={{ scale: 1, opacity: 1 }}
          onTap={() => setDarkMode(!darkMode)}>
          <img src={darkMode ? SunIcon : MoonIcon} alt="icon"></img>
        </motion.button>
        <div
          className={isNavActive ? "menu-icon active" : "menu-icon"}
          onClick={() => setIsNavActive(!isNavActive)}>
          <input className="menu-icon__cheeckbox" type="checkbox" />
          <div>
            <span></span>
            <span></span>
          </div>
        </div>
      </div>
      <AnimatePresence>
        {isNavActive && (
          <motion.nav
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 20 }}
            className="nav-content">
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/manage-subscription">Membership Info</Link>
              </li>
              {/*<li>*/}
              {/*  <Link to="/sound-legacy">Stream App Legacy</Link>*/}
              {/*</li>*/}
              {/*<li>*/}
              {/*  <a*/}
              {/*    href="##"*/}
              {/*    className="main-button"*/}
              {/*    onClick={() => setOpenExportModal(true)}>*/}
              {/*    Download*/}
              {/*  </a>*/}
              {/*</li>*/}
              <li>
                <a href="##" className="main-button" onClick={logUserOut}>
                  Log Out
                </a>
              </li>
            </ul>
          </motion.nav>
        )}
      </AnimatePresence>
    </div>
  );
};

export default MobileNav;
