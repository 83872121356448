const SupportPage = () => (
  <div className="main2 policy">
    <div className="main--text">
      <div className="Header">
        <h2> LOUD Music Technology Co.</h2>
      </div>
    </div>

    <div className="main--text">
      ________________________________________
      <br />
    </div>
    <div className="main--text">
      <h3>Support</h3>
      <br />
    </div>

    <div className="policy">
      {" "}
      <br />
      If you have any issues or concerns please contact us{" "}
      <a
        style={{ color: "#EC3432" }}
        href={
          "mailto:support@loud.army?subject=Support Request&body=Name:%0D%0AEmail Associated with the Account:%0D%0ADescription of issue:"
        }
      >
        support@loud.army
      </a>
      <br />
    </div>
  </div>
);

export default SupportPage;
