import React, { useEffect, useState } from 'react';
import API from '../../api';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import setAxiosAuthToken from '../../utils/setAxiosAuthToken';
import { copyTextToClipBoard } from '../../utils/helpers';
import shareIcon from '../../images/shareIcon.png';
import ProducerLayout from './ProducerLayout';

const ProducerBeats = () => {
  const [beats, setBeats] = useState();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    const token = localStorage.getItem('auth-token');
    setAxiosAuthToken(token);
    API.getProducerSongs()
      .then((res) => {
        setBeats(res.songs);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const shareBeat = (id) => {
    copyTextToClipBoard(`https://app.loud.army/share/${id}`);
    toast('Link Copied');
  };

  return (
    <ProducerLayout>
      <div className="producerBeats">
        <div className="producerBeats__title">My Beats</div>

        {!loading && beats && beats.length > 0 && (
          <React.Fragment>
            <Link to="/new-song" className="white-btn">
              Upload New Beat
            </Link>
            <div className="producerBeats__beats">
              <div className="beats-wrapper">
                <div className="beats-headings">
                  <div>Name</div>
                  <div>plays</div>
                  <div>saves</div>
                  <div>downloads</div>
                  <div>share</div>
                </div>
                {beats.map((item) => (
                  <div key={item._id} className="beats-details">
                    <div>{item.title}</div>
                    <div>{item.plays}</div>
                    <div>{item.saves}</div>
                    <div>{item.downloads}</div>
                    <div>
                      <img
                        src={shareIcon}
                        alt="share"
                        onClick={() => shareBeat(item._id)}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </React.Fragment>
        )}
        {!loading && beats && !beats.length && (
          <React.Fragment>
            <div className="beats-empty">
              Hmm! You have not uploaded any beats yet <br />
              Upload your first beat by clicking the upload button below
            </div>
            <Link to="/new-song" className="white-btn">
              Upload Beat
            </Link>
          </React.Fragment>
        )}
      </div>
    </ProducerLayout>
  );
};

export default ProducerBeats;
