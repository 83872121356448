import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import API from "../../api";
import HomeHeader from "../Home/HomeHeader";

const ProducerLayout = ({ children }) => {
  const { user } = useSelector((state) => state.auth);
  const history = useHistory();
  useEffect(() => {
    if (!user || (user && user.userType !== "producer")) {
      history.push("/producer/signin");
    }
  });
  return (
    <div className="producerProfile">
      {user && user.emailConfirmed && (
        <React.Fragment>
          <HomeHeader isWhiteBackground={true} />
          {children}
        </React.Fragment>
      )}
      {user && !user.emailConfirmed && <ConfirmEmail />}
    </div>
  );
};

const ConfirmEmail = () => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const sendEmailConfirmation = () => {
    setLoading(true);
    API.sendProducerEmailConfirmation()
      .then((data) => {
        console.log(data);
        setLoading(false);
        setSuccess(true);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  return (
    <div className="confirm-email">
      {success ? (
        <React.Fragment>
          <p>Email Sent!</p>
          <p>Please check your email to confirm your email address</p>
          <p>
            If you did not receive an email after 15 minutes click the resend
            button below to send email
          </p>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <p>
            You need to confirm your email before you can enjoy all of LOUD's
            functionalities, We sent you an email already
          </p>
          <p>Please check your email to confirm your email address</p>
          <p>
            You have not received an email? please click the resend button below
            to resend email.
          </p>
        </React.Fragment>
      )}

      <button
        disabled={loading}
        className="white-btn"
        onClick={sendEmailConfirmation}
      >
        {loading ? "Please wait..." : "resend"}
      </button>
    </div>
  );
};

export default ProducerLayout;
