import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import API from "../api";
import Home from "../components/Home";

const OneBeatShared = ({ match, history }) => {
  const { songId } = match.params;
  const { user, loading } = useSelector((state) => state.auth);
  const [song, setSong] = useState({ shared: true, song: "" });

  useEffect(() => {
    localStorage.setItem("sharedSongId", songId);
    if (user && user.userType === "artiste") {
      history.push("/sound");
    } else if(user && user.userType === "listener"){
      history.push("/stream");
    } else if ((!user && !loading) || (user && (user.userType !== "artiste" || user.userType !== "listener"))) {
      API.getSong(songId)
          .then((res) => {
            setSong({ shared: true, song: res.song });
          })
          .catch((err) => console.log(err));

    }
  }, [user, songId, loading]);

  return <Home isSharedPage={song.shared} sharedSong={song.song} />;
};

export default OneBeatShared;
