import React, { useState } from "react";
import ProducerLayout from "./ProducerLayout";
import { Link, useHistory } from "react-router-dom";
import loudFace from "../../images/loudfaceBlack.png";
import protectImg from "../../images/protect.png";
import API from "../../api";
import successImg from "../../images/exportSuccess.png";

const checkIfFormIsValid = (data, setSubmissionError, ownerShipChecked) => {
  const { title, audio, coverArt } = data;

  if (!/^[a-zA-Z0-9\d\s]+$/.test(title) || title.length > 15) {
    setSubmissionError("Invalid beat name");
    return false;
  }

  if (!audio) {
    setSubmissionError("Please select an audio file");
    return false;
  }

  if (audio && !audio.type.startsWith("audio/")) {
    setSubmissionError("Invalid audio file");
    return false;
  }
  if (audio && !audio.size > 52428800) {
    setSubmissionError("Audio file too large, maximum size allowed is 50mb");
    return false;
  }

  if (coverArt && !coverArt.type.startsWith("image/")) {
    setSubmissionError("Invalid cover art, only images allowed");
    return false;
  }

  if (!ownerShipChecked) {
    setSubmissionError("Please check that you are the owner of the Song");
    return false;
  }

  return true;
};

const UploadBeat = () => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [submissionError, setSubmissionError] = useState("");
  const [formData, setFormData] = useState({
    title: "",
    audio: "",
    coverArt: "",
  });
  const [ownerShipChecked, setOwnerShipChecked] = useState(false);
  const history = useHistory();

  const handleChange = (e) => {
    const { name } = e.target;
    const value =
      name === "audio" || name === "coverArt"
        ? e.target.files[0]
        : e.target.value;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const resetPage = () => {
    setSuccess(false);
    setLoading(false);
    setFormData({
      title: "",
      audio: "",
      coverArt: "",
    });
  };

  const handleBeatUpload = () => {
    setSubmissionError("");
    const isFormValid = checkIfFormIsValid(
      formData,
      setSubmissionError,
      ownerShipChecked
    );

    if (isFormValid) {
      const data = new FormData();
      data.append("title", formData.title);
      data.append("audio", formData.audio);
      data.append("type", "song");
      if (formData.coverArt) {
        data.append("coverArt", formData.coverArt);
      }
      setLoading(true);
      API.uploadSong(data)
        .then(() => {
          setSuccess(true);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    } else {
      window.scrollTo(0, 0);
    }
  };

  return (
    <ProducerLayout>
      <div className="uploadBeat">
        {!success && (
          <React.Fragment>
            <div className="uploadBeat__title">
              <img src={loudFace} alt="Loud face" /> Upload Song
            </div>
            <div className="error-message">{submissionError}</div>
            <div className="uploadBeat__form">
              <input
                type="text"
                placeholder="Enter Song Name"
                className="input-text"
                name="title"
                value={formData.title}
                onChange={handleChange}
              />
              <p className="info">*Only alphanumeric characters</p>
              <p className="info">*Maximum 15 characters</p>
              <label className="input-label" htmlFor="audio">
                Select your song
              </label>
              <input
                type="file"
                name="audio"
                // accept="audio/*"
                id="audio"
                onChange={handleChange}
              />
              <label htmlFor="coverArt" className="input-label">
                Select your cover art (optional)
              </label>
              <input
                type="file"
                name="coverArt"
                accept="image/*"
                id="coverArt"
                onChange={handleChange}
              />
            </div>
            <div className="uploadBeat__ownership">
              <input
                type="checkbox"
                id="ownership"
                value={ownerShipChecked}
                onChange={(e) => setOwnerShipChecked(e.target.checked)}
              />
              <label htmlFor="ownership">
                I own this Song or have the permission from the owner to upload
                it
              </label>
            </div>
            {/* <div className="uploadBeat__protect">
              <img src={protectImg} alt="protection" />
              <h3>We protect you</h3>
            </div> */}
            {/* <p className="uploadBeat__vow">
              When you upload a beat with us, we vow to protect it
            </p> */}
            <div className="double-input">
              <button
                onClick={() => history.goBack()}
                disabled={loading}
                className="white-btn"
              >
                Back
              </button>
              <button
                disabled={loading}
                onClick={handleBeatUpload}
                className="white-btn"
              >
                {loading ? "Uploading..." : "Upload"}
              </button>
            </div>
          </React.Fragment>
        )}
        {success && (
          <div className="uploadBeat__success">
            <img src={successImg} alt="successful" />
            <div className="double-input">
              <button onClick={resetPage} className="white-btn red">
                New Song
              </button>
              <Link to="/producer" className="white-btn red">
                Dashboard
              </Link>
            </div>
          </div>
        )}
      </div>
    </ProducerLayout>
  );
};

export default UploadBeat;
