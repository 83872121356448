import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import MainApp from "../components/Sound/MainApp";
import { canUseSoundApp } from "../utils/userRIghts";
import SharedSongPage from "./SharedSongPage";

const SoundRedesignPage = ({ history }) => {
  const [darkMode, setDarkMode] = useState(true);
  const { user, loading } = useSelector((state) => state.auth);

  useEffect(() => {
    if (user && !canUseSoundApp(user)) history.push("/manage-subscription");
  }, [user, history]);

  if (!loading && !user) {
    return (
      <SharedSongPage history={history} pageType={'sound'}/>
    )
  }
  return (
    <div className={`sound-container`} data-theme={darkMode ? "dark" : "light"}>
      <MainApp darkMode={darkMode} setDarkMode={setDarkMode}></MainApp>
    </div>
  );
};

export default SoundRedesignPage;
