import { baseUri } from "./index";
import axios from "axios";

export const cancelSubscription = async () => {
  try {
    let userType = localStorage.getItem('userType');
    const response = await axios.delete(`${baseUri}/subscriptions?userType=${userType}`);
    return response.data;
  } catch (err) {
    throw err.response;
  }
};

export const reactivateSoundSubscription = async () => {
  try {
    let userType = localStorage.getItem('userType');
    const response = await axios.post(`${baseUri}/subscriptions/reactivate?userType=${userType}`);
    return response.data;
  } catch (err) {
    throw err.response;
  }
};

export const createProducerChallengeSubscription = async (data) => {
  try {
    const response = await axios.post(
      `${baseUri}/subscriptions/producer-challenge`,
      { ...data }
    );
    return response.data;
  } catch (err) {
    throw err.response;
  }
};

export const createPaymentIntent = async (data) => {
  try {
    const response = await axios.post(
      `${baseUri}/subscriptions/payment-intent`,
      { ...data }
    );
    return response.data;
  } catch (err) {
    throw err.response;
  }
};
