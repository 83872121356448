import React, { useEffect, useState } from "react";
import API from "../../api";
import AdminLayout from "./AdminLayout";
import CreatePartner from "./CreatePartner";

function AdminPartners() {
  const [partners, setPartners] = React.useState([]);
  const [createPartnerOpen, setCreatePartnerOpen] = useState(false);

  useEffect(() => {
    API.getPartners().then((res) => {
      setPartners(res.partners);
    });
  }, []);

  const handlePartnerCreated = (partner) => {
    setPartners([partner, ...partners]);
  };
  return (
    <AdminLayout activeNav="partner">
      {createPartnerOpen && (
        <CreatePartner
          setIsOpen={setCreatePartnerOpen}
          addPartner={handlePartnerCreated}
        />
      )}
      <div>
        <h1>Partners</h1>
        <button
          onClick={() => setCreatePartnerOpen(true)}
          className="white-btn"
          type="button"
        >
          create
        </button>
        <div className="d-flex w-100 mt-2 mb-2">
          <div className="w-10">Name</div>
          <div className="w-10">Active</div>
          <div className="w-10">Trial days</div>
          <div className="w-10">Signups</div>
          <div className="w-10">Max Signups</div>
        </div>
        {partners.length > 0 &&
          partners.map((partner) => (
            <div key={partner._id} className="d-flex w-100 mt-1 mb-1">
              <div className="w-10">{partner.name}</div>
              <div className="w-10">{partner.isActive ? "Yes" : "No"}</div>
              <div className="w-10">{partner.trialDays}</div>
              <div className="w-10">{partner.signups}</div>
              <div className="w-10">{partner.maxSignups}</div>
            </div>
          ))}
      </div>
    </AdminLayout>
  );
}

export default AdminPartners;
