import React, { useRef, useEffect, useState } from "react";
import { motion } from "framer-motion";


const sideScroll = (HTMLDivElement, speed, distance, step) => {
  let scrollAmount = 0;
  const slideTimer = setInterval(() => {
    HTMLDivElement.scrollLeft += step;
    scrollAmount += Math.abs(step);
    if (scrollAmount >= distance) {
      clearInterval(slideTimer);
    }
  }, speed);
};
const SoundCard = ({
  playlistTabs,
  removeFromTabList,
  isScroll,
  setActiveFilterTabObject,
  defaultTabs,
  activeFilterTabObject
 }) => {
  const contentWrapper = useRef(null);
  const [urlParam, seturlParam] = useState("")


  useEffect(() => {
    if (isScroll) {
      sideScroll(contentWrapper.current, 10, 1000, 10);
    }
  }, [isScroll])

  useEffect(() => {
    let path = window?.location.pathname.split('/')
    seturlParam(path[path.length - 1]
    )
  }, [])


  useEffect(() => {
    if (urlParam && playlistTabs?.length !== 0) {
      let obj = playlistTabs.find((item) => item?.nickname === urlParam)
      if (obj) {
        setActiveFilterTabObject(obj)
        seturlParam("")
        sideScroll(contentWrapper.current, 10, 1000, 10);
      } else {
        setActiveFilterTabObject(defaultTabs[0])
        seturlParam("")
      }
    }
  }, [urlParam, playlistTabs])




  const MAXIMUM_PLAYLIST_LENGTH = playlistTabs?.length + 3 > 6 ? playlistTabs?.length + 3 : 6
  return (
    <div ref={contentWrapper} className="card-wrapper" style={{ gridTemplateColumns: `repeat(${MAXIMUM_PLAYLIST_LENGTH}, 1fr)` }}>
     {defaultTabs.map(item =>(
      <motion.button
        initial={{ scale: 1 }}
        animate={{ opacity: activeFilterTabObject?.nickname === item.nickname ? 1 : 0.5 }}
        whileHover={{
          scale: 0.96,
          transition: { duration: 0.2 },
        }}
        onTap={() => setActiveFilterTabObject(item)}
        className="card-btn">
        <img src={item.tabImage}></img>
        <h1 className="text-white">{item.title}</h1>
      </motion.button>
     ))}

      {playlistTabs.map(response => {
        return (
          <motion.button
            initial={{ scale: 1 }}
            animate={{ opacity: activeFilterTabObject?.nickname === response.nickname ? 1 : 0.5 }}
            whileHover={{
              scale: 0.96,
              transition: { duration: 0.2 },
            }}
            whileTap={{ scale: 0.97 }}
            onTap={() => {
              setActiveFilterTabObject(response)
            }}
            className="card-btn bgAndBorderColor">
            <p onClick={() => removeFromTabList(response.id)} className="cancelIcon">x</p>
            {/* <img src={response.coverArt}></img> */}
            <h1 className="text-black">{response.nickname}</h1>

          </motion.button>
        )
      })}
    </div>
  );
};

export default SoundCard;
