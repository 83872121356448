import React, { useState, useRef, useEffect } from "react";
import { Canvas, useFrame } from "react-three-fiber";
import { perlin3 } from "./shaders/noise";
import { useSpring, animated } from "@react-spring/three";

const BlobContainer = ({ isPlaying }) => {
  const [xVal, setXVal] = useState(1);
  const [yVal, setYVal] = useState(1);
  const { scale } = useSpring({ scale: isPlaying ? 1.2 : 1 });

  useEffect(() => {
    if (isPlaying) {
      setXVal(5);
      setYVal(5);
    } else {
      setXVal(1);
      setYVal(1);
    }
  }, [isPlaying]);

  const Blob = () => {
    const sphereGeometryRef = useRef();
    const inputScale = 2;
    const mesh = useRef();

    useFrame(({ clock }) => {
      const sphereGeometry = sphereGeometryRef.current;
      const { vertices } = sphereGeometry;
      const time = clock.getElapsedTime();

      for (
        let i = 0, verticesLength = vertices.length;
        i < verticesLength;
        i++
      ) {
        const p = vertices[i];
        p.normalize().multiplyScalar(
          2 +
            0.15 *
              perlin3(
                p.x * inputScale + time * xVal,
                p.y * inputScale - time * yVal,
                p.z * inputScale
              )
        );
      }

      sphereGeometry.verticesNeedUpdate = true;
      sphereGeometry.computeVertexNormals();
      sphereGeometry.normalsNeedUpdate = true;
    });
    return (
      <>
        <animated.mesh scale={scale} position={[0, 0, 0]} ref={mesh}>
          <animated.sphereGeometry
            attach="geometry"
            args={[1.5, 32, 32]}
            ref={sphereGeometryRef}
          />
          <meshNormalMaterial attach="material" />
        </animated.mesh>
      </>
    );
  };

  return (
    <Canvas
      style={{
        position: "fixed",
        height: "100vh",
        width: "100vw",
        top: "0",
        left: "0",
        zIndex: "-100",
        background:
          "linear-gradient(to right,#eea2a2 0%,#bbc1bf 19%,#57c6e1 42%,#b49fda 79%,#7ac5d8 100%)",
      }}
    >
      <Blob />
    </Canvas>
  );
};

export default BlobContainer;
