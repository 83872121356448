import React, { useRef, useState } from "react";
import bgVideo from "../../images/bg2.mp4";
import visaIcon from "../../images/visaIcon.svg";
import chipIcon from "../../images/chipicon.png";
import { useHistory } from "react-router";
import {
    useStripe,
    useElements,
    CardNumberElement,
    CardCvcElement,
    CardExpiryElement,
    Elements,
} from "@stripe/react-stripe-js";
import Tilt from "react-parallax-tilt";
import { useOptions } from "../../utils/helpers";
import { stripeOptions, stripePromise } from "../../services/stripe";


const UpdateCardPaymentForm = () => {
    const history = useHistory()
    const options = useOptions();

    const [[manualTiltAngleX, manualTiltAngleY], setManualTiltAngle] = useState([
        0, 0,
    ]);

    return (
        <Elements stripe={stripePromise} options={stripeOptions}>
            <div className="signup">
                <React.Fragment>
                    <video className="videoTag" autoPlay loop muted playsInline>
                        <source src={bgVideo} type="video/mp4" />
                    </video>
                    <div className="signup__step1">
                        <div className="signup__step1-wrapper">

                            <h1>PAYMENT DETAILS</h1>

                            <input
                                className="input-text"
                                type="text"
                                // value={formData.cardName}
                                // onChange={handleChange}
                                name="cardName"
                                placeholder="NAME ON CARD"
                            />
                            <CardNumberElement
                                options={options}
                                className="input-text"
                                onChange={(e) => setManualTiltAngle([-22, 0])}
                            />
                            <div className="card-expiry-cvv double-input">
                                <CardExpiryElement
                                    options={options}
                                    className="input-text"
                                    onChange={(event) => {
                                        setManualTiltAngle([0, 30]);
                                    }}
                                />
                                <CardCvcElement
                                    options={options}
                                    className="input-text"
                                    onChange={(event) => {
                                        //   handleFormComplete('cvv', event.complete);
                                    }}
                                />
                            </div>

                            <div className="signup__actions">
                                <button
                                    onClick={() => history.goBack()}
                                    className="white-btn ns"
                                    style={{ color: "white" }}
                                >
                                    Back
                                </button>
                                <button
                                    disabled={false}
                                    //   onClick={goToNextPage}
                                    className="white-btn"
                                    onMouseEnter={() => setManualTiltAngle([0, 0])}
                                >
                                    {"Continue"}
                                </button>
                            </div>

                        </div>
                        <div className="signup__step1-card-wrapper">
                            <Tilt
                                tiltAngleXManual={manualTiltAngleX}
                                tiltAngleYManual={manualTiltAngleY}
                                onLeave={() => setManualTiltAngle([0, 0])}
                            >
                                <div className="card-group">
                                    <div className="card">
                                        <div className="logo">
                                            <img src={visaIcon} alt="Visa" />
                                        </div>
                                        <div className="chip">
                                            <img src={chipIcon} alt="chip" />
                                        </div>
                                        <div className="number">1234 5678 9012 3456</div>
                                        <div className="name">{"ALI"}</div>
                                        <div className="from">10/19</div>
                                        <div className="to">06/21</div>
                                        <div className="ring"></div>
                                    </div>
                                </div>
                            </Tilt>
                        </div>
                    </div>
                </React.Fragment>
            </div>
        </Elements>

    );
};

export default UpdateCardPaymentForm;
