import { useQuery } from "react-query";
import API from "../../api";
import AdminLayout from "./AdminLayout";

function AdminSummary() {
  const { isLoading, data: stats } = useQuery(["admin", "summary"], () =>
    API.adminGetSummary().then((res) => res)
  );

  return (
    <AdminLayout activeNav="summary">
      {isLoading && <div className="loading">Loading...</div>}
      {stats && (
        <div className="summary">
          <div className="summary__item">
            <h3>Producers</h3>
            <h4>{stats.producers}</h4>
          </div>
          <div className="summary__item">
            <h3>Artistes</h3>
            <h4>{stats.artistes}</h4>
          </div>
          <div className="summary__item">
            <h3>Beats</h3>
            <h4>{stats.beats}</h4>
          </div>
          <div className="summary__item">
            <h3>Songs</h3>
            <h4>{stats.songs}</h4>
          </div>
          <div className="summary__item">
            <h3>Total Users</h3>
            <h4>{stats.artistes + stats.producers}</h4>
          </div>
        </div>
      )}
    </AdminLayout>
  );
}

export default AdminSummary;
