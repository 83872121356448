import React, { useState } from "react";
import API from "../../api";
import { Link, useHistory } from "react-router-dom";

const checkIfDataIsValid = (data, setSubmissionError) => {
  const { password, firstname, lastname, email, confirmPassword } = data;
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (!password || !firstname || !lastname || !email) {
    setSubmissionError("All fields are required");
    return false;
  }
  if (email && !re.test(email.toLowerCase())) {
    setSubmissionError("Invalid email");
    return false;
  }
  if (password && (password.length < 6 || password.length > 20)) {
    setSubmissionError("Password should be between 6 and 20 characters");
    return false;
  }
  if (password && password !== confirmPassword) {
    setSubmissionError("Passwords are not the same");
    return false;
  }
  return true;
};

const AdminSignup = () => {
  const [loading, setLoading] = useState(false);
  const [submissionError, setSubmissionError] = useState("");
  const history = useHistory();
  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    password: "",
    confirmPassword: "",
  });
  const [page, setPage] = useState(1);

  const signup = () => {
    setSubmissionError("");
    const isFormValid = checkIfDataIsValid(formData, setSubmissionError);
    if (isFormValid) {
      setLoading(true);
      API.adminSignup({
        ...formData,
        email: formData.email.toLowerCase().trim(),
      })
        .then(() => {
          setPage(2);
        })
        .catch((err) => {
          setLoading(false);
          setSubmissionError(err.data.message);
        });
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <div className="producerSignup">
      {page === 1 && (
        <React.Fragment>
          <div className="producerSignup__title">Create Admin Account</div>
          <div className="error-message">{submissionError}</div>
          <div className="producerSignup__step2">
            <div className="double-input">
              <input
                type="text"
                name="firstname"
                className="input-text"
                placeholder="Firstname"
                value={formData.firstname}
                onChange={handleChange}
              />
              <input
                type="text"
                name="lastname"
                className="input-text"
                placeholder="Lastname"
                value={formData.lastname}
                onChange={handleChange}
              />
            </div>
            <input
              type="text"
              name="email"
              className="input-text"
              placeholder="Email"
              value={formData.email}
              onChange={handleChange}
            />
            <input
              type="password"
              name="password"
              className="input-text"
              placeholder="Password"
              value={formData.password}
              onChange={handleChange}
            />
            <input
              type="password"
              name="confirmPassword"
              className="input-text"
              placeholder="Password Again"
              value={formData.confirmPassword}
              onChange={handleChange}
            />
            <div className="double-input">
              <button
                disabled={loading}
                className="white-btn"
                onClick={() => history.push("/")}
              >
                Back
              </button>
              <button disabled={loading} className="white-btn" onClick={signup}>
                {loading ? "Please Wait..." : "Sign Up"}
              </button>
            </div>
          </div>
        </React.Fragment>
      )}

      {page === 2 && (
        <div className="producerSignup__success">
          <div className="title">Congratulations</div>
          <p>Your admin account has been created successfully</p>
          <p>Please click the button below to log in</p>
          <Link to="/admin/signin" disabled={loading} className="white-btn">
            Log In
          </Link>
        </div>
      )}
    </div>
  );
};

export default AdminSignup;
