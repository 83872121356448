import React, { useState, useRef, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import handleIntesection from '../../utils/intersectionObserver';
import { toast } from 'react-toastify';
import { formatSongArray } from './soundAppUtils';
import API from '../../api';
import SongsGrid from './SongsGrid';
import Song from './Song';
import AlbumArt from '../../images/albumArt.png';
import playButton from '../../images/playButton.png';
import saveIcon from '../../images/saveIcon.png';
import savedIcon from '../../images/savedIcon.png';
import exportIcon from '../../images/exportIcon.png';
import shareIcon from '../../images/shareIcon.png';
import ExportModal from './ExportModal';
import SoundAppHeader from './SoundAppHeader';
import Modal from '../Modal';
import _ from 'lodash';
import ReportSong from './ReportSong';
import { copyTextToClipBoard } from '../../utils/helpers';
let audioPlayedCounter = 1;

function SoundApp() {
  const [songs, setSongs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentSong, setCurrentSong] = useState();
  const audioRef = useRef();
  const [activeFilterTab, setActiveFilterTab] = useState('popular');
  const [audioPlaying, setAudioPlaying] = useState(false);
  const [currentAudioTime, setCurrentAudioTime] = useState();
  const [audioDuration, setAudioDuration] = useState();
  const [showSideBeatDetails, setShowSideBeatDetails] = useState(false);
  const [showNav, setShowNav] = useState(false);
  const [songsTrayHeight, setSongsTrayHeight] = useState();
  const [lastSongGridRef, setLastSongGridRef] = useState();
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [nextPageLoading, setNextPageLoading] = useState(false);
  const [isSaved, setIsSaved] = useState();
  const [showExportModal, setShowExportModal] = useState(false);
  const [showReportModal, setShowReportModal] = useState(false);
  const songTrayHeadingRef = useRef(null);
  const progressBarRef = useRef(null);
  const [sharedSong, setSharedSong] = useState();
  const user = useSelector((state) => state.auth.user);

  //Handle audio time seek when user click on part of the the progress bar
  const handleAudioTimeSeek = (e) => {
    let percentagePosition =
      (e.clientX - progressBarRef.current.getBoundingClientRect().left) /
      progressBarRef.current.offsetWidth;
    audioRef.current.currentTime = percentagePosition * audioDuration;
    if (audioRef.current.paused) {
      audioRef.current.pause();
    }
  };

  const handleSaveSong = async () => {
    if (currentSong && !isSaved) {
      await API.saveSong(currentSong._id, 'save');
      setIsSaved(true);
    } else if (currentSong && isSaved) {
      await API.saveSong(currentSong._id, 'remove');
      setIsSaved(false);
    }
  };

  const checkIfSongIsSaved = (song) => {
    API.checkIfSongIsSaved(song._id, user.userType)
      .then(() => {
        setIsSaved(true);
      })
      .catch(() => setIsSaved(false));
  };

  const shareSong = () => {
    copyTextToClipBoard(`https://app.loud.army/share/${currentSong._id}`);
    toast('Link Copied');
  };

  const handleSelectSong = (song) => {
    setCurrentSong(song);
    checkIfSongIsSaved(song);
    audioPlayedCounter = 1;
    if (audioRef.current.paused) {
      audioRef.current.play();
    }
  };

  const handleScroll = (e) => {
    if (window.pageYOffset > 45) {
      setShowSideBeatDetails(true);
    } else {
      setShowSideBeatDetails(false);
    }
  };

  const getSharedSong = async () => {
    const sharedSongId = localStorage.getItem('sharedSongId');
    if (!sharedSongId) return;
    API.getSong(sharedSongId)
      .then((res) => {
        setSongs((prev) => _.uniqBy([res.song, ...prev], '_id'));
        setCurrentSong(res.song);
        setSharedSong(res.song);
        checkIfSongIsSaved(res.song);
        localStorage.removeItem('sharedSongId');
      })
      .catch(console.log);
  };

  useEffect(() => {
    setLoading(true);
    if (user) {
      const loadSongs = async () => {
        try {
          const data = await API.fetchSongs(1, activeFilterTab);
          if (
            sharedSong &&
            (activeFilterTab === 'recent' || activeFilterTab === 'popular')
          ) {
            setSongs(
              _.uniqBy([sharedSong, ...data.filter((song) => !!song)], '_id')
            );
          } else {
            setSongs(data.filter((song) => !!song));
          }
          setPage(1);
          setLoading(false);
          data.length < 15 ? setHasMore(false) : setHasMore(true);
          await getSharedSong();
        } catch (error) {
          setLoading(false);
        }
      };
      loadSongs();
    }
  }, [activeFilterTab, user]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const loadNextPage = useCallback(async () => {
    if (!hasMore) return;
    if (hasMore) {
      setNextPageLoading(true);
      const data = await API.fetchSongs(page + 1, activeFilterTab);
      if (data.length > 0) {
        setSongs((prev) => _.uniqBy([...prev, ...data], '_id'));
        setNextPageLoading(false);
        if (data.length < 15) {
          setHasMore(false);
        } else {
          setPage((prev) => prev + 1);
        }
      } else {
        setNextPageLoading(false);
      }
    }
  }, [hasMore, activeFilterTab, songs, page]);

  const incrementPlayCount = async () => {
    if (audioPlayedCounter === 1) {
      if (Math.floor(currentAudioTime) === Math.floor(audioDuration / 10)) {
        audioPlayedCounter = 2;
        await API.incrementSongPlayCount(currentSong._id);
      }
    }
  };

  useEffect(() => {
    if (lastSongGridRef) {
      handleIntesection(lastSongGridRef, loadNextPage);
    }
  }, [lastSongGridRef, loadNextPage]);

  const handleResize = () => {
    var height = Math.max(
      document.documentElement.clientHeight,
      window.innerHeight || 0
    );
    setSongsTrayHeight(height - 180);
  };

  //This effect is purely for songtray scroll styling and has nothing to do with fuctionality.
  useEffect(() => {
    var height = Math.max(
      document.documentElement.clientHeight,
      window.innerHeight || 0
    );
    setSongsTrayHeight(height - 180);
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const togglePlay = useCallback(() => {
    if (currentSong) {
      if (audioRef.current.paused) {
        audioRef.current.play();
      } else {
        audioRef.current.pause();
      }
    }
  }, [currentSong]);

  const playNextSong = () => {
    const currentSongIndex = songs.findIndex(
      (song) => song._id === currentSong._id
    );
    currentSongIndex === songs.length - 1
      ? setCurrentSong(songs[0])
      : setCurrentSong(songs[currentSongIndex + 1]);
    audioRef.current.play();
  };

  const renderSongs = () => {
    const songsArray = formatSongArray(songs);
    return (
      <React.Fragment>
        {songsArray.map((array, i) => {
          if (array.length === 3) {
            if (i === songsArray.length - 1) {
              return (
                <SongsGrid ref={setLastSongGridRef} key={i} columns={3}>
                  {array.map((song, i) => {
                    if (song) {
                      return (
                        <Song
                          headingRef={songTrayHeadingRef}
                          currentSongId={currentSong && currentSong._id}
                          key={i}
                          song={song}
                          handleSelectSong={handleSelectSong}
                        />
                      );
                    }
                    return null;
                  })}
                </SongsGrid>
              );
            } else {
              return (
                <SongsGrid key={i} columns={3}>
                  {array.map((song, i) => {
                    if (song) {
                      return (
                        <Song
                          headingRef={songTrayHeadingRef}
                          currentSongId={currentSong && currentSong._id}
                          key={i}
                          song={song}
                          handleSelectSong={handleSelectSong}
                        />
                      );
                    }
                    return null;
                  })}
                </SongsGrid>
              );
            }
          } else {
            if (i === songsArray.length - 1) {
              return (
                <SongsGrid ref={setLastSongGridRef} key={i} columns={2}>
                  {array.map((song, i) => {
                    if (song) {
                      return (
                        <Song
                          headingRef={songTrayHeadingRef}
                          currentSongId={currentSong && currentSong._id}
                          key={i}
                          song={song}
                          handleSelectSong={handleSelectSong}
                        />
                      );
                    }
                    return null;
                  })}
                </SongsGrid>
              );
            } else {
              return (
                <SongsGrid key={i} columns={2}>
                  {array.map((song, i) => {
                    if (song) {
                      return (
                        <Song
                          headingRef={songTrayHeadingRef}
                          currentSongId={currentSong && currentSong._id}
                          key={i}
                          song={song}
                          handleSelectSong={handleSelectSong}
                        />
                      );
                    }
                    return null;
                  })}
                </SongsGrid>
              );
            }
          }
        })}
      </React.Fragment>
    );
  };

  return (
    <div className="soundapp_container">
      {showExportModal && (
        <ExportModal setOpen={setShowExportModal} user={user && user} />
      )}
      {showReportModal && (
        <Modal setIsOpen={setShowReportModal}>
          <ReportSong
            setIsOpen={setShowReportModal}
            currentSong={currentSong}
          />
        </Modal>
      )}
      <div className="soundApp">
        <SoundAppHeader
          user={user}
          setShowNav={setShowNav}
          showNav={showNav}
          currentSong={currentSong}
          setShowExportModal={setShowReportModal}
          showSideBeatDetails={showSideBeatDetails}
        />
        <div className="soundApp__player">
          <audio
            hidden
            autoPlay
            onPlay={() => setAudioPlaying(true)}
            onPause={() => setAudioPlaying(false)}
            ref={audioRef}
            src={currentSong && currentSong.audio}
            onEnded={playNextSong}
            onDurationChange={(e) => setAudioDuration(e.target.duration)}
            onTimeUpdate={(e) => {
              setCurrentAudioTime(e.target.currentTime);
              incrementPlayCount();
            }}
          ></audio>
          <div className="player-wrapper">
            <a href="##" className="album-art" onClick={togglePlay}>
              <img
                className={audioPlaying ? 'art' : 'art paused'}
                src={
                  currentSong && currentSong.coverArt
                    ? currentSong.coverArt
                    : AlbumArt
                }
                alt="cover art"
              />
              {!audioPlaying && (
                <img src={playButton} alt="play pause" className="play-pause" />
              )}
            </a>
            <div className="right">
              <div className="beat-details">
                <div className="producer-name">
                  {currentSong && currentSong.producer.nickname}
                </div>
                <div className="beat-name">
                  {currentSong && currentSong.title}
                </div>
                {/* <div className="info">Info</div> */}
              </div>
              <div className="actions">
                <a href="##" className="actions__item" onClick={handleSaveSong}>
                  <img src={!isSaved ? saveIcon : savedIcon} alt="save icon" />
                  <div className="icon-text">{isSaved ? 'UNSAVE' : 'SAVE'}</div>
                </a>
                <a
                  href="##"
                  className="actions__item"
                  onClick={() => setShowExportModal(true)}
                >
                  <img src={exportIcon} alt="export icon" />
                  <div className="icon-text">EXPORT</div>
                </a>
                <a
                  href="##"
                  className="actions__item"
                  onClick={() => {
                    if (currentSong) shareSong();
                  }}
                >
                  <img src={shareIcon} alt="share icon" />
                  <div className="icon-text">SHARE</div>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div
          className="progress-bar"
          onClick={currentSong && handleAudioTimeSeek}
        >
          <div ref={progressBarRef} className="background">
            <div
              style={{
                width: `${(currentAudioTime / audioDuration) * 100}%`,
              }}
              className="progress"
            ></div>
          </div>
        </div>

        <div
          ref={songTrayHeadingRef}
          className="soundApp__songs-tray"
          style={{ height: songsTrayHeight }}
        >
          <div className="headings">
            <div className="wrapper">
              <a
                href="##"
                onClick={() => {
                  setActiveFilterTab('popular');
                  setHasMore(false);
                }}
                className={
                  activeFilterTab === 'popular'
                    ? 'headings__item selected'
                    : 'headings__item'
                }
              >
                Hottest
              </a>
              <a
                href="##"
                onClick={() => {
                  setActiveFilterTab('recent');
                  setHasMore(false);
                }}
                className={
                  activeFilterTab === 'recent'
                    ? 'headings__item selected'
                    : 'headings__item'
                }
              >
                Recent
              </a>
              <a
                href="##"
                onClick={() => {
                  setActiveFilterTab('saved');
                  setHasMore(false);
                }}
                className={
                  activeFilterTab === 'saved'
                    ? 'headings__item selected'
                    : 'headings__item'
                }
              >
                Saved
              </a>
            </div>
          </div>
          {loading ? (
            <div className="songs-loading">loading....</div>
          ) : (
            <React.Fragment>
              {!loading && (!songs || songs.length < 1) ? (
                <div className="songs-empty">
                  No songs available in this category
                </div>
              ) : (
                <div className="songs">
                  {songs && renderSongs()}
                  {nextPageLoading && (
                    <div className="songs-loading">loading....</div>
                  )}
                </div>
              )}
            </React.Fragment>
          )}
        </div>
      </div>
    </div>
  );
}

export default SoundApp;
