import React from "react";
import { motion } from "framer-motion";
import { Player as LottiePlayer } from "@lottiefiles/react-lottie-player";

// Assets
import CloseBtnDark from "../../images/closeDark.svg";
import CloseBtnWhite from "../../images/closeWhite.svg";
import loadingAnim from "../../LottieAnimations/loading-white.json";
import CheckedIcon from "../../images/checked.svg";

const ExportSuccess = ({ setOpen, user, loading, exportSongs, darkMode }) => {
  return (
    <div className="export-wrapper">
      <div className="top">
        <div className="title">
          <h1>DOWNLOAD</h1>
          <motion.button
            initial={{ opacity: 0.2 }}
            whileHover={{
              scale: 1.1,
              opacity: 1,
              transition: { duration: 0.2 },
            }}
            whileTap={{ scale: 1 }}
            onTap={setOpen}>
            <img
              src={darkMode ? CloseBtnDark : CloseBtnWhite}
              alt="close Button"
            />
          </motion.button>
        </div>
        <h2>COMPLETE</h2>
      </div>
      <div className="success-message">
        {loading ? (
          <LottiePlayer autoplay loop src={loadingAnim}></LottiePlayer>
        ) : (
          <React.Fragment>
            <div>
              <img src={CheckedIcon}></img>
            </div>
            <div className="message">
              We emailed the beats you selected to <span>{user.email}</span>
              <br />
              <br />
              <br />
              If you have not received an email after 20 minutes, Click Resend
              below or Export the beats again
            </div>
          </React.Fragment>
        )}
      </div>
      <div className="bottom">
        <button
          disabled={loading}
          className="export-button"
          onClick={() => exportSongs()}>
          RESEND
        </button>
      </div>
    </div>
  );
};

export default ExportSuccess;
