import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import API from "../api";
import HomeHeader from "../components/Home/HomeHeader";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import songIcon from "../images/play.svg";
import Fade from "react-reveal/Fade";
import bgVideo from "../images/bg2.mp4";
import DownloadIconLight from "../images/download-icon-light.svg";
import ShareIconLight from "../images/share-light.svg";
import { shareSongCopyLink } from "../components/Sound/utils";
import PlayingIconBlack from "../LottieAnimations/playing-icon-black.json";
import { Player } from "@lottiefiles/react-lottie-player";

const SharedSongPage = ({ history, pageType }) => {
  const  songId  = localStorage.getItem('sharedSongId');
  const [song, setSong] = useState({ shared: true, song: "" });
  const { user, loading } = useSelector((state) => state.auth);
  const [homeAudioPlaying, setHomeAudioPlaying] = useState();
  const audioRef = useRef();
  const audioSharedRef = useRef();
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentSong, setCurrentSong] = useState('');
  const [sharedSongList, setsharedSongList] = useState([])
  const [sharedProducerName, setsharedProducerName] = useState('')
  // State variable to store the text
  const [displayText, setDisplayText] = useState('');

  const handleAudioPlay = () => {
    if (audioRef.current) {
      if (homeAudioPlaying) {
        audioRef.current.pause();
      } else {
        audioRef.current.play();
      }
    }
  };
  const handleAudioSharedPlay = (song) => {
    setCurrentSong(song);
    if (audioSharedRef.current) {
      if (isPlaying && currentSong.id === song.id) {
        setIsPlaying(false);
        audioSharedRef.current.pause();
      } else {
        setIsPlaying(true);
        audioSharedRef.current.play();
      }
    }
  }



  useEffect(() => {
    const urlParams = window.location.pathname.split('/');
    const producer = urlParams[urlParams?.length - 1];
    if (user) {
      if (user.userType === "artiste") {
        history.push("/sound");
      } else if (user.userType === "listener") {
        history.push("/stream");
      }
    } else if ((!user && !loading) || (user && user.userType !== "artiste" && user.userType !== "listener")) {
      API.getSharedSongList(producer,pageType)
        .then(response => {
          setSong({ shared: true, song: response?.songs[0] })
          setCurrentSong(response?.songs[0])
          setsharedSongList(response?.songs)
          setsharedProducerName(producer)
        }).catch(console.log);
    }

    // Based on pageType, set the text
    if (pageType === 'stream') {
      setDisplayText('STREAM');
    } else if (pageType === 'sound') {
      setDisplayText('BEAT');
    }

  }, [user, songId, loading, pageType])

  return (
    <div className="home shareHome">
      <video className="videoTag" autoPlay loop muted playsInline>
        <source src={bgVideo} type="video/mp4" />
      </video>
      <audio
        hidden
        ref={audioSharedRef}
        src={currentSong && currentSong.audio}
      ></audio>
      <div className="home__middle">
        <React.Fragment>
        {sharedSongList.length > 0 &&
          <div className="shared-parent">
            <h3 className="text-align-center pb-10">{displayText} CATALOG <span className="producer-name-unbold">{sharedProducerName?.toUpperCase()}</span></h3>
            <div className="shared-list">
              {sharedSongList.map((item, index) => (
                <div key={index} className={currentSong.id === item.id && isPlaying ? 'play-bg hover display-flex justify-content-space-between pt-5 pb-5 align-item-center width300px' : 'hover display-flex justify-content-space-between pt-5 pb-5 align-item-center width300px'}>
                  <div className="display-flex justify-content-start align-item-center">
                    <div className='pr10'>
                      {currentSong.id === item.id && isPlaying ? (
                        <>
                          <motion.button
                            whileHover={{
                              scale: 0.95,
                              transition: { duration: 0.2 },
                            }}
                            whileTap={{ scale: 1 }}
                            animate={{
                            transition:{duration:0.2},
                            scale: 0.95,
                            }}
                            onTap={() => {
                              handleAudioSharedPlay(item)
                            }}
                            className="play-and-pause">
                            <Player
                              className="play-and-pause"
                              autoplay
                              loop
                              src={PlayingIconBlack}></Player>
                          </motion.button>
                        </>
                      ) : (
                        <motion.img
                          whileHover={{
                            scale: 0.95,
                            transition: { duration: 0.2 },
                          }}
                          whileTap={{ scale: 1 }}
                          className="play-and-pause"
                          src={songIcon}
                          onTap={() => handleAudioSharedPlay(item)}></motion.img>
                      )}
                    </div>
                    <div>
                      <p>{item.title}</p>
                    </div>
                  </div>

                  <div>
                    {pageType !== 'stream' ? (
                    <motion.button
                      initial={{ opacity: 0.8, scale: 1.2 }}
                      whileHover={{
                        scale: 1.4,
                        opacity: 1,
                        transition: { duration: 0.2 },
                      }}
                      whileTap={{ scale: 1 }}
                      onTap={() => history.push(`/signup/${sharedProducerName}`)}
                      className="download-icon">
                      <img src={DownloadIconLight}></img>
                    </motion.button>
                        ):('')}
                  </div>
                </div>
              ))}
            </div>
            <div className="drive-parent">
              <div>
                <motion.div
                  whileHover={{
                    rotate: 360,
                    transition: { duration: 2 },
                  }}
                  className={isPlaying ? "animated-cd-player circle-icon" : "circle-icon"}>
                  <img
                    className="coverArt"
                    src={
                      currentSong
                        ? currentSong.coverArt
                          ? currentSong.coverArt
                          : "https://images.unsplash.com/photo-1545486332-9e0999c535b2?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=634&q=80"
                        : "https://images.unsplash.com/photo-1545486332-9e0999c535b2?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=634&q=80"
                    }></img>
                  <div className="circle-mid"></div>
                </motion.div>
              </div>
              <div>
                {pageType === 'stream' ? (
                <Fade bottom cascade>
                  <div className="heading-Smob pb-20 share-paragraph-text" >
                    <p className="font-size12">STREAM UNLIMITED </p>{" "}
                    <p className="font-size12">MUSIC FROM </p>
                    <p className="font-size12">EMERGING ARTISTS</p>
                  </div>
                </Fade>
                ) : (
                    <Fade bottom cascade>
                      <div className="heading-Smob pb-20 share-paragraph-text">
                        <p className="font-size12">UNLIMITED BEAT  </p>{" "}
                        <p className="font-size12">DOWNLOADS ONLY </p>
                        <p className="font-size12">$9/MONTH</p>
                      </div>
                    </Fade>
                )}
                    {pageType === 'stream' ? (
                    <Link
                        to={`/stream/signup/${sharedProducerName}`}
                        className="white-btn shared-btn"
                    >
                      Join Stream
                    </Link>
                ) : (
                    <Link
                        to={`/signup/${sharedProducerName}`}
                        className="white-btn shared-btn"
                    >
                      Download
                    </Link>
                )}

              </div>
              <div className="pt-70">
                <motion.button
                  initial={{ opacity: 0.8, scale: 1.2 }}
                  whileHover={{
                    scale: 1.4,
                    opacity: 1,
                    transition: { duration: 0.2 },
                  }}
                  whileTap={{ scale: 1 }}
                  onTap={() => shareSongCopyLink(currentSong.id)}
                  className="download-icon">
                  <img height={20} width={20} src={ShareIconLight}></img>
                </motion.button>

              </div>
            </div>
          </div>
        }

        </React.Fragment>
      </div>
      <React.Fragment>
        <HomeHeader
          withAudio={true}
          setHomeAudioPlaying={setHomeAudioPlaying}
          handleAudioPlay={handleAudioPlay}
          sharedSong={song.song}
          isSharedPage={song.shared}
          ref={audioRef}
          incompleteRegistration={
            user && user.userType === "artiste" && !user.completedRegistration
          }
        />
      </React.Fragment>
      <div className="home__policy">
        <ul>
          <li>
            <Link to="/support">Support</Link>
          </li>
          <li>
            <Link to="/privacy-policy">Privacy Policy</Link>
          </li>
        </ul>
      </div>
    </div>
  )
};

export default SharedSongPage;
