import React, { useState } from "react";
import API from "../api";
import { Link } from "react-router-dom";

const ResetPasswordPage = ({ match }) => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [formData, setFormData] = useState({
    password: "",
    confirmPassword: "",
  });
  const [submissionError, setSubmissionError] = useState("");
  const { user, token } = match.params;

  const handleResetPassword = () => {
    setSubmissionError("");
    const { password, confirmPassword } = formData;
    if (!password || !confirmPassword) {
      setSubmissionError("All fields are required");
      return;
    }
    if (password && (password.length < 6 || password.length > 20)) {
      setSubmissionError("Password must be between 6 and 20 characters");
      return;
    }
    if (password && confirmPassword && password !== confirmPassword) {
      setSubmissionError("passwords must be the same");
      return;
    }
    setLoading(true);
    API.handleResetPassword({ password }, user, token)
      .then((res) => {
        console.log(res);
        setSuccess(true);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setSubmissionError(err.data.message);
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <div className="container">
      <div className="forgotPassword">
        <div className="forgotPassword__title">
          {success ? `${user} password changed` : `Reset your ${user} password`}
        </div>
        <div className="error-message">{submissionError}</div>
        {success ? (
          <React.Fragment>
            <p>
              Your {user} password has been changed successfully. Please log in
            </p>
            {user === "producer" && (
                <Link
                    className="white-btn"
                    to="/producer/signin"
                >
                  Log in
                </Link>
            )}

            {user === "artiste" && (
                <Link
                    className="white-btn"
                    to="/signin"
                >
                  Log in
                </Link>
            )}

            {user === "listener" && (
                <Link
                    className="white-btn"
                    to="/stream/signin"
                >
                  Log in
                </Link>
            )}
          </React.Fragment>
        ) : (
          <React.Fragment>
            <input
              type="password"
              placeholder="Please enter new password"
              className="input-text"
              value={formData.password}
              onChange={handleChange}
              name="password"
            />
            <input
              type="password"
              placeholder="Please enter new password again"
              className="input-text"
              value={formData.confirmPassword}
              onChange={handleChange}
              name="confirmPassword"
            />
            <button
              onClick={handleResetPassword}
              disabled={loading}
              className="white-btn"
            >
              {loading ? "Please wait..." : "Change Password"}
            </button>
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

export default ResetPasswordPage;
