import React, { useState, useEffect } from "react";
// import ReactDOM from "react-dom";
import axios from "axios";
import ExportSuccess from "./ExportSuccess";
import ExportWigdet from "./ExportWidget";
import API from "../../api";

function ExportModal({ user, setOpen }) {
  const [savedSongs, setSavedSongs] = useState();
  const [loading, setLoading] = useState(false);
  const [exportSuccess, setExportSuccess] = useState(false);
  const [selectedBeats, setSelectedBeats] = useState([]);

  useEffect(() => {
    setLoading(true);
    API.fetchSongs(1, "saved")
      .then((data) => {
        // console.log(data);

        setSavedSongs(data.filter((song) => !!song));
        setLoading(false);
      })
      .catch((err) => {
        console.log(err.reponse);
        setLoading(false);
      });
  }, [user]);

  const exportSongs = () => {
    if (selectedBeats.length < 1) return;
    setLoading(true);
    API.exportSongs({ songs: selectedBeats })
      .then(() => {
        setExportSuccess(true);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    document.body.setAttribute("style", "overflow: hidden");
    return () => {
      document.body.setAttribute("style", "overflow: scroll");
    };
  }, []);

  return (
    <div className="exportModal">
      <div className="export_container">
        {exportSuccess ? (
          <ExportSuccess
            setOpen={setOpen}
            exportSongs={exportSongs}
            user={user}
            loading={loading}
          />
        ) : (
          <ExportWigdet
            setSavedSongs={setSavedSongs}
            songs={savedSongs}
            setOpen={setOpen}
            exportSongs={exportSongs}
            user={user}
            loading={loading}
            selectedBeats={selectedBeats}
            setSelectedBeats={setSelectedBeats}
          />
        )}
      </div>
    </div>
  );
}

export default ExportModal;
