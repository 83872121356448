import React, { useEffect, useState, useCallback } from "react";
import StreamNav from "./StreamNav";
import StreamCard from "./StreamCards";
import StreamPlayer from "./StreamPlayer";
import API from "../../api";
import _ from "lodash";
import { useSelector } from "react-redux";
import ReportSong from "./ReportSong";
import { toast } from "react-toastify";
import CardOne from "../../images/cardOne.png";
import CardTwo from "../../images/cardTwo.png";
import CardThree from "../../images/cardThree.png";

const toastOptions = {
  position: "top-left",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

let defaultTabs = [
  {
    nickname: 'popular',
    id: 0,
    title: 'Hottest',
    tabImage: CardOne
  },
  {
    nickname: 'recent',
    id: 0,
    title: 'recent',
    tabImage: CardTwo

  },
  {
    nickname: 'saved',
    id: 0,
    title: 'saved',
    tabImage: CardThree
  }
]

const MainApp = ({ darkMode, setDarkMode }) => {
  const [loading, setLoading] = useState(false);
  const [songs, setSongs] = useState([]);
  const [currentSong, setCurrentSong] = useState("");
  const [openReportModal, setOpenReportModal] = useState(false);
  const [sharedSong, setSharedSong] = useState();
  const [playlistTabs, setplaylistTabs] = useState([])
  const [isScroll, setisScroll] = useState(false)
  const [urlParamProducerName, setUrlParamProducerName] = useState('stream')
  const [activeFilterTabObject, setActiveFilterTabObject] = useState(defaultTabs[0])

  // Page Management
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [nextPageLoading, setNextPageLoading] = useState(false);
  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    setLoading(true);
    let pathName = window.location.pathname.split('/')
    let producerName = pathName[pathName.length - 1]
    if (user) {
      loadSongs();
      getProducerList()
      setUrlParamProducerName(producerName)
    }
  }, [user]);

  useEffect(() => {
    loadSongs();
  }, [activeFilterTabObject])




  const loadSongs = async () => {
    try {
      const data = await API.fetchSongs(1, activeFilterTabObject?.nickname, activeFilterTabObject?.id, user.userType,"song");
      if (
        sharedSong &&
        (activeFilterTabObject.nickname === "recent" || activeFilterTabObject.nickname === "popular")
      ) {
        setSongs(
          _.uniqBy([sharedSong, ...data.filter((song) => !!song)], "_id")
        );
      } else {
        setSongs(data.filter((song) => !!song));
      }
      setPage(1);
      setLoading(false);
      data.length < 15 ? setHasMore(false) : setHasMore(true);
      await getSharedSong();
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    addToPlayListByUrl()
  }, [window.location.pathname])


  const getSharedSong = async () => {
    const sharedSongId = localStorage.getItem("sharedSongId");
    if (!sharedSongId) return;
    API.getSong(sharedSongId)
      .then((res) => {
        if (res.song.type === 'song') {
          setSongs((prev) => _.uniqBy([res?.song, ...prev], "_id"));
          setCurrentSong(res?.song);
          setSharedSong(res?.song);

          localStorage.removeItem("sharedSongId");
        }
      })
      .catch(console.log);
  };

  const loadNextPage = useCallback(async () => {
    if (!hasMore) return;
    if (hasMore) {
      setNextPageLoading(true);

      const data = await API.fetchSongs(page + 1, activeFilterTabObject.nickname, activeFilterTabObject.id, user.userType,"song");
      if (data?.length > 0) {
        setSongs((prev) => _.uniqBy([...prev, ...data], "_id"));
        setNextPageLoading(false);
        if (data.length < 15) {
          setHasMore(false);
        } else {
          setPage((prev) => prev + 1);
        }
      } else {
        setNextPageLoading(false);
      }
    }
  }, [hasMore, songs, page, activeFilterTabObject]);

  const addPlayList = (list) => {
    setisScroll(true)
    let playListArry = [...playlistTabs]
    let checkIsAlreadyAdded = playListArry.some((respomse => respomse.id === list?.producer?.id))
    if (checkIsAlreadyAdded) {
      toast.error("Your producer already added", toastOptions);
    } else {
      API.addFavouriteProducers(list?.producer?.id)
        .then(res => {
          if (res.producer !== null) {
            playListArry.push(res.producer)
            setplaylistTabs(playListArry)
          } else {
            toast.error("something went wrong", toastOptions);
          }

        })
        .catch(console.log);
    }
    setisScroll(false)
  }

  const removeFromTabList = (id) => {
    let playListArry = [...playlistTabs]
    API.deleteFavouriteProducer(id)
      .then(response => {
        let newPlayListArry = playListArry.filter((item) => item.id !== id)
        setplaylistTabs(newPlayListArry)
        setActiveFilterTabObject(defaultTabs[0])
      })
      .catch(console.log);
  }

  const getProducerList = () => {
    API.getFavouriteProducers()
      .then(response => {
        setplaylistTabs(response.producers)
      })
      .catch(console.log);
  }
  const addToPlayListByUrl = () => {
    let pathName = window.location.pathname.split('/')
    let producerName = pathName[pathName.length - 1]
    if (urlParamProducerName !== producerName) {
      API.getSharedSongList(producerName,'stream')
        .then(response => {
          if (response?.songs) {
            addPlayList(response?.songs[0])
            setUrlParamProducerName('')
            getProducerList()
          }
        }).catch(() => {
          toast.error("Invalid Producer Name", toastOptions);
        });
    }
  }

  return (
    <div className="sound-main-wrapper">
      <StreamNav
        setDarkMode={setDarkMode}
        darkMode={darkMode}
        ></StreamNav>
      {openReportModal && (
        <ReportSong
          darkMode={darkMode}
          currentSong={currentSong}
          setOpenReportModal={setOpenReportModal}></ReportSong>
      )}

      <div className="main-content">

          <StreamCard
              isScroll={isScroll}
              playlistTabs={playlistTabs}
              removeFromTabList={removeFromTabList}
              setActiveFilterTabObject={setActiveFilterTabObject}
              activeFilterTabObject={activeFilterTabObject}
              defaultTabs={defaultTabs}
          />


          <StreamPlayer
              loading={loading}
              songs={songs}
              nextPageLoading={nextPageLoading}
              loadNextPage={loadNextPage}
              currentSong={currentSong}
              setCurrentSong={setCurrentSong}
              setOpenReportModal={setOpenReportModal}
              addPlayList={addPlayList}
              darkMode={darkMode}></StreamPlayer>
      </div>
    </div>
  );
};

export default MainApp;
