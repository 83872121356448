import React, { useEffect, useState } from "react";
import signupCompleteImg from "../images/exportSuccess.png";
import { Link } from "react-router-dom";
import API from "../api";

const ProducerConfirmEmailPage = ({ match }) => {
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(false);
  const { token } = match.params;

  useEffect(() => {
    API.confirmProducerEmail(token)
      .then((data) => {
        setSuccess(true);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setSuccess(false);
      });
  }, []);
  return (
    <div className="producerProfile">
      {!loading && success && (
        <React.Fragment>
          <p>Your email is now confirmed</p>
          <img src={signupCompleteImg} alt="Email Confirmed" />
          <Link className="white-btn red" to="/producer/signin">
            Login
          </Link>
        </React.Fragment>
      )}
      {loading && <div>Please wait....</div>}
      {!loading && !success && (
        <div className="">
          <p>
            Invalid Token! Please check your email to make sure you clicked on
            the correct link
          </p>
          <p>
            you can resend email confirmation email by login in to your account
            below
          </p>
          <Link className="white-btn red" to="/producer/signin">
            Login
          </Link>
        </div>
      )}
    </div>
  );
};

export default ProducerConfirmEmailPage;
