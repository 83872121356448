import React, { useEffect } from "react";
import SideNav from "./SideNav";
import HomeHeader from "../Home/HomeHeader";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import setAxiosAuthToken from "../../utils/setAxiosAuthToken";

function AdminLayout({ children, activeNav }) {
  const { user, loading } = useSelector((state) => state.auth);
  const history = useHistory();
  useEffect(() => {
    setAxiosAuthToken(localStorage.getItem("auth-token"));
    if (!user && !loading) {
      history.push("/admin/signin");
      console.log("here");
    } else if (user && user.userType !== "admin") {
      history.push("/");
    }
  }, [user, loading]);
  return (
    <React.Fragment>
      <div className="not-available">This page is not available on mobile</div>
      <div className="dashboard">
        <HomeHeader isWhiteBackground={true} />
        <div className="dashboardLayout">
          <div className="dashboardLayout__left">
            <SideNav activeNav={activeNav} />
          </div>
          <div className="dashboardLayout__right">
            {user && user.isApproved && children}
            {user && !user.isApproved && (
              <div className="not-approved">
                Your Admin account has not been approved, once approved you will
                be able to view this page
              </div>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default AdminLayout;
