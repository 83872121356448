import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import MainApp from "../components/Stream/MainApp";
import {canUseStreamApp} from "../utils/userRIghts";
import SharedSongPage from "./SharedSongPage";
import {useLocation} from "react-router-dom";
import {toast} from "react-toastify";

const StreamPage = ({ history }) => {
  const [darkMode, setDarkMode] = useState(true);
  const { user, loading, isSignedIn } = useSelector((state) => state.auth);
  const location = useLocation();
  const currentPath = location.pathname;
  const toastOptions = {
    position: "top-left",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };


  useEffect(() => {

    if (!isSignedIn) {
      if (currentPath.includes('/stream/')) {
        history.push(`/stream/${currentPath.split('/stream/')[1]}`);
      } else {
        history.push("/stream/signin");
      }
    } else if (user && !canUseStreamApp(user)) {
      history.push("/manage-subscription");
    } else if (user && user.userType !== 'listener'){
      history.push("/");
      toast.error("You Cannot Access Stream App!", toastOptions);
    }

  }, [user, history, isSignedIn]);

  if (!loading && !user) {
    return (
      <SharedSongPage history={history} pageType={'stream'}/>
    )
  }
  return (
    <div className={`sound-container`} data-theme={darkMode ? "dark" : "light"}>
      <MainApp darkMode={darkMode} setDarkMode={setDarkMode}></MainApp>
    </div>
  );
};

export default StreamPage;
