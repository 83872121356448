import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Signin from "../components/Signin";
import { useLocation } from "react-router-dom";
import { authSuccess } from "../actions";

const SigninPage = ({ history }) => {
  const location = useLocation();
  const { isSignedIn } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  let { from } = location.state || { from: { pathname: "/" } };

  const navigate = () => {
    const sharedSongId = localStorage.getItem("sharedSongId");
    if (sharedSongId) {
      history.push("/sound");
    } else {
      history.replace(from);
    }
  };

  const onSigninSuccess = (user) => {
    dispatch(authSuccess(user));
    navigate();
  };

  useEffect(() => {
    if (isSignedIn) {
      navigate();
    }
  }, [isSignedIn, from, history]);

  let userType = 'artistes';
  return <Signin onSigninSuccess={onSigninSuccess} userType={userType}/>;
};

export default SigninPage;
