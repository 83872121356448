import React, { useState } from "react";
import closeButton from "../../images/exitButton.png";
import successPng from "../../images/exportSuccess.png";
import API from "../../api";

const ReactivateSound = ({setIsOpen, user}) => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const reactivateSoundSubscription = () => {
    setLoading(true);
    API.reactivateSoundSubscription()
      .then(() => setSuccess(true))
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  return (
    <div className="loud-window">
      <div className="top">
        <div className="title">
          <h1>Activate</h1>
          <img
            onClick={() => {
              if (success) window.location.reload();
              else setIsOpen(false);
            }}
            src={closeButton}
            alt="close Button"
            className="close"
          />
        </div>
      </div>
      {success ? (
        <React.Fragment>
          <img className="success-image" src={successPng} alt="success icon" />
          <div className="success-text">
              {user && user.userType === 'listener' ? (" Your stream subscription has been reactivated succesfully"
              ) : (
                  " Your sound subscription has been reactivated succesfully"
              )}
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <div className="reactivate">
              {user && user.userType === 'listener' ? (" Please click the button below to reactivate your stream $9/Mo subscription"
              ) : (
                  " Please click the button below to reactivate your sound $9/Mo subscription"
              )}

          </div>
          <button
            disabled={loading}
            onClick={reactivateSoundSubscription}
            className="window-btn"
          >
            {loading ? "Please Wait..." : "Activate"}
          </button>
        </React.Fragment>
      )}
    </div>
  );
};

export default ReactivateSound;
