import React, { useState } from "react";
import API from "../../api";

const checkIfDataIsValid = (data, setSubmissionError) => {
  const { password, firstname, lastname, email } = data;
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (!password || !firstname || !lastname || !email) {
    setSubmissionError("All fields are required");
    return false;
  }
  if (email && !re.test(email.toLowerCase())) {
    setSubmissionError("Invalid email");
    return false;
  }
  if (password && (password.length < 6 || password.length > 20)) {
    setSubmissionError("Password should be between 6 and 20 characters");
    return false;
  }
  return true;
};

const ProducerSIgnupStep2 = ({
  formData,
  setFormData,
  setPage,
  setSubmissionError,
}) => {
  const [loading, setLoading] = useState(false);

  const goToNextPage = () => {
    setSubmissionError("");
    const isFormValid = checkIfDataIsValid(formData, setSubmissionError);
    if (isFormValid) {
      setLoading(true);
      API.producerSignup({
        ...formData,
        email: formData.email.toLowerCase().trim(),
      })
        .then(() => {
          setPage(3);
        })
        .catch((err) => {
          setLoading(false);
          setSubmissionError(err.data.message);
        });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <div className="producerSignup__step2">
      <input
        type="text"
        name="nickname"
        className="input-text"
        placeholder="choose your LOUD producer name"
        value={formData.nickname}
        readOnly
      />
      <div className="double-input">
        <input
          type="text"
          name="firstname"
          className="input-text"
          placeholder="Firstname"
          value={formData.firstname}
          onChange={handleChange}
        />
        <input
          type="text"
          name="lastname"
          className="input-text"
          placeholder="Lastname"
          value={formData.lastname}
          onChange={handleChange}
        />
      </div>
      <input
        type="text"
        name="email"
        className="input-text"
        placeholder="Email"
        value={formData.email}
        onChange={handleChange}
      />
      <input
        type="password"
        name="password"
        className="input-text"
        placeholder="Password"
        value={formData.password}
        onChange={handleChange}
      />
      <div className="double-input">
        <button
          disabled={loading}
          className="white-btn"
          onClick={() => setPage(1)}
        >
          Back
        </button>
        <button disabled={loading} className="white-btn" onClick={goToNextPage}>
          {loading ? "Please Wait..." : "Sign Up"}
        </button>
      </div>
    </div>
  );
};

export default ProducerSIgnupStep2;
