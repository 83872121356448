import React, { useEffect, useState } from "react";
import API from "../api";

import Signup from "../components/signup";

const PartnerSignupPage = ({ match, history }) => {
  const { partner } = match.params;
  const [loading, setLoading] = useState(true);
  const [partnerData, setPartnerData] = useState({});

  useEffect(() => {
    API.checkPartnerName(partner)
      .then(({ partner }) => {
        setPartnerData(partner);
        if (partner.isActive) {
          setLoading(false);
        } else {
          history.push("/signup");
        }
      })
      .catch(() => history.push("/signup"));
    setLoading(false);
  }, [partner, history]);

  return (
    <React.Fragment>
      {loading ? (
        <div>loading</div>
      ) : (
        <Signup partnerName={partner} partnerData={partnerData} />
      )}
    </React.Fragment>
  );
};

export default PartnerSignupPage;
