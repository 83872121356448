import { loadStripe } from "@stripe/stripe-js";
export const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);
export const stripeOptions = {
  fonts: [
    {
      cssSrc:
        "https://fonts.googleapis.com/css2?family=Jost:wght@100;200;400;500;700&display=swap",
      family: "jost",
      style: "normal",
    },
  ],
};
export const prices =
  process.env.REACT_APP_ENV === "production"
    ? [
        "price_1J1daiBlovt61D4yN7GXG4EB",
        "price_1J1daaBlovt61D4ycVy9J9gI",
        "price_1J1daVBlovt61D4ybA6ZjbPS",
        "price_1J1daPBlovt61D4yA1sGVWYl",
        "price_1J1daHBlovt61D4yLiVSJlyp",
        "price_1IqHj4Blovt61D4yfgv2pyRR",
        "price_1IqHitBlovt61D4yN0QmnqEA",
        "price_1IqHijBlovt61D4ykjx5LJOz",
        "price_1IqHiVBlovt61D4yRRKSHu0i",
        "price_1IbnIABlovt61D4yvXKbxnCv",
      ]
    : [
        "price_1JYsDjBlovt61D4yA4FesjRu",
        "price_1JYsDsBlovt61D4ycTe5WpYs",
        "price_1JYsDxBlovt61D4yVPwKUVfc",
        "price_1JYsE3Blovt61D4yssiR2YKS",
        "price_1JYsE8Blovt61D4y6LuelVR8",
        "price_1JYsECBlovt61D4yYP5G66GH",
        "price_1JYsEHBlovt61D4ymTw6HQYX",
        "price_1JYsEMBlovt61D4y7aXWIJNF",
        "price_1JYsEQBlovt61D4yTQK3RjEp",
        "price_1JYsEWBlovt61D4yMWUtexaZ",
      ];

export const producerChallengePrice =
  process.env.REACT_APP_ENV === "production"
    ? "price_1JbNG9Blovt61D4y1sNAHO29"
    : "price_1JbNG9Blovt61D4y1sNAHO29";
