import React from "react";
import EmptyImage from "../../images/empty.png";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const capitalizeFirstLetter = (str) => {
  return str[0].toUpperCase() + str.slice(1, str.length);
};

function SideNav({ activeNav }) {
  const { user } = useSelector((state) => state.auth);

  return (
    <React.Fragment>
      {user && (
        <React.Fragment>
          <div className="user">
            <div className="user__image">
              <img src={user.photo ? user.photo : EmptyImage} alt="user" />
            </div>
            <div className="user__name">
              {capitalizeFirstLetter(user.firstname)}{" "}
              {user.lastname[0].toUpperCase()}.
            </div>
            <div className="user__role">
              {capitalizeFirstLetter(user.userType)}
            </div>
          </div>
          <div className="navigation">
            <Link
              className={
                activeNav === "summary"
                  ? "navigation__item active"
                  : "navigation__item"
              }
              to="/admin"
            >
              Summary
            </Link>
            <Link
              className={
                activeNav === "producer"
                  ? "navigation__item active"
                  : "navigation__item"
              }
              to="/admin/producers"
            >
              Producers
            </Link>
            <Link
              className={
                activeNav === "artiste"
                  ? "navigation__item active"
                  : "navigation__item"
              }
              to="/admin/artistes"
            >
              Artistes
            </Link>
            <Link
              className={
                activeNav === "beat"
                  ? "navigation__item active"
                  : "navigation__item"
              }
              to="/admin/beats"
            >
              Beats
            </Link>
            <Link
              className={
                activeNav === "song"
                  ? "navigation__item active"
                  : "navigation__item"
              }
              to="/admin/songs"
            >
              Songs
            </Link>
            <Link
              className={
                activeNav === "secret"
                  ? "navigation__item active"
                  : "navigation__item"
              }
              to="/admin/secret-service"
            >
              Secret Service
            </Link>
            <Link
              className={
                activeNav === "partner"
                  ? "navigation__item active"
                  : "navigation__item"
              }
              to="/admin/partners"
            >
              Partners
            </Link>
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

export default SideNav;
