import { AUTH_SUCCESS, AUTH_ERROR } from '../actions/types';

const INITIAL_STATE = {
  isSignedIn: false,
  user: null,
  loading: true,
};

const authReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case AUTH_SUCCESS:
      return {
        ...state,
        isSignedIn: true,
        user: payload,
        loading: false,
      };
    case AUTH_ERROR:
      localStorage.removeItem('auth-token');
      return {
        ...INITIAL_STATE,
        loading: false,
      };
    default:
      return state;
  }
};

export default authReducer;
