import React, { useState, useRef, useEffect } from "react";
import { motion } from "framer-motion";
import API from "../../api";
import { shareSongCopyLink } from "./utils";

// Assets
import HeartIconDark from "../../images/heart-dark.svg";
import HeartIconLight from "../../images/heart-light.svg";
import WhishlistGray from "../../images/playlist2.png";
import WhishlistWhite from "../../images/playlist3.png";
import ShareIconDark from "../../images/share-dark.svg";
import ShareIconLight from "../../images/share-light.svg";
import forwardBtnDark from "../../images/forward-button-dark.svg";
import forwardBtnLight from "../../images/forward-button-light.svg";
import PrevBtnDark from "../../images/rewind-button-dark.svg";
import PrevBtnLight from "../../images/rewind-button-light.svg";
import ReportIconDark from "../../images/exclamation-mark-dark.svg";
import ReportIconLight from "../../images/exclamation-mark-white.svg";
import DownloadIconDark from "../../images/download-icon-dark.svg";
import DownloadIconLight from "../../images/download-icon-light.svg";
import PlaylistIcon from "../../images/playlist.png";
import {useSelector} from "react-redux";

const PlayerMobile = ({
  audioRef,
  isPlaying,
  currentSong,
  nextSong,
  prevSong,
  // setOpenExportModal,
  audioDuration,
  setIsPlaying,
  currentAudioTime,
  setOpenReportModal,
  darkMode,
  addPlayList
}) => {
  const [saved, setIsSaved] = useState(true);
  const progressBarRef = useRef(null);
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    if (currentSong !== "") {
      checkIfSongIsSaved(currentSong ? currentSong.id : "");
    }
  }, [currentSong]);

  const checkIfSongIsSaved = (id) => {
     API.checkIfSongIsSaved(id, user.userType)
      .then((res) => {
        setIsSaved(true);
      })
      .catch(() => setIsSaved(false));
  };

  const handleSaveSong = async () => {
    if (currentSong.id && !saved) {
      await API.saveSong(currentSong.id, "save");
      setIsSaved(true);
    } else if (currentSong.id && saved) {
      await API.saveSong(currentSong.id, "remove");
      setIsSaved(false);
    }
  };

  //Handle audio time seek when user click on part of the the progress bar
  const handleAudioTimeSeek = (e) => {
    let percentagePosition =
      (e.clientX - progressBarRef.current.getBoundingClientRect().left) /
      progressBarRef.current.offsetWidth;
    audioRef.current.currentTime = percentagePosition * audioDuration;
    if (audioRef.current.paused) {
      audioRef.current.pause();
    }
  };

  return (
    <div className="sound-player-mobile">
      {currentSong && (
        <motion.button
          initial={{ opacity: 0.2 }}
          whileHover={{
            scale: 1.1,
            opacity: 1,
            transition: { duration: 0.2 },
          }}
          whileTap={{ scale: 1 }}
          onTap={() => setOpenReportModal(true)}
          className="report-btn">
          <img src={darkMode ? ReportIconDark : ReportIconLight}></img>
        </motion.button>
      )}

      <motion.button
        animate={{ rotate: isPlaying ? 360 : 0 }}
        transition={{ repeat: isPlaying ? Infinity : 0, duration: 2 }}
        className="circle-player"
        onTap={() => setIsPlaying(!isPlaying)}>
        <img
          src={
            currentSong
              ? currentSong.coverArt
                ? currentSong.coverArt
                : "https://images.unsplash.com/photo-1545486332-9e0999c535b2?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=634&q=80"
              : "https://images.unsplash.com/photo-1545486332-9e0999c535b2?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=634&q=80"
          }></img>
        <div className="white-circle"></div>
      </motion.button>
      <div className="controls">
        <motion.button
          initial={{ opacity: 0.2 }}
          whileHover={{
            scale: 1.1,
            opacity: 1,
            transition: { duration: 0.2 },
          }}
          whileTap={{ scale: 1 }}
          onTap={prevSong}>
          <img src={darkMode ? PrevBtnDark : PrevBtnLight}></img>
        </motion.button>
        <motion.button
          initial={{ opacity: 0.2 }}
          whileHover={{
            scale: 1.1,
            opacity: 1,
            transition: { duration: 0.2 },
          }}
          whileTap={{ scale: 1 }}
          onTap={nextSong}>
          <img src={darkMode ? forwardBtnDark : forwardBtnLight}></img>
        </motion.button>
      </div>
      <div
        ref={progressBarRef}
        className="progress-wrapper"
        onClick={currentSong && handleAudioTimeSeek}>
        <motion.div
          className="progress-bar-main"
          animate={{
            width: `${(currentAudioTime / audioDuration) * 100}%`,
          }}></motion.div>
      </div>
      <div className="actions">
        {" "}
        <motion.button
          initial={{ opacity: 0.2 }}
          whileHover={{
            scale: 1.1,
            opacity: 1,
            transition: { duration: 0.2 },
          }}
          whileTap={{ scale: 1 }}
          animate={{ opacity: saved ? 1 : 0.2 }}
          onTap={() => handleSaveSong()}
          className="beat-action">
          <img src={darkMode ? HeartIconDark : HeartIconLight}></img>
        </motion.button>
        {/*<motion.button*/}
        {/*  initial={{ opacity: 0.2 }}*/}
        {/*  whileHover={{*/}
        {/*    scale: 1.1,*/}
        {/*    opacity: 1,*/}
        {/*    transition: { duration: 0.2 },*/}
        {/*  }}*/}
        {/*  whileTap={{ scale: 1 }}*/}
        {/*  onTap={() => setOpenExportModal(true)}*/}
        {/*  className="beat-action">*/}
        {/*  <img height={20} src={darkMode ? DownloadIconLight : DownloadIconDark}></img>*/}
        {/*</motion.button>*/}
        <motion.button
          initial={{ opacity: 0.2 }}
          whileHover={{
            scale: 1.1,
            opacity: 1,
            transition: { duration: 0.2 },
          }}
          whileTap={{ scale: 1 }}
          onTap={() => shareSongCopyLink(currentSong.id)}
          className="beat-action">
          <img src={darkMode ? ShareIconLight : ShareIconDark}></img>
        </motion.button>
        <motion.button
          initial={{ opacity: 0.2 }}
          whileHover={{
            scale: 1.1,
            opacity: 1,
            transition: { duration: 0.2 },
          }}
          whileTap={{ scale: 1 }}
          onTap={() => addPlayList(currentSong)}
          className="beat-action">
          <img height={40} width={40} src={darkMode ? WhishlistGray : WhishlistWhite}></img>
        </motion.button>
      </div>
    </div>
  );
};

export default PlayerMobile;
