import React, { useState } from 'react';
import API from '../../api';
import ProducerSignupStep1 from './ProducerSignupStep1';
import ProducerSignupStep2 from './ProducerSignupStep2';
import ProducerSignupSuccess from './ProducerSignupSuccess';

const ProducerSignup = () => {
  const [loading, setLoading] = useState(false);
  const [submissionError, setSubmissionError] = useState('');

  const [formData, setFormData] = useState({
    nickname: '',
    firstname: '',
    lastname: '',
    email: '',
    password: '',
  });
  const [page, setPage] = useState(1);

  return (
    <div className="producerSignup">
      {page !== 3 && (
        <div className="producerSignup__title">
          Become a LOUD producer
        </div>
      )}

      <div className="error-message">{submissionError}</div>
      {page === 1 && (
        <ProducerSignupStep1
          setPage={setPage}
          setSubmissionError={setSubmissionError}
          formData={formData}
          setFormData={setFormData}
        />
      )}
      {page === 2 && (
        <ProducerSignupStep2
          setPage={setPage}
          setSubmissionError={setSubmissionError}
          formData={formData}
          setFormData={setFormData}
        />
      )}
      {page === 3 && <ProducerSignupSuccess />}
    </div>
  );
};

export default ProducerSignup;
