import React, { useState, useEffect, useCallback, useRef } from "react";
import closeButton from "../../images/redClose.png";
import API from "../../api";

const SecretService = ({ setIsOpen, setFormData, formData, setPage }) => {
  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState("");
  const [step, setStep] = useState(1);
  const [submissionError, setSubmissionError] = useState("");
  const inputRef = useRef();

  const checkSecretServiceCode = useCallback(() => {
    setSubmissionError("");
    setLoading(true);
    API.checkSecretCode({ code })
      .then((data) => {
        setFormData((prev) => ({
          ...prev,
          plan: "secret",
          secretCode: data.code,
        }));
        setPage(2);
      })
      .catch((err) => {
        setSubmissionError(err.data.message);
        setLoading(false);
        setCode("");
      });
  }, [code, setIsOpen, setFormData]);

  const handleChange = (e) => {
    if (code.length === 6) return;
    setCode(e.target.value);
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef]);

  useEffect(() => {
    if (code && code.length === 6) {
      checkSecretServiceCode();
    }
  }, [code, checkSecretServiceCode]);

  const handleDisplayClick = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const handleNoSecretCode = (e) => {
    // e.stopPropagation();
    setFormData((prev) => ({ ...prev, plan: "sound" }));
    setStep(2);
  };

  const setPlan = (plan) => {
    setFormData((prev) => ({ ...prev, plan }));
  };

  return (
    <div className="secret-service">
      <div className="secret-service__top">
        <div className="title">
          <h1>Secre...</h1>
          <img
            onClick={() => setIsOpen(false)}
            src={closeButton}
            alt="close Button"
            className="close"
          />
        </div>
      </div>

      {step === 1 && (
        <React.Fragment>
          <div className="secret-service__content">
            <h3>Enter Secret Service Code</h3>
          </div>
          {loading && (
            <div className="secret-service__loading">Please wait...</div>
          )}
          {submissionError && (
            <div className="secret-service__error">
              &#9888; {submissionError}
            </div>
          )}
          <div className="secret-service__display" onClick={handleDisplayClick}>
            {code[0] || 0}
            {code[1] || 0}
            {code[2] || 0} - {code[3] || 0}
            {code[4] || 0}
            {code[5] || 0}
            <input
              // hidden
              value={code}
              className="display-input"
              ref={inputRef}
              type="text"
              onChange={handleChange}
            />
          </div>
          <div className="secret-service__button" onClick={handleNoSecretCode}>
            I don't have a code
          </div>
        </React.Fragment>
      )}
      {step === 2 && (
        <React.Fragment>
          <div className="secret-service__content no-secret">
            <h4>You need a secret code to join the secret service</h4>
          </div>
          <div className="secret-service__other-plans">
            <h3>Select a different membership</h3>
            <div className="plan-container">
              <div
                className={formData.plan === "sound" ? "plan active" : "plan"}
                onClick={() => setPlan("sound")}
              >
                $9/mo SOUND membership {"   "}
                {formData.plan === "sound" && <span>&#10003;</span>}
              </div>
              <div
                className={formData.plan === "trial" ? "plan active" : "plan"}
                onClick={() => setPlan("trial")}
              >
                FREE month membership {"   "}
                {formData.plan === "trial" && <span>&#10003;</span>}
              </div>
            </div>
          </div>
          <div className="secret-service__button" onClick={() => setPage(2)}>
            Purchase
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

export default SecretService;
