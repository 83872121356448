import React, { useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import PrivateRoute from "./utils/PrivateRoute";
import SoundAppPage from "./pages/SoundAppPage";
import SigninPage from "./pages/SigninPage";
import SignupPage from "./pages/SignupPage";
import "./sass/App.scss";
import { loadUser } from "./actions";
import {useDispatch, useSelector} from "react-redux";
import HomePage from "./pages/HomePage";
import MembershipInfoPage from "./pages/MembershipInfoPage";
import ProducerSigninPage from "./pages/ProducerSigninPage";
import ProducerProfilePage from "./pages/ProducerProfilePage";
import UploadBeatPage from "./pages/UploadBeatPage";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ProducerSignupPage from "./pages/ProducerSignupPage";
import SignupWithReferralPage from "./pages/SignupWithReferralPage";
import ProducerConfirmEmailPage from "./pages/ProducerConfirmEmailPage";
import ForgotPasswordPage from "./pages/ForgotPasswordPage";
import ResetPasswordPage from "./pages/ResetPasswordPage";
import ProducerBeatsPage from "./pages/ProducerBeatsPage";
import AdminSignupPage from "./pages/AdminSignupPage";
import AdminSigninPage from "./pages/AdminSigninPage";
import AdminSummary from "./components/Admin";
import AdminProducers from "./components/Admin/AdminProducers";
import AdminArtistes from "./components/Admin/AdminArtistes";
import AdminBeats from "./components/Admin/AdminBeats";
import AdminSongs from "./components/Admin/AdminSongs";
import AdminSecretService from "./components/Admin/AdminSecretService";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import SupportPage from "./pages/SupportPage";
import SoundRedesignPage from "./pages/SoundRedesignPage";
import Rap from "./pages/rap";
import World from "./pages/world";
import TagManager from "react-gtm-module";
import SignupWithArtisteReferral from "./pages/SignupArtisteReferral";
import ProducerChallengePage from "./pages/ProducerChallenge";
import PartnerSignupPage from "./pages/PartnerSignupPage";
import AdminPartners from "./components/Admin/AdminPartners";
import PaymentUpdate from "./pages/PaymentUpdate";
import UpdateCardPaymentForm from './components/UpdateCardPayment/UpdateCardPayment'
import OneBeatShared from "./pages/OneBeatShared";
import UploadSongPage from "./pages/UploadSongPage";
import ProducerSongsPage from "./pages/ProducerSongsPage";
import StreamerSigninPage from "./pages/StreamerSigninPage";
import StreamSignupPage from "./pages/StreamSignupPage";
import StreamPage from "./pages/StreamPage";
import SignupWithUserReferral from "./pages/SignupUserReferral";
import StreamerSignupWithReferralPage from "./pages/StreamerSignupWithReferralPage";

function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loadUser());
  }, [dispatch]);

  const tagManagerArgs = {
    gtmId: "GTM-NLTTP4X",
  };
  TagManager.initialize(tagManagerArgs);

  useEffect(() => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  }, []);

  return (
    <div className="App">
      <ToastContainer position="top-center" hideProgressBar autoClose={3000} />
      <Router>
        <Switch>
          <Route path="/" exact component={HomePage} />
          <Route path="/signin" exact component={SigninPage} />
          <Route path="/signup" exact component={SignupPage} />
          <Route path="/privacy-policy" exact component={PrivacyPolicy} />
          <Route path="/support" exact component={SupportPage} />
          <Route path="/rap" exact component={Rap} />
          <Route path="/world" exact component={World} />
          <Route
            path="/signup/:producer"
            exact
            component={SignupWithReferralPage}
          />
          <Route
            path="/stream/signup/:producer"
            exact
            component={StreamerSignupWithReferralPage}
          />
          <Route
            path="/signup/artiste/:id"
            exact
            component={SignupWithArtisteReferral}
          />
          <Route
            path="/signup/listener/:id"
            exact
            component={SignupWithUserReferral}
          />
          <Route path="/producer/signin" exact component={ProducerSigninPage} />
          <Route path="/stream/signin" exact component={StreamerSigninPage} />
          <Route path="/admin/signup" exact component={AdminSignupPage} />
          <Route path="/admin/signin" exact component={AdminSigninPage} />
          <Route path="/admin" exact component={AdminSummary} />
          <Route path="/admin/producers" exact component={AdminProducers} />
          <Route path="/admin/artistes" exact component={AdminArtistes} />
          <Route path="/admin/beats" exact component={AdminBeats} />
          <Route path="/admin/songs" exact component={AdminSongs} />
          <Route
            path="/producer-challenge/signup"
            exact
            component={ProducerChallengePage}
          />
          <Route
            path="/admin/secret-service"
            exact
            component={AdminSecretService}
          />
          <Route path="/admin/partners" exact component={AdminPartners} />
          <Route
            path="/forgot-password/:user"
            exact
            component={ForgotPasswordPage}
          />
          <Route
            path="/reset-password/:user/:token"
            exact
            component={ResetPasswordPage}
          />
          <Route
            path="/producer/confirm-email/:token"
            exact
            component={ProducerConfirmEmailPage}
          />
          <Route path="/producer/signup" exact component={ProducerSignupPage} />
          <Route path="/stream/signup" exact component={StreamSignupPage} />
          <PrivateRoute path="/stream" exact component={StreamPage} />
          <Route path="/stream/:soundId" exact component={StreamPage} />

          <PrivateRoute path="/sound-legacy" exact component={SoundAppPage} />
          <PrivateRoute path="/sound" exact component={SoundRedesignPage} />
          <Route path="/sound/:soundId" exact component={SoundRedesignPage} />
          <Route path="/new-song" exact component={UploadBeatPage} />
          <Route path="/add-song" exact component={UploadSongPage} />
          <Route path="/producer" exact component={ProducerProfilePage} />
          <Route path="/producer/beats" exact component={ProducerBeatsPage} />
          <Route path="/producer/songs" exact component={ProducerSongsPage} />
          <Route path="/share/:songId" exact component={OneBeatShared} />
          <PrivateRoute path="/payment-update" exact component={PaymentUpdate} />
          <PrivateRoute path="/update-card" exact component={UpdateCardPaymentForm} />
          <PrivateRoute
            path="/manage-subscription"
            exact
            component={MembershipInfoPage}
          />
          <Route path="/:partner" exact component={PartnerSignupPage} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
