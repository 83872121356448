import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useOptions } from "../../utils/helpers";
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from "@stripe/react-stripe-js";
import Tilt from "react-parallax-tilt";
import { toast } from "react-toastify";
import Blob from "../Canvas/Blob";
import API from "../../api";
// Assets
import visaIcon from "../../images/visaIcon.svg";
import chipIcon from "../../images/chipicon.png";

const toastOptions = {
  position: "top-left",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

const Step1 = ({ formData, setFormData, setStep }) => {
  const [loading, setLoading] = useState(false);
  const [[manualTiltAngleX, manualTiltAngleY], setManualTiltAngle] = useState([
    0, 0,
  ]);
  const history = useHistory();
  const stripe = useStripe();
  const elements = useElements();
  const options = useOptions();
  const stripeOptions = {
    ...options,
    style: {
      base: {
        fontSize: "16px",
        fontFamily: "jost",
        color: "#000",
        "::placeholder": {
          color: "#000",
        },
        invalid: {
          color: "#9e2146",
        },
      },
    },
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "cardName") {
      setManualTiltAngle([35, 17]);
    }
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const goToNextPage = async () => {
    const { cardName } = formData;
    if (!stripe || !elements || !cardName) {
      toast.error("All fields are required", toastOptions);
      return;
    }

    setLoading(true);
    const cardElement = elements.getElement(CardNumberElement);
    const { secret } = await API.createPaymentIntent({
      amount: 5000,
    });
    stripe
      .confirmCardPayment(secret, {
        payment_method: {
          card: cardElement,
          billing_details: {
            name: cardName,
          },
        },
      })
      .then((result) => {
        if (result.error) {
          setLoading(false);
          toast.error(result.error.message);
        }
        if (result.paymentIntent) {
          setStep(2);
        }
      });
  };

  return (
    <React.Fragment>
      <div className="signup__step1">
        <Blob></Blob>
        <div className="signup__step1-wrapper">
          <h1>PAYMENT DETAILS</h1>
          <h3>
            <strong>$50 CAD</strong> PRODUCER CHALLENGE REGISTRATION FEE
          </h3>
          <input
            className="input-text"
            type="text"
            value={formData.cardName}
            onChange={handleChange}
            name="cardName"
            placeholder="NAME ON CARD"
          />
          <CardNumberElement
            options={stripeOptions}
            className="input-text"
            onChange={(e) => setManualTiltAngle([-22, 0])}
          />
          <div className="card-expiry-cvv double-input">
            <CardExpiryElement
              options={stripeOptions}
              className="input-text"
              onChange={(event) => {
                setManualTiltAngle([0, 30]);
              }}
            />
            <CardCvcElement options={stripeOptions} className="input-text" />
          </div>
          <div className="signup__actions">
            <button
              onClick={() => history.goBack()}
              className="white-btn ns black"
            >
              Back
            </button>
            <button
              disabled={loading}
              onClick={goToNextPage}
              className="white-btn black"
              onMouseEnter={() => setManualTiltAngle([0, 0])}
            >
              {loading ? "Processing..." : "Continue"}
            </button>
          </div>
        </div>
        <div className="signup__step1-card-wrapper">
          <Tilt
            tiltAngleXManual={manualTiltAngleX}
            tiltAngleYManual={manualTiltAngleY}
            onLeave={() => setManualTiltAngle([0, 0])}
          >
            <div className="card-group">
              <div className="card">
                <div className="logo">
                  <img src={visaIcon} alt="Visa" />
                </div>
                <div className="chip">
                  <img src={chipIcon} alt="chip" />
                </div>
                <div className="number">1234 5678 9012 3456</div>
                <div className="name">{formData.cardName}</div>
                <div className="to">06/23</div>
                <div className="ring"></div>
              </div>
            </div>
          </Tilt>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Step1;
