import React, { useState, useEffect } from "react";
import API from "../../api";
import ExportSuccess from "./ExportSuccess";
import ExportWigdet from "./ExportWidget";

const ExportModal = ({ user, setOpen, darkMode }) => {
  const [savedSongs, setSavedSongs] = useState();
  const [loading, setLoading] = useState(false);
  const [exportSuccess, setExportSuccess] = useState(false);
  const [selectedBeats, setSelectedBeats] = useState([]);

  useEffect(() => {
    setLoading(true);
    API.fetchSongs(1, "saved")
      .then((data) => {
        setSavedSongs(data.filter((song) => !!song));
        setLoading(false);
      })
      .catch((err) => {
        console.log(err.reponse);
        setLoading(false);
      });
  }, [user]);

  const exportSongs = () => {
    if (selectedBeats.length < 1) return;
    setLoading(true);
    API.exportSongs({ songs: selectedBeats })
      .then(() => {
        setExportSuccess(true);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    document.body.setAttribute("style", "overflow: hidden");
    return () => {
      document.body.setAttribute("style", "overflow: scroll");
    };
  }, []);

  useEffect(() => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  }, []);

  return (
    <div className="export-modal">
      <div className="export-container">
        {exportSuccess ? (
          <ExportSuccess
            darkMode={darkMode}
            setOpen={setOpen}
            exportSongs={exportSongs}
            user={user}
            loading={loading}
          />
        ) : (
          <ExportWigdet
            darkMode={darkMode}
            setSavedSongs={setSavedSongs}
            songs={savedSongs}
            setOpen={setOpen}
            exportSongs={exportSongs}
            user={user}
            loading={loading}
            selectedBeats={selectedBeats}
            setSelectedBeats={setSelectedBeats}
          />
        )}
      </div>
    </div>
  );
};

export default ExportModal;
