import React, { useRef, useEffect, useCallback } from "react";
import ReactDOM from "react-dom";

function Modal({ setIsOpen, children }) {
  const contentRef = useRef();
  const handleClickOutside = useCallback(
    (e) => {
      if (contentRef.current && !contentRef.current.contains(e.target))
        if (!e.target.classList.contains("secret-service__button")) {
          setIsOpen(false);
        }
    },
    [setIsOpen]
  );

  useEffect(() => {
    document.body.setAttribute("style", "overflow : hidden");
    if (contentRef.current) {
      document.addEventListener("click", handleClickOutside);
    }
    return () => {
      document.body.setAttribute("style", "overflow : auto");
      document.removeEventListener("click", handleClickOutside);
    };
  }, [handleClickOutside]);

  return ReactDOM.createPortal(
    <div className="modal">
      {/* <div
        onClick={() => setIsOpen(false)}
        className="close-button"
      ></div> */}
      <div ref={contentRef} className="content">
        {children}
      </div>
    </div>,
    document.getElementById("modal")
  );
}

export default Modal;
