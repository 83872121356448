import React, { forwardRef } from "react";

function SongsGrid({ columns, children }, ref) {
    return (
        <div
            ref={ref}
            style={{
                justifyContent: columns === 3 ? "space-between" : "space-around"
            }}
            className="songs__grid"
        >
            {children}
        </div>
    );
}
const forwardedGrid = forwardRef(SongsGrid);

export default forwardedGrid;
