import { baseUri } from "./index";
import axios from "axios";

const admin = {
  checkPartnerName: async (name) => {
    try {
      const response = await axios.get(
        `${baseUri}/admins/partners/get-by-name?name=${name}`
      );
      return response.data;
    } catch (err) {
      throw err.response;
    }
  },
  getPartners: async () => {
    try {
      const response = await axios.get(`${baseUri}/admins/partners`);
      return response.data;
    } catch (err) {
      throw err.response;
    }
  },
  createPartner: async (data) => {
    try {
      const response = await axios.post(`${baseUri}/admins/partners`, {
        ...data,
      });
      return response.data;
    } catch (err) {
      throw err.response;
    }
  },

  getProducerEarnings: async ({ month, year }) => {
    let queryMonth = parseInt(month, 10);
    let queryYear = parseInt(year, 10);

    // If the selected month is December, we need to adjust the query parameters
    // to match how the data is stored in the database (month = -1, year = following year).
    if (queryMonth === 11 && queryYear !== 2021) {
      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();

      if (currentYear == queryYear) {
      } else if (queryYear < currentYear) {
        queryYear = queryYear + 1;
        queryMonth = -1;
      }
    }
    try {
      const response = await axios.get(
        `${baseUri}/admins/producer_earnings?month=${queryMonth}&year=${queryYear}`
      );
      return response.data.earnings;
    } catch (err) {
      throw err.response;
    }
  },

  updateProducerEarnings: async (id, paid) => {
    try {
      const response = await axios.put(
        `${baseUri}/admins/producer_earnings/${id}`,
        { paid }
      );
      return response.data;
    } catch (err) {
      throw err.response;
    }
  },

  producerExport: async () => {
    try {
      const response = await axios.get(`${baseUri}/admins/producer_export`);
      return response.data;
    } catch (err) {
      throw err.response;
    }
  },

};


export default admin;
