import React, { useState, useEffect } from 'react';
import SignupStep1 from './SignupStep1';
import SignupStep2 from './SignupStep2';
import { Elements } from '@stripe/react-stripe-js';
import API from '../../api';
import SignupSuccess from './SignupSuccess';
import setAxiosAuthToken from '../../utils/setAxiosAuthToken';
import { getQueryVariable } from '../../utils/helpers';
import Blob from '../Canvas/Blob';
import { MultiStepForm, Step } from 'react-multi-form';
import { prices, stripePromise, stripeOptions } from '../../services/stripe';
import { useRouteMatch, useLocation, useHistory } from 'react-router-dom';
import ConfirmEmailStep from './ConfirmEmailStep';

const Signup = ({
  isReferred,
  producerName,
  refArtisteId,
  refUserId,
  partnerName,
  partnerData,
  plan,
}) => {
  const [page, setPage] = useState(1);
  const [eventPage, setEventPage] = useState(false);
  const [subscriptionPrice, setSubscriptionPrice] = useState(prices[9]);
  const [tempUserId, setTempUserId] = useState('');
  const location = useLocation();
  const history = useHistory();

  const [formData, setFormData] = useState({
    plan: plan,
    cardName: '',
    paymentMethod: '',
    firstname: '',
    lastname: '',
    password: '',
    email: '',
    phone: '',
    secretCode: '',
    isReferred: false,
    producer: '',
    refArtisteId: '',
    refUserId: '',
    partnerName,
    userType: plan === 'sound' ? 'artiste' : 'listener',
  });

  const [submissionError, setSubmissionError] = useState('');
  const [loading, setLoading] = useState(false);

  const match = useRouteMatch('/:userType');
  let type = match ? match.params.userType : 'artistes'; // default to 'artistes'
  if (type !== 'artistes' && type !== 'stream') {
    type = 'artistes';
  } else if (type === 'stream') {
    type = 'users';
  }

  const signupUser = (customerId, subscriptionId) => {
    API.signUp(
      {
        stripeCustomerId: customerId,
        ...formData,
        subscriptionId,
      },
      type
    )
      .then(async (res) => {
        const sharedSongId = localStorage.getItem('sharedSongId');
        history.push(window.location.pathname);
        if (sharedSongId) {
          setAxiosAuthToken(res.token);
          await API.saveSong(sharedSongId, 'save');
        }

        if (eventPage) {
          window.location.href = 'https://loudarmysounds.netlify.app/verified';
        } else {
          setPage(4);
        }
      })
      .catch((err) => {
        console.log(err);
        history.push(window.location.pathname);
        setPage(1);
        setSubmissionError(err.data.message);
      });
  };

  useEffect(() => {
    if (isReferred && producerName) {
      setFormData((prev) => ({
        ...prev,
        isReferred: true,
        producer: producerName,
      }));
    }
    if (refArtisteId) {
      setSubscriptionPrice(prices[8]);
      setFormData((prev) => ({ ...prev, refArtisteId }));
    }
    if (refUserId) {
      setSubscriptionPrice(prices[8]);
      setFormData((prev) => ({ ...prev, refUserId }));
    }
    if (partnerName) {
      setFormData((prev) => ({ ...prev, plan: 'partner' }));
    }
  }, [isReferred, producerName, refArtisteId, refUserId, partnerName]);

  useEffect(() => {
    setEventPage(getQueryVariable('event'));
  }, []);

  useEffect(() => {
    const search = new URLSearchParams(location.search);
    const tempUserId = search.get('tempUserId');
    if (tempUserId) {
      setTempUserId(tempUserId);
      setPage(2);
    } else {
      setTempUserId(null);
    }
  }, [location]);

  return (
    <div className="signup">
      <div style={{ marginBottom: '40px', zIndex: '100', marginTop: '70px' }}>
        <MultiStepForm activeStep={page} accentColor={'white'}>
          <Step label="PAYMENT"></Step>
          <Step label="EMAIL CONFIRMATION"></Step>
          <Step label="SET UP"></Step>
          <Step label="DONE"></Step>
        </MultiStepForm>
      </div>
      {eventPage && <Blob></Blob>}
      {page === 1 && (
        <Elements stripe={stripePromise} options={stripeOptions}>
          <SignupStep1
            setPage={setPage}
            formData={formData}
            setFormData={setFormData}
            setSubmissionError={setSubmissionError}
            eventPage={eventPage}
            partnerName={partnerName}
            partnerData={partnerData}
          />
        </Elements>
      )}
      {page === 2 && (
        <ConfirmEmailStep
          formData={formData}
          setFormData={setFormData}
          setPage={setPage}
          tempUserId={tempUserId}
        />
      )}
      {page === 3 && (
        <Elements stripe={stripePromise} options={stripeOptions}>
          <SignupStep2
            setPage={setPage}
            formData={formData}
            setFormData={setFormData}
            signupUser={signupUser}
            subscriptionPrice={subscriptionPrice}
            setSubmissionError={setSubmissionError}
          />
        </Elements>
      )}
      {page === 4 && <SignupSuccess formData={formData} />}
    </div>
  );
};

export default Signup;
