import React, { useEffect } from "react";
import SoundApp from "../components/soundApp";
import { useSelector } from "react-redux";
import { canUseSoundApp } from "../utils/userRIghts";

const SoundAppPage = ({ history }) => {
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    if (user && user.userType !== "artiste") {
      history.push("/");
    }
    if (user && !canUseSoundApp(user)) history.push("/manage-subscription");
  }, [user, history]);

  return (
    <React.Fragment>
      {user && user.userType === "artiste" && <SoundApp />}
    </React.Fragment>
  );
};

export default SoundAppPage;
