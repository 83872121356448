const handleIntesection = (element, callBack) => {
  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        callBack();
        observer.unobserve(element);
      }
    });
  });
  observer.observe(element);
};

export default handleIntesection;
