import React, { useState } from "react";
import API from "../../api";
import setAxiosAuthToken from "../../utils/setAxiosAuthToken";
import { Link } from "react-router-dom";
import Fade from "react-reveal/Fade";
const ProducerSignin = ({ onSigninSuccess }) => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [submissionError, setSubmissionError] = useState("");
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { email, password } = formData;
    setSubmissionError("");
    if (!email || !password) {
      setSubmissionError("All fields are required");
      return;
    }

    setLoading(true);
    API.signIn({ email: email.trim().toLowerCase(), password }, "admins")
      .then((res) => {
        localStorage.setItem("auth-token", res.token);
        setAxiosAuthToken(res.token);
        onSigninSuccess(res.user);
      })
      .catch((err) => {
        setSubmissionError(err.data.message);
        setLoading(false);
      });
  };

  return (
    <div className="signin">
      <Fade bottom>
        <div className="signin__card">
          <div className="signin__title title">Admin Log In</div>
          <div className="error-message">{submissionError}</div>
          <form onSubmit={handleSubmit} className="signin__form">
            <input
              type="text"
              className="signin-input"
              value={formData.email}
              name="email"
              placeholder="Your Email"
              onChange={handleChange}
            />
            <input
              type="password"
              className="signin-input"
              name="password"
              value={formData.password}
              placeholder="Password"
              onChange={handleChange}
            />
            <div className="double-input">
              <Link className="white-btn ns" to="/admin/signup">
                Sign up
              </Link>
              <button disabled={loading} className="signin__button">
                {loading ? "Loading..." : "Login"}
              </button>
            </div>
          </form>
        </div>
      </Fade>
      {/* <div className="signin__forgot">
        <Link to="/forgot-password/producer">Forgot your password?</Link>
      </div> */}
    </div>
  );
};

export default ProducerSignin;
