import { useState } from "react";
import API from "../../api";
import AdminLayout from "./AdminLayout";

const formatPlan = (plan) => {
  return plan.indexOf("_") === -1 ? plan : plan.split("_").join(" ");
};

function AdminSecretService() {
  const [formData, setFormData] = useState({
    plan: "",
    quantity: 1,
  });

  const [loading, setLoading] = useState(false);
  const [submissionError, setSubmissionError] = useState("");
  const [secretCodes, setSecretCodes] = useState();

  const createCodes = (e) => {
    e.preventDefault();
    setSubmissionError("");
    if (!formData.plan) {
      setSubmissionError("Please Select Secret Service Plan");
      return;
    }
    setLoading(true);
    API.adminCreateSecretServiceCodes(formData)
      .then((data) => {
        setSecretCodes(data.secretCodes);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setSubmissionError(err.data.message);
      });
  };
  const handleChange = (e) => {
    const { value, name } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };
  return (
    <AdminLayout activeNav="secret">
      <div className="secret">
        <div className="title">Create Secret Service Code</div>
        <div className="error-message">{submissionError}</div>
        <form className="secret__form">
          <select
            value={formData.plan}
            onChange={handleChange}
            name="plan"
            className="input-select"
          >
            <option value="">Select a secret service</option>
            <option value="ARTIST_PARTNER">
              ARTIST PARTNER 6 months free account
            </option>
            <option value="ADMIN">ADMIN 1 year free account</option>
            <option value="LOUD_BOX">Loud Box Account $30/Mo</option>
          </select>
          <select
            onChange={handleChange}
            name="quantity"
            value={formData.quantity}
            className="input-select"
          >
            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item) => (
              <option key={item} value={item}>
                {item}
              </option>
            ))}
          </select>
          <button
            disabled={loading}
            onClick={createCodes}
            className="white-btn"
            type="button"
          >
            {loading ? "Please wait.." : "Create"}
          </button>
        </form>
        {secretCodes && secretCodes.length > 0 && (
          <div className="secret__codes">
            <div className="headings">
              <div>Code</div>
              <div>Plan</div>
            </div>
            {secretCodes.map((code) => (
              <div key={code.code} className="codes">
                <div>{code.code}</div>
                <div>{formatPlan(code.plan)}</div>
              </div>
            ))}
          </div>
        )}
      </div>
    </AdminLayout>
  );
}

export default AdminSecretService;
