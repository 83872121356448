import { LOAD_USER, AUTH_SUCCESS, AUTH_ERROR } from "./types";

export const loadUser = () => ({
  type: LOAD_USER,
});

export const authSuccess = (user) => ({
  type: AUTH_SUCCESS,
  payload: user,
});

export const authError = (user) => ({
  type: AUTH_ERROR,
});
