import React, { useState } from "react";
import closeButton from "../../images/exitButton.png";
import API from "../../api";

const CreatePartner = ({ setIsOpen, addPartner }) => {
  const [loading, setLoading] = useState(false);
  const [submissionError, setSubmissionError] = useState("");
  const [formData, setFormData] = useState({
    name: "",
    maxSignups: "",
    trialDays: "",
  });

  const createPartner = () => {
    const { name, maxSignups, trialDays } = formData;
    if (!name || !maxSignups || !trialDays) {
      setSubmissionError("Please fill out all fields");
      return;
    }
    setLoading(true);
    API.createPartner({ name: name.toLowerCase(), maxSignups, trialDays })
      .then((res) => {
        addPartner(res.partner);
        setIsOpen(false);
      })
      .catch((err) => {
        setLoading(false);
        setSubmissionError(err.data.message);
      });
  };

  const handeChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <div className="loud-window fixed">
      <div className="top">
        <div className="title">
          <h1>Create</h1>
          <img
            onClick={() => {
              setIsOpen(false);
            }}
            src={closeButton}
            alt="close Button"
            className="close"
          />
        </div>
      </div>
      <React.Fragment>
        <div className="error-message">{submissionError}</div>
        <br />

        <input
          value={formData.name}
          type="text"
          className="input-text"
          placeholder="Name"
          name="name"
          onChange={handeChange}
        />
        <input
          type="number"
          value={formData.trialDays}
          className="input-text"
          placeholder="Trial days"
          name="trialDays"
          onChange={handeChange}
        />
        <input
          value={formData.maxSignups}
          type="number"
          className="input-text"
          placeholder="Max Signups"
          name="maxSignups"
          onChange={handeChange}
        />
        <br />
        <br />
        <button
          disabled={loading}
          onClick={createPartner}
          className="window-btn"
        >
          {loading ? "Please Wait..." : "Create"}
        </button>
      </React.Fragment>
    </div>
  );
};

export default CreatePartner;
