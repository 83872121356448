import React, { useState } from "react";
import API from "../../api";
import setAxiosAuthToken from "../../utils/setAxiosAuthToken";
import { Link } from "react-router-dom";
import Fade from "react-reveal/Fade";

const Signin = ({ onSigninSuccess ,userType}) => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const [submissionError, setSubmissionError] = useState("");
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { email, password } = formData;
    if (!email || !password) {
      setSubmissionError("All fields are required");
      return;
    }
    let formatUserType;
    if (userType === 'listener'){
      formatUserType = 'users';
    }else {
      formatUserType = userType;
    }
    setLoading(true);
    API.signIn({ email: email.trim().toLowerCase(), password }, formatUserType)
      .then((res) => {
        localStorage.setItem("auth-token", res.token);
        localStorage.setItem("user", res.user);
        setAxiosAuthToken(res.token);
        onSigninSuccess(res.user);
      })
      .catch((err) => {
        console.log(err)
        setSubmissionError(err.data.message);
        setLoading(false);
      });
  };

  return (
    <div className="signin">
      <Fade bottom>
        <div className="signin__card">
          <div className="signin__title title">Log In</div>
          <div className="error-message">{submissionError}</div>
          <form onSubmit={handleSubmit} className="signin__form">
            <input
              type="text"
              className="signin-input"
              value={formData.email}
              name="email"
              placeholder="Email Address"
              onChange={handleChange}
            />
            <input
              type="password"
              className="signin-input"
              name="password"
              value={formData.password}
              placeholder="Password"
              onChange={handleChange}
            />
            <div className="double-input">
              {userType === 'listener' ? (
                      <Link className="white-btn ns" to="/stream/signup">
                Sign up
              </Link>
              ) : (
                  <Link className="white-btn ns" to="/signup">
                    Sign up
                  </Link>
              )}
              <button disabled={loading} className="signin__button ">
                {loading ? "Loading..." : "Login"}
              </button>
            </div>
          </form>
          <div className="signin__forgot">
            <Link to={`/forgot-password/${userType}`}>Reset my password</Link>
          </div>
        </div>
      </Fade>
    </div>
  );
};

export default Signin;
