import React, { useState, useEffect } from "react";
import API from "../../api";
import { motion } from "framer-motion";

// Assets
import CloseBtnDark from "../../images/closeDark.svg";
import CloseBtnWhite from "../../images/closeWhite.svg";
import CheckedIcon from "../../images/checked.svg";

const reportTypes = ["Not Music", "Stolen Beat", "Not Playing", "Other"];

const ReportSong = ({ setOpenReportModal, currentSong, darkMode }) => {
  //need to create a copy of the current song because current song might change while report window is still opened
  let song;
  if (currentSong) {
    song = { ...currentSong };
  }
  const [formData, setFormData] = useState({
    type: "",
    song,
    description: "",
  });
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleTextAreaChange = (e) => {
    setFormData((prev) => ({ ...formData, description: e.target.value }));
  };

  const reportSong = () => {
    setLoading(true);
    API.reportSong(formData)
      .then(() => setSuccess(true))
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  useEffect(() => {
    document.body.setAttribute("style", "overflow: hidden");
    return () => {
      document.body.setAttribute("style", "overflow: scroll");
    };
  }, []);

  useEffect(() => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  }, []);

  return (
    <div className="report-modal">
      <div className="report-container">
        <div className="top">
          <div className="title">
            <h1>Report</h1>
            <motion.button
              initial={{ opacity: 0.2 }}
              whileHover={{
                scale: 1.1,
                opacity: 1,
                transition: { duration: 0.2 },
              }}
              whileTap={{ scale: 1 }}
              onTap={() => setOpenReportModal(false)}>
              <img
                src={darkMode ? CloseBtnDark : CloseBtnWhite}
                alt="close Button"
              />
            </motion.button>
          </div>
        </div>
        {success ? (
          <React.Fragment>
            <div className="report-success-modal">
              <img className="success-image" src={CheckedIcon} />
              <div className="success-text">
                Your report has been submitted successfully <br /> <br></br>{" "}
                Thank you for making LOUD better
              </div>
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <div className="report-song__details">
              <div className="song-title">{song ? song.title : ""}</div>
              <div className="song-producer">
                {song && song.producer.nickname.length > 4
                  ? song.producer.nickname.substring(0, 4) + "..."
                  : song.producer.nickname}
              </div>
            </div>
            <div className="report-song__form">
              {reportTypes.map((type) => (
                <div
                  key={type}
                  className="report-type"
                  onClick={() =>
                    setFormData((prev) => ({ ...prev, description: "", type }))
                  }>
                  <input
                    id="checkmark"
                    type="checkbox"
                    checked={formData.type === type}
                  />
                  <span>{type}</span>
                </div>
              ))}

              <textarea
                onChange={handleTextAreaChange}
                placeholder="Additional info..."
                value={formData.description}
                rows="5"
                maxLength="150"></textarea>
            </div>

            <button
              disabled={
                loading ||
                !formData.type ||
                (formData.type === "Other" && !formData.description)
              }
              onClick={reportSong}
              className="window-btn">
              {loading ? "Please Wait..." : "Submit"}
            </button>
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

export default ReportSong;
