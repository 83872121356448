import React, { useState } from "react";
import checkedBeat from "../../images/checked.png";
import uncheckedBeat from "../../images/unchecked.png";
import closeButton from "../../images/exitButton.png";

const ExportWigdet = ({
  songs,
  exportSongs,
  setSelectedBeats,
  selectedBeats,
  setOpen,
  setSavedSongs,
  loading,
}) => {
  const handleChange = (e, beat) => {
    const { checked } = e.target;
    if (selectedBeats.length === 4 && checked) return;
    if (checked) {
      const beats = selectedBeats.slice();
      beats.push(beat);
      setSelectedBeats(beats);
    } else {
      const beats = selectedBeats.filter((item) => item._id !== beat._id);
      setSelectedBeats(beats);
    }
    setSavedSongs((prevSaved) =>
      prevSaved.map((item) =>
        item._id === beat._id
          ? {
              ...item,
              isSelected: checked,
            }
          : item
      )
    );
  };

  return (
    <div className="exportWidget">
      <div className="top">
        <div className="title">
          <h1>EXPORT</h1>
          <img
            onClick={() => setOpen(false)}
            src={closeButton}
            alt="close Button"
            className="close"
          />
        </div>
        <div className="subtitle">SAVED BEATS</div>
      </div>
      <div className="saved-songs">
        <div className="saved-songs_container">
          {loading ? (
            <div>Please Wait...</div>
          ) : (
            <React.Fragment>
              {songs &&
                songs.length > 0 &&
                songs.map((song, i) => (
                  <div key={i} className="song-container">
                    <label className="song-label" htmlFor={`song${song._id}`}>
                      <img
                        src={song.isSelected ? checkedBeat : uncheckedBeat}
                        alt="check-status"
                        className="selected-status"
                      />
                      <div className="song-details">
                        <div className="artist">{song.producer.nickname}</div>
                        <div className="song">{song.title}</div>
                      </div>
                    </label>
                    <input
                      onChange={(e) => handleChange(e, song)}
                      type="checkbox"
                      className="checkbox"
                      id={`song${song._id}`}
                      checked={song.isSelected ? song.isSelected : false}
                      hidden
                    />
                  </div>
                ))}
            </React.Fragment>
          )}
        </div>
      </div>
      <div className="bottom">
        <div className="info-count">
          <div className="info">you can only export 4 beats at a time</div>
          <div className="count">{selectedBeats.length}</div>
        </div>
        <button
          disabled={loading || selectedBeats.length < 1}
          className="export-button"
          onClick={() => exportSongs(selectedBeats)}
        >
          CONFIRM EXPORT
        </button>
      </div>
    </div>
  );
};

export default ExportWigdet;
