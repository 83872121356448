import React, { useEffect, useState } from 'react';
import API from '../api';
import Signup from '../components/signup';

const SignupWithReferralPage = ({ match }) => {
  const producer = match.params.producer;
  const [producerName, setProducerName] = useState('');
  const [isReferred, setIsReferred] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    API.checkIfProducerNicknameExists(producer)
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        //this is in the catch block because the call to the backend to check if a nickname exists throws an error when that nickname exists, and this is what we need to check the validity of the nickname in the url.
        setProducerName(producer);
        setIsReferred(true);
        setLoading(false);
      });
  }, [producer]);

  return (
    <React.Fragment>
      {loading ? (
        <div>loading</div>
      ) : (
        <Signup isReferred={isReferred} producerName={producerName} plan={'sound'} />
      )}
    </React.Fragment>
  );
};

export default SignupWithReferralPage;
