import React, { useState, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useOptions } from '../../utils/helpers';
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from '@stripe/react-stripe-js';
import Modal from '../Modal';
import SecretService from './SecretService';
import Tilt from 'react-parallax-tilt';
import { toast } from 'react-toastify';

// Assets
import bgVideo from '../../images/bg2.mp4';
import visaIcon from '../../images/visaIcon.svg';
import chipIcon from '../../images/chipicon.png';
import TagManager from 'react-gtm-module';
import API from '../../api';

const SignupStep1 = ({
  formData,
  setFormData,
  setPage,
  setSubmissionError,
  eventPage,
  partnerName,
  partnerData,
}) => {
  const [loading, setLoading] = useState(false);
  const [secretServiceModal, setShowSecretServiceModal] = useState(false);
  const [[manualTiltAngleX, manualTiltAngleY], setManualTiltAngle] = useState([
    0, 0,
  ]);

  const history = useHistory();
  const stripe = useStripe();
  const elements = useElements();
  const options = useOptions();
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'cardName') {
      setManualTiltAngle([35, 17]);
    }
    if (name === 'plan' && value === 'secret') {
      setFormData((prev) => ({ ...prev, [name]: value }));
    } else {
      setFormData((prev) => ({ ...prev, [name]: value }));
    }
  };

  const paymentRequest = useMemo(() => {
    if (stripe) {
      const pr = stripe.paymentRequest({
        country: 'CA',
        currency: 'cad',
        total: {
          label: 'Demo Total',
          amount: 900, // default amount, this will be changed on plan selection
        },
        requestPayerName: true,
        requestPayerEmail: true,
      });
      pr.canMakePayment().then((result) => {
        if (result) {
          setCanMakePayment(true);
        }
      });

      return pr;
    }
  }, [stripe]);

  const [canMakePayment, setCanMakePayment] = useState(false);

  useEffect(() => {
    if (paymentRequest) {
      switch (formData.plan) {
        case 'sound':
          paymentRequest.update({
            total: {
              label: 'SOUND Membership',
              amount: 900,
            },
          });
          break;
        case 'secret':
          // update for secret
          paymentRequest.update({
            total: {
              label: 'Secret Membership',
              amount: 900,
            },
          });
          break;
        case 'trial':
          // update for trial
          break;
        default:
          break;
      }
    }
  }, [formData.plan, paymentRequest]);

  const goToNextPage = async () => {
    setSubmissionError('');
    if (!formData.email) {
      setSubmissionError('Email is required');
      toast.error('Email is required', {
        position: 'top-left',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    if (!stripe || !elements || !formData.cardName) {
      setSubmissionError('All fields are required');
      toast.error('All fields are required', {
        position: 'top-left',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    setLoading(true);
    const cardElement = elements.getElement(CardNumberElement);
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });
    if (error) {
      setSubmissionError('Invalid card details, Please try again');
      toast.error('Invalid card details, Please try again', {
        position: 'top-left',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setLoading(false);
      return;
    }
    if (paymentMethod) {
      setFormData((prev) => ({
        ...prev,
        paymentMethod: paymentMethod.id,
      }));
      if (formData.plan === 'secret') {
        setLoading(false);
        setShowSecretServiceModal(true);
      } else {
        API.createTempUser({ ...formData, paymentMethod: paymentMethod.id })
          .then(() => setPage(2))
          .catch((err) => {
            toast.error('Something went wrong');
            setLoading(false);
          });
      }
      TagManager.dataLayer({
        dataLayer: {
          event: 'Payment',
          transactionID: paymentMethod.id,
        },
      });
    }
  };

  return (
    <React.Fragment>
      {secretServiceModal && (
        <Modal setIsOpen={setShowSecretServiceModal}>
          <SecretService
            setFormData={setFormData}
            formData={formData}
            setIsOpen={setShowSecretServiceModal}
            setPage={setPage}
          />
        </Modal>
      )}

      <video className="videoTag" autoPlay loop muted playsInline>
        <source src={bgVideo} type="video/mp4" />
      </video>
      <div className="signup__step1">
        <div className="signup__step1-wrapper">
          {partnerName ? (
            <h1>{partnerName.toUpperCase()} DISCOUNT</h1>
          ) : (
            <h1>PAYMENT DETAILS</h1>
          )}
          {formData.plan !== 'stream' ? (
            <select
              className="input-select"
              value={formData.plan}
              name="plan"
              id=""
              onChange={handleChange}
            >
              {partnerName ? (
                <>
                  <option value="partner">
                    CA $9/mo [first {partnerData.trialDays} days free]
                  </option>
                </>
              ) : (
                <>
                  {eventPage ? (
                    <>
                      <option value="sound">
                        $9/mo SOUND membership [$CAD]
                      </option>
                    </>
                  ) : (
                    <>
                      {' '}
                      <option value="sound">
                        $9/mo SOUND membership [$CAD]
                      </option>
                      <option value="secret">Secret Service</option>{' '}
                      <option value="trial">
                        FREE 2 weeks trial membership
                      </option>
                    </>
                  )}
                </>
              )}
            </select>
          ) : (
            <select
              className="input-select"
              value={formData.plan}
              name="plan"
              id=""
              onChange={handleChange}
            >
              <option value="stream">
                $9/mo BASIC STREAM membership [$CAD]
              </option>
            </select>
          )}

          {/*{canMakePayment && (*/}
          {/*    <PaymentRequestButtonElement*/}
          {/*        options={{ paymentRequest }}*/}
          {/*        className="my-custom-class" // you can style it or remove this*/}
          {/*    />*/}
          {/*)}*/}
          <input
            className="input-text"
            type="text"
            value={formData.email}
            onChange={handleChange}
            name="email"
            placeholder="YOUR EMAIL"
          />
          <input
            className="input-text"
            type="text"
            value={formData.cardName}
            onChange={handleChange}
            name="cardName"
            placeholder="NAME ON CARD"
          />
          <CardNumberElement
            options={options}
            className="input-text"
            onChange={(e) => setManualTiltAngle([-22, 0])}
          />
          <div className="card-expiry-cvv double-input">
            <CardExpiryElement
              options={options}
              className="input-text"
              onChange={(event) => {
                setManualTiltAngle([0, 30]);
              }}
            />
            <CardCvcElement
              options={options}
              className="input-text"
              // onChange={(event) => {
              //   handleFormComplete('cvv', event.complete);
              // }}
            />
          </div>

          <div className="signup__actions">
            <button
              onClick={() => history.goBack()}
              className="white-btn ns"
              style={{ color: 'white' }}
            >
              Back
            </button>
            <button
              disabled={loading}
              onClick={goToNextPage}
              className="white-btn"
              onMouseEnter={() => setManualTiltAngle([0, 0])}
            >
              {partnerName ? 'Purchase' : 'Continue'}
            </button>
          </div>
          <div className="signup__info">
            Your card details are encrypted and processed by Stripe. For best
            results, Please use Firefox, Chrome, Safari or Microsoft Edge with
            scripts enabled
          </div>
        </div>
        <div className="signup__step1-card-wrapper">
          <Tilt
            tiltAngleXManual={manualTiltAngleX}
            tiltAngleYManual={manualTiltAngleY}
            onLeave={() => setManualTiltAngle([0, 0])}
          >
            <div className="card-group">
              <div className="card">
                <div className="logo">
                  <img src={visaIcon} alt="Visa" />
                </div>
                <div className="chip">
                  <img src={chipIcon} alt="chip" />
                </div>
                <div className="number">1234 5678 9012 3456</div>
                <div className="name">{formData.cardName}</div>
                <div className="from">10/19</div>
                <div className="to">06/21</div>
                <div className="ring"></div>
              </div>
            </div>
          </Tilt>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SignupStep1;
