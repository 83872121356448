import { useState } from "react";
import { MultiStepForm, Step } from "react-multi-form";
import { Elements } from "@stripe/react-stripe-js";
import { stripePromise, stripeOptions } from "../../services/stripe";
import Step1 from "./Step1";
import Step2 from "./Step2";
// import Step3 from "./Step3";
import Success from "./Success";

const ProducerChallenge = () => {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    cardName: "",
    paymentMethod: "",
    firstname: "",
    lastname: "",
    password: "",
    email: "",
    phone: "",
  });

  return (
    <div className="signup producer-challenge">
      <div className="multistep">
        <MultiStepForm activeStep={step} accentColor={"black"}>
          <Step label="PAYMENT"></Step>
          <Step label="BOOK INTAKE"></Step>
          <Step label="DONE"></Step>
        </MultiStepForm>
      </div>
      {step === 1 && (
        <Elements stripe={stripePromise} options={stripeOptions}>
          <Step1
            setStep={setStep}
            formData={formData}
            setFormData={setFormData}
          />
        </Elements>
      )}
      {step === 2 && <Step2 setStep={setStep} />}
      {step === 3 && <Success />}
    </div>
  );
};

export default ProducerChallenge;
